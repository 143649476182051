<template>
  <div v-if="ba">
    <hzba-group>
      <status-progress
        :current-status="ba?.status"
        @saveHzba="syncBestandsaufnahme"
      />
    </hzba-group>
    <!-- Activate Edit mode -->
    <hzba-group
      v-if="
        !ba.isDownloaded && (ba.isLockableByGutachter() || !user.isGutachter()) && (ba?.status !== 'ABGESCHLOSSEN') && !ba.bearbeitendesGeraetId
      "
      data-cy="offline"
      hide-progress
      :title="t('hzba.uebersicht.activateEditMode')"
    >
      <template #cta>
        <AButton
          :btn-secondary="true"
          shape="round"
          :expand="isMaxSmall ? 'full' : 'block'"
          :class="isMaxSmall ? 'mx-4' : ''"
          @click="() => lockAndEditBaAlert(ba, t, true)"
        >
          {{ t("hzba.uebersicht.bearbeiten") }}
        </AButton>
      </template>
    </hzba-group>

    <!-- Remove local data and quit edit mode -->
    <hzba-group
      v-if="ba.isDownloaded"
      data-cy="offline"
      :title="t('hzba.uebersicht.dismissLocalChanges')"
      hide-progress
    >
      <template #cta>
        <AButton
          :btn-secondary="true"
          shape="round"
          :expand="isMaxSmall ? 'full' : 'block'"
          :class="isMaxSmall ? 'mx-4' : ''"
          fill="flat"
          @click="() => unlockBaAndDeleteLocalDataAlert(ba, t)"
        >
          {{ t("hzba.uebersicht.loeschen") }}
        </AButton>
      </template>
    </hzba-group>

    <hzba-group
      v-if="immobilie"
      :title="t('hzba.uebersicht.titel')"
      hide-progress
    >
      <HzbaBaseInput
        :model-value="immobilie?.externeObjektNr"
        :label="t('hzba.objektdaten.objektnummerTitel')"
        hide-unit-space
        readonly
        flat-mode
      />

      <!-- <HzbaBaseInput
        :model-value="immobilie?.strasse"
        :label="t('hzba.objektdaten.straßeTitel')"
        hide-unit-space
        readonly
        flat-mode
      /> -->

      <HzbaBaseInput
        :model-value="translatedHzbaStatus(ba?.status, t)"
        label="Status"
        hide-unit-space
        readonly
      />
      <HzbaBaseInput
        :label="t('hzba.uebersicht.durchgefuehrtVon')"
        hide-unit-space
        readonly
        flat-mode
        :model-value="ba?.bearbeitenderNutzer?.username"
      />
      <HzbaBaseInput
        :label="t('hzba.uebersicht.zugeordneterGutachter')"
        hide-unit-space
        readonly
        flat-mode
        :model-value="ba?.gutachter?.username"
      />
    </hzba-group>

    <hzba-group
      :title="t('hzba.vorschau.fortschrittTitel')"
      hide-progress
    >
      <HzbaBaseInput
        :label="t('hzba.vorschau.felderAusgefuellt')"
        :model-value="`${totalProgress}%`"
        hide-unit-space
        readonly
        flat-mode
      />

      <!--    <HzbaBaseInput-->
      <!--      label="Mängel erkannt"-->
      <!--      :model-value="15"-->
      <!--      readonly-->
      <!--    />-->

      <!--    <HzbaBaseInput-->
      <!--      label="Berechneter Maluswert"-->
      <!--      :model-value="15"-->
      <!--      readonly-->
      <!--    />-->
    </hzba-group>


    <hzba-group
      v-if="upgradeAvailable"
      :title="'Migiriere Bestandsaufnahme'"
      class="relative"
      hide-progress
    >
      <div
        v-if="migrationFromTo && Object.keys(migrationFromTo).length > 0"
        class="flex gap-2 mx-6 p-2 mb-4 bg-gray-200 rounded-md"
      >
        <div class="flex flex-col gap-2">
          <p class="m-0 font-semibold">
            Von Vorlage
          </p>
          <p class="m-0 text-sm">
            {{ migrationFromTo?.fromVorlage }}
          </p>
        </div>
        <div class="flex flex-col gap-2 border-b">
          <p class="m-0 font-semibold">
            Zur Vorlage
          </p>
          <p class="m-0 text-sm">
            {{ migrationFromTo?.toVorlage }}
          </p>
        </div>        
      </div>
      <div class=" px-8">
        <info-banner
          v-if="!migrationSuccessfull"
          class="mb-4"
          no-extra-margin
        >
          <p>
            Migration nicht erfolgreich
          </p>
          <p v-if="migrationStatusText != ''">
            {{ migrationStatusText }}
          </p>
          <div>
            <div
              v-for="(unsolvedMigration, key) in baUnsolvedMigrations"
              :key="key"
            >
              <pre>{{ unsolvedMigration.migrationDescription }}</pre>
              <div> {{ unsolvedMigration.onFailDescription }}</div>
            </div>
          </div>
        </info-banner>
        <div class="flex justify-between">
          <AButton
            :btn-secondary="true"
            @click="() => getMigrateBa(ba, false)"
          >
            Änderungen durchführen
          </AButton>
          <AButton
            :btn-secondary="true"
            @click="() => getMigrateBa(ba, true)"
          >
            Änderungen anschauen
          </AButton>
        </div>
      </div>
      <ba-all-differences
        v-if="baDifferences && Object.keys(baDifferences).length > 0"
        :ba-differences="baDifferences"
      />
      <div
        v-if="baDifferences && Object.keys(baDifferences).length > 0"
        class="text-sm mx-4 mt-2 p-2 bg-gray-200 cursor-pointer rounded-md"
        @click="() => showDifferencesJson = !showDifferencesJson"
      >
        <span v-if="!showDifferencesJson">
          show
        </span>
        <span v-else>
          hide
        </span>
        all Differences JSON
      </div>
      <div v-if="showDifferencesJson">
        <pre
          v-if="baDifferences && Object.keys(baDifferences).length > 0"
          class="mb-0 mx-4 bg-gray-200 rounded-md p-2"
        >
                    {{ baDifferences }}
                  </pre>
        <p v-else>
          No differences
        </p>
      </div>
      <div v-if="baUnsolvedMigrations && baUnsolvedMigrations.length > 0">
        <pre>{{ baUnsolvedMigrations }}</pre>
      </div>
    </hzba-group>

    <hzba-group :title="'Backups verfügbar'">
      <div
        v-for="backup in backups"
        :key="backup"
        class="px-4 py-2 flex items-center"
      >
        <div class="flex-1">
          <div>ID {{ backup.id }} | {{ moment(backup.attributes.createdAt).format('dddd, DD.MM.yyyy hh:mm') }}</div>
          <div>{{ backup.description }}</div>
        </div>
        <button
          class="ghost-button"
          @click="runBackup(backup.id)"
        >
          Backup durchführen
        </button>
      </div>
    </hzba-group>



    <hzba-group
      :title="t('hzba.vorschau.maengelTitel')"
      hide-progress
    >
      <template #cta>
        <AButton
          v-if="!isMaxSmall && removedMangelsFromMigrations && removedMangelsFromMigrations.length > 0"
          :btn-secondary="true"
          @click="openBaseModal('maengel.uebersicht', { maengel: removedMangelsFromMigrations, fileName })"
        >
          {{
            t("migration.showEntfernteMaengelsVonMigration") +
              ` (${removedMangelsFromMigrations.length})`
          }}
        </AButton>
        <AButton
          v-if="!isMaxSmall"
          :btn-secondary="true"
          :disabled="maengelReport?.countedMaengel() === 0"
          @click="openBaseModal('maengel.uebersicht', { maengel, fileName })"
        >
          {{
            maengelReport?.countedMaengel() === 0
              ? t("hzba.buttons.keineMaengel")
              : t("hzba.buttons.alleAnzeigen") +
                ` (${maengelReport?.countedMaengel()})`
          }}
        </AButton>
      </template>

      <HzbaBaseInput
        :label="t('hzba.vorschau.maengelErkannt')"
        :model-value="maengelReport?.countedMaengel()"
        hide-unit-space
        readonly
        flat-mode
      />

      <HzbaBaseInput
        :label="t('hzba.vorschau.relevanterMalusWert')"
        :model-value="maengelReport?.getCalculatedRelevantMalus()"
        hide-unit-space
        readonly
        flat-mode
      />
      <HzbaBaseInput
        :label="t('hzba.vorschau.nichtRelevanterMalusWert')"
        :model-value="maengelReport?.getCalculatedNotRelevantMalus()"
        hide-unit-space
        readonly
        flat-mode
      />
      <HzbaBaseInput
        :label="t('hzba.vorschau.summeMalusWert')"
        :model-value="maengelReport?.getCalculatedMalus()"
        hide-unit-space
        readonly
        flat-mode
      />


      <AButton
        v-if="isMaxSmall"
        :btn-secondary="true"
        class="mt-4 mx-4"
        data-cy="button-alleMaengelAnzeigen"
        expand="full"
        fill="flat"
        :disabled="maengelReport?.countedMaengel() === 0"
        @click="openBaseModal('maengel.uebersicht', { ba })"
      >
        {{
          maengelReport?.countedMaengel() === 0
            ? t("hzba.buttons.keineMaengel")
            : t("hzba.buttons.alleAnzeigen") +
              ` (${maengelReport?.countedMaengel()})`
        }}
      </AButton>
    </hzba-group>

    <hzba-group
      v-if="ba?.isInProgress()"
      :title="t('hzba.vorschau.pdfVorschauTitel')"
      hide-progress
    >
      <template #cta>
        <AButton
          :btn-secondary="true"
          :disabled="!ba?.hasPdfSupport()"
          shape="round"
          fill="flat"
          :expand="isMaxSmall ? 'full' : 'block'"
          :class="isMaxSmall ? 'mx-4' : ''"
          @click="$router.push(`/ba/${ba.id}/pdf-preview`)"
        >
          {{ t("hzba.buttons.anzeigen") }}
        </AButton>
      </template>
      <div
        v-if="!ba?.hasPdfSupport()"
        class="mx-4"
      >
        {{ t("hzba.pdf.pdfErstellungNichtSupported") }}
      </div>
    </hzba-group>

    <hzba-group
      v-if="isReadonly"
      :title="t('hzba.vorschau.pdfAnzeigenTitel')"
      data-cy="group-pdf-report"
      hide-progress
    >
      <ion-list class="bg-white boxed-container m-4 p-0">
        <ion-item
          v-for="(doc, key) in ba?.berichts"
          :key="key"
          class="cursor-pointer"
          data-cy="button-previewPdf"
          @click="openBaseModal('pdf.view', { pdf: doc.data })"
        >
          <ion-label
            v-if="doc.kennzeichen && doc.kennzeichen.endsWith('-de')"
            data-cy="pdf-report-item"
          >
            {{ doc.titel && doc.titel.de }}
          </ion-label>

          <ion-label
            v-if="doc.kennzeichen && doc.kennzeichen.endsWith('-en')"
            data-cy="pdf-report-item"
          >
            {{ doc.titel && doc.titel.en }}
          </ion-label>

          <ion-icon
            :icon="chevronForward"
            size="large"
          />
        </ion-item>
      </ion-list>
    </hzba-group>

    <hzba-group
      v-if="ba?.archive"
      data-cy="group-zip-report"
      title="ZIP Heizungsbestandsaufnahme"
      hide-progress
    >
      <template #cta>
        <AButton
          btn-primary="true"
          shape="round"
          :href="getPhotoUrl(ba?.archive)"
          data-cy="btn-download-zip-report"
        >
          {{ t("hzba.buttons.downloaden") }}
        </AButton>
      </template>
    </hzba-group>

    <hzba-group
      :title="t('hzba.vorschau.hzbaAbschliessen')"
      hide-progress
    >
      <div class="m-4">
        <ion-grid class="p-0">
          <ion-row class="flex-nowrap d-flex items-center">
            <ion-col
              class="flex-grow-0 mr-2"
              data-cy="fortschritt"
            >
              <progress-indicator :progress="totalProgress === 100 ? 100 : 0" />
            </ion-col>
            <div class="flex-1">
              {{ t("hzba.vorschau.felderAusgefuellt") }}
            </div>
            <div>{{ totalProgress }}%</div>
          </ion-row>

          <ion-row class="flex-nowrap d-flex items-center mt-4">
            <ion-col class="flex-grow-0 mr-2">
              <progress-indicator
                :progress="((ba && ba.fazit?.length) ?? 0) > 0 ? 100 : 0"
              />
            </ion-col>
            <div class="flex-1">
              {{ t("hzba.vorschau.fazit") }}
            </div>
            <div>
              <AButton
                :btn-primary="true"
                data-cy="fazit-hinzufuegen"
                shape="round"
                @click="
                  openBaseModal('conclusion', {
                    parent: () => ba,
                    maxFazitLength: 500,
                  })
                "
              >
                {{
                  !isReadonly
                    ? ba && ba.fazit && ba.fazit.length > 0
                      ? t("hzba.buttons.aendern")
                      : t("hzba.buttons.hinzufuegen")
                    : t("hzba.buttons.anzeigen")
                }}
              </AButton>
            </div>
          </ion-row>

          <ion-row
            :class="!isMaxSmall ? 'flex-nowrap d-flex items-center mt-4' : 'flex items-center mt-4 justify-between'"
          >
            <ion-col class="flex-grow-0 mr-2">
              <progress-indicator
                :progress="(ba.unterschrifts?.length ?? 0) > 0 ? 100 : 0"
              />
            </ion-col>
            <div
              class="flex-1"
            >
              {{ t("hzba.vorschau.unterschrift") }}
            </div>
            <div>
              <AButton
                :btn-primary="true"
                :class="isMaxSmall ? 'flex order-last' : ''"
                data-cy="unterschreiben"
                shape="round"
                @click="openBaseModal('signandsubmit', { ba })"
              >
                {{
                  !isReadonly
                    ? t("hzba.buttons.hinzufuegen")
                    : t("hzba.buttons.anzeigen")
                }}
              </AButton>
            </div>
          </ion-row>
        </ion-grid>
      </div>
      <div class="m-4 block md:flex">
        <AButton
          shape="round"
          :btn-primary="true"
          class="text-lg py-5 px-8"
          :disabled="!isSubmittable"
          :expand="isMaxSmall ? 'full' : 'block'"
          @click="finishHzba()"
        >
          {{ t("hzba.buttons.abschliessen") }}
        </AButton>

        <div v-if="isSubmittable || isUploading">
          <PdfPreviewerAndCreator
            v-for="lang in uploadLanguages"
            :key="lang"
            :ref="(el) => setupPdfViewerRef(el, lang)"
            :lang="lang"
            :download-name="downloadName"
            :go-back-url="ba && `/ba/${ba.id}`"
            :show="false"
            :is-upload-mode="true"
            @pdfAsBlob="(blob) => setGeneratedPdfFile(blob, lang)"
          >
            <PdfTemplates
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
            />
          </PdfPreviewerAndCreator>
        </div>
      </div>
    </hzba-group>
  </div>
</template>


<script lang="ts">
import { strapiUrl } from "@/api/api";
import AButton from "@/components/Base/AButton";
import InfoBanner from "@/components/Base/InfoBanner.vue";
import Toolbar from "@/components/Navigation/Toolbar";
import OfflineHint from "@/components/Other/OfflineHint.vue";
import PdfHzbaTemplate from "@/components/Pdf/Bericht_Heizung/PdfHzbaTemplate.vue";
import PdfPreviewerAndCreator from "@/components/Pdf/PdfPreviewerAndCreator.vue";
import PdfTemplates from "@/components/Pdf/PdfTemplates.vue";
import ProgressIndicator from "@/components/ProgressIndicator";
import SignatureField from "@/components/Signature/SignatureField.vue";
import Tabs from "@/components/hzba/BaTabs.vue";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput";
import StatusProgress from "@/components/hzba/StatusProgress.vue";
import useBestandsaufnahmeUpload from "@/composables/Bestandsaufnahme/useBestandsaufnahmeUpload";
import { useBestandsaufnahmens } from "@/composables/Bestandsaufnahme/useBestandsaufnahmens";
import { PdfBerichtManager } from "@/composables/pdfBerichtManager";
import useCurrentBaState from "@/composables/useCurrentBaState";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import { translatedHzbaStatus } from "@/models/ba/interfaces/IBestandsaufnahme";
import getLocalization from "@/utilities/get-localization";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { openBaseModal } from "@/utilities/modal-helper";
import {
IonBackButton,
IonButton,
IonButtons,
IonChip,
IonCol,
IonContent,
IonGrid,
IonIcon,
IonItem,
IonLabel,
IonList,
IonPage,
IonRow,
IonSegment,
IonSegmentButton,
toastController,
} from "@ionic/vue";
import { chevronForward, warningOutline } from "ionicons/icons";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import BaAllDifferences from "../BaAllDifferences.vue";
import AdditionalMaengel from "../Base/AdditionalMaengel.vue";

import moment from "moment";

export default defineComponent({
  name: "BaSummary",
  components: {
    PdfTemplates,
    PdfHzbaTemplate,
    PdfPreviewerAndCreator,
    OfflineHint,
    // SignatureWrapper,
    SignatureField,
    StatusProgress,
    HzbaBaseInput,
    Tabs,
    IonList,
    AButton,
    HzbaGroup,
    ProgressIndicator,
    Toolbar,
    IonBackButton,
    IonButtons,
    IonButton,
    IonPage,
    IonLabel,
    IonRow,
    IonIcon,
    IonCol,
    IonContent,
    IonSegment,
    IonItem,
    IonChip,
    IonSegmentButton,
    IonGrid,
    BaAllDifferences,
    AdditionalMaengel,
    InfoBanner
},
  setup(_) {
    const { t } = useI18n({ useScope: "global" });
    const store = useStore();
    const selectedView = ref("uebersicht");
    const { isMaxSmall } = useScreenSize();
    const uploadLanguages = ["en", "de"];
    const isUploading = ref(false);
    const isOffline = computed(() => {
      return store.getters["app/isOffline"];
    });
    const upgradeAvailable = ref();
    const baDifferences = ref({});
    const baUnsolvedMigrations = ref();
    const backups = ref();
    const showDifferencesJson = ref(false)
    const migrationSuccessfull = ref(true);
    const migrationStatusText  = ref("")
    const migrationFromTo = ref({})

    const {
      immobilie,
      isReadonly,
      isSubmittable,
      totalProgress,
      isNotSubmitted,
      ba,
    } = useCurrentBaState();

    const user = useUser();

    const { syncBestandsaufnahme, submitBestandsaufnahme, saveBaLocally } =
      useBestandsaufnahmeUpload();

    const {
      downloadBa,
      removeDownloadedBa,
      lockAndEditBaAlert,
      unlockBaAndDeleteLocalDataAlert,
      migrateBa,
      isUpgradeAvailable,
        getBackupsForBa,
        runBackup,
    } = useBestandsaufnahmens();
    const pdfManager = new PdfBerichtManager(uploadLanguages);

    const finishHzba = () => {
      isUploading.value = true;
      submitBestandsaufnahme(pdfManager);
    };

    const downloadName = computed(() => {
      return (immobilie.value?.stadt + "_" + immobilie.value?.name + "_" + ba.value?.name?.de).replace(' ', '_');
    });

    const maengel = computed(() => {
      return ba.value && ba.value.getFiredMaengel();
    })

    const removedMangelsFromMigrations = computed(() => {
      return ba.value && ba.value.removedMangelsFromMigrations.map((el: any) => new Mangelzuordnung(el, '', undefined));
    })

    const fileName = `${immobilie.value?.strasse}, ${immobilie.value?.plz} ${immobilie.value?.stadt} - ${t('hzba.maengelliste.maengelliste')} ${moment(ba.value?.begehungsdatum).format('DD.MM.YYYY')}.csv`


    onMounted(async() => {
      const { success, fromVorlage, toVorlage} = await isUpgradeAvailable(ba.value!)
      upgradeAvailable.value = success
      if (success) { migrationFromTo.value = {fromVorlage:fromVorlage, toVorlage:toVorlage} }

      backups.value = await getBackupsForBa(ba.value!.id);
    })

    const maengelReport = computed(() => {
      return ba.value?.maengelReport();
    });

    const getMigrateBa = async (bestandsaufnahme: any, preview: any) => {
      if (ba.value?.isLocal) {
        const toast = await toastController.create({
            message: t('toasts.migrationLokaleÄnderungLöschen'),
            duration: 4000
        })
        await toast.present();
      } else {
        const { success, differences, performedChanges, unsolvedMigrations, message } = await migrateBa(bestandsaufnahme, preview)
        if (performedChanges) {
          const { success } =  await isUpgradeAvailable(ba.value!);
          upgradeAvailable.value = success
          const toast = await toastController.create({
            message: t('toasts.migrationErfolgreich'),
            duration: 4000
          })
          await toast.present();
          await store.dispatch('currentHzba/refetchCurrentBa', true);
        } else if (!success) {
          migrationSuccessfull.value = false;
          migrationStatusText.value = message;
          baUnsolvedMigrations.value = unsolvedMigrations;
        } else {
          baDifferences.value = differences;
        }
      }
    }

    return {
      selectedView,
      immobilie,
      objectCountHeight: 62,
      syncBestandsaufnahme,
      uploadLanguages,
      openBaseModal,
      getMigrateBa,
      baDifferences,
      isMaxSmall,
      user,
      upgradeAvailable,
      getLocalization,
      lockAndEditBaAlert,
      unlockBaAndDeleteLocalDataAlert,
      isReadonly,
      getPhotoUrl,
      isOffline,
      isSubmittable,
      maengelReport,
      finishHzba,
      setGeneratedPdfFile: pdfManager.setGeneratedPdfFile,
      setupPdfViewerRef: pdfManager.setupPdfViewerRef,
      totalProgress,
      warningOutline,
      isUploading,
      chevronForward,
      ba,
      baseUrl: strapiUrl,
      baUnsolvedMigrations,
      t,
      downloadBa,
      removeDownloadedBa,
      saveBaLocally,
      runBackup,
      moment,
      backups,
      translatedHzbaStatus,
      showDifferencesJson,
      migrationSuccessfull,
      migrationStatusText,
      migrationFromTo,
      removedMangelsFromMigrations,
      maengel,
      fileName,
      downloadName
    };
  },
});
</script>

<style scoped>
.green {
  color: "green";
  background-color: "red";
}
</style>