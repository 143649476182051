import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ecd65194"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container mt-10" }
const _hoisted_2 = {
  class: "m-4 text-3xl",
  "data-cy": "header-faq"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserDialogButton = _resolveComponent("UserDialogButton")!
  const _component_toolbar = _resolveComponent("toolbar")!
  const _component_faq_item = _resolveComponent("faq-item")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_toolbar, {
        title: _ctx.t('toolbar.hilfe'),
        "show-back-button": "",
        onOnBackButtonClicked: () => {}
      }, {
        trailing: _withCtx(() => [
          _createVNode(_component_UserDialogButton)
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.t('toolbar.hilfe')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqs, (faq) => {
              return (_openBlock(), _createBlock(_component_faq_item, {
                key: faq.frage,
                faq: faq
              }, null, 8, ["faq"]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}