<template>
  <table class="text-sm w-full">
    <tr>
      <th class="text-left">
        {{ t("hzba.pdf.datum", 1, { locale: lang}) }}
      </th>
      <th class="text-left">
        {{ t("hzba.pdf.artdermassnahme", 1, { locale: lang}) }}
      </th>
      <th class="text-left">
        {{ t("hzba.pdf.massnahme", 1, { locale: lang}) }}
      </th>
    </tr>
    <tr
      v-for="appointment in appointments"
      :key="appointment"
      class="h-12"
    >
      <td class="pr-4">
        {{ moment(appointment.date).format('YYYY-MM-DD') }}
      </td>
      <td>{{ appointment.topic }}</td>
      <td>{{ appointment.description }}</td>
    </tr>
  </table>
</template>
<script lang="ts">

import { useI18n } from 'vue-i18n';
import moment from "moment";
import {defineComponent} from "vue";

export default defineComponent({
  name: 'PdfAppointmentTable',
  props: {
    appointments: {
      type: Object,
      required: true
    },
    lang: {
      type: String,
      default: 'de'
    }
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    return { moment, t }
  }
})
</script>

<style lang="scss" scoped>
tr {
  border-bottom: 1px solid $grey-800;
}
</style>
