<template>
  <pdf-row-base>
    <td style="width: 15%;">
      <!-- <PdfImageCropped
          v-if="mangel.eingabeMedien && mangel.eingabeMedien.length > 0 && getPhotoUrl(mangel.eingabeMedien[0])"
        :src="mangel.eingabeMedien && mangel.eingabeMedien.length > 0 && getPhotoUrl(mangel.eingabeMedien[0])"
        :height="100"
        :width="90"
      /> -->
      <img
        v-if="
          mangel.eingabeMedien &&
            mangel.eingabeMedien.length > 0 &&
            getPhotoUrl(mangel.eingabeMedien[0])
        "
        :src="
          mangel.eingabeMedien &&
            mangel.eingabeMedien.length > 0 &&
            getPhotoUrl(mangel.eingabeMedien[0])
        "
        class="mt-0"
        style="width: 120px; height: 120px"
      >
    </td>
    <td
      class="text-left font-semibold"
      style="width: 65%; word-break: break-word"
    >
      {{ mangel.titel() }}
      <br>
      <p class="font-normal whitespace-normal">
        {{ mangel.eingabeFreitext }}
      </p>
    </td>
    <td class="text-left font-semibold">
      {{ mangel.gewerkLabel() }}
    </td>
    <td class="flex justify-end">
      <PdfChip
        :value="mangel.maluspunkte()"
        :color="mangel.getColor()"
        pdf-mode
      />
    </td>
  </pdf-row-base>
  <!-- <tr
      v-for="(entry, key) in data"
      :key="key"
      class="data-tr numberCol"
    >
      <td>
        {{ entry }}
      </td>
      <td>a</td>
      <td>b</td>
      <td>c</td>
    </tr> -->
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import { getPhotoUrl } from "@/utilities/get-media-url";
import AImage from "@/components/Base/AImage.vue";
import PdfRowBase from "@/components/Pdf/Components/PdfRowBase.vue";
// import PdfImageCropped from "@/components/Pdf/Components/PdfImageCropped.vue";

export default defineComponent({
  name: "PdfRowMangel",
  components: {
    PdfRowBase,
    // PdfImageCropped,
    AImage,
    PdfChip,
  },
  props: {
    mangel: {
      type: Object as PropType<Mangelzuordnung>,
      required: true,
    },
  },
  setup() {
    return {
      getPhotoUrl,
    };
  },
});
</script>
