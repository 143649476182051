<template>
  <ion-page>
    <toolbar
      title="Einstellungen"
      show-back-button
      @onBackButtonClicked="() => {}"
    >
      <template #trailing>
        <UserDialogButton />
      </template>
    </toolbar>

    <ion-content>
      <div class="account-container">
        <div
          class="flex account-box-container"           
          data-cy="account-container"
        >
          <div class="m-4 flex-auto">
            <div :class="rowClass">
              <h1 class="no-margin inline flex-1">
                {{ $t("einstellungen.account") }}
              </h1>
              <div
                :class="rowClass" 
                class="inline float-right"
              >
                <AButton
                  :type="'submit'"
                  data-cy="button-logout"
                  :btn-secondary="true"
                  class="mt-4"
                  @click="logout"
                >
                  {{ $t('buttons.logout') }}
                </AButton>
              </div>
            </div>

            <div :class="rowClass">
              <div
                class="label flex-1 grow inline-block"
                data-cy="label-name"
              >
                {{ $t("einstellungen.name") }}
              </div>
              <div
                class="flex-1 grow inline-block text-right input"
                data-cy="userName"
              >
                {{ user.username }}
              </div>
            </div>
            <div :class="rowClass">
              <div
                class="label flex-1 grow inline-block"
                data-cy="label-email"
              >
                {{ $t("einstellungen.email") }}
              </div>
              <div
                class="flex-1 grow inline-block text-right input"
                data-cy="userEmail"
              >
                {{ user.email }}
              </div>
            </div>
            <div
              v-if="isDev"
              :class="rowClass"
            >
              <div
                class="label flex-1 grow inline-block"
                data-cy="label-selectLanguage"
              >
                {{ $t("einstellungen.sprache") }}
              </div>
              <SelectLanguage />
            </div> 
          </div>
          <div class="relative h-48 md:h-72">
            <IonImg
              data-cy="profileImage"
              :src="
                user.profilbild
                  ? getPhotoUrl(user.profilbild)
                  : './assets/img/profiles/placeholder_profile_image.png'
              "
              class="rounded object-cover h-full"
              style="background: white"
            />
            <AButton
              data-cy="button-uploadProfileImage"
              :btn-tertiary="true"
              class="
                px-12
                py-4
                absolute    
                bottom-4
                right-4
                cursor-pointer  
              "
              @click="uploadProfileImage"
            >
              {{ $t("buttons.aendern") }}
            </AButton>
          </div>
        </div>
      </div>

      <div
        class="container"
        data-cy="password-container"
      >
        <div class="boxed-container">
          <div class="m-4">
            <div :class="rowClass">
              <h1 class="app-info">
                {{ $t("einstellungen.passwort") }}
              </h1>
            </div>

            <form
              novalidate
              @submit.prevent="onChangePassword"
            >
              <div>
                <AInputMLStyle
                  v-model="changePasswordForm.currentPassword.$model"
                  data-cy="input-currentPassword"
                  class="pl-4"
                  input-type="password"
                  :label="t('einstellungen.aktuellesPasswort')"
                  :errors="changePasswordForm.currentPassword.$errors"
                />
                <AInputMLStyle
                  v-model="changePasswordForm.newPassword.$model"
                  data-cy="input-newPassword"
                  class="pl-4"
                  input-type="password"
                  :label="t('einstellungen.neuesPasswort')"
                  :errors="changePasswordForm.newPassword.$errors"
                />
                <AInputMLStyle
                  v-model="changePasswordForm.confirmNewPassword.$model"
                  data-cy="input-confirmNewPassword"
                  class="pl-4"
                  input-type="password"
                  :label="t('einstellungen.neuesPasswortBestätigen')"
                  :errors="changePasswordForm.confirmNewPassword.$errors"
                />
              </div>
              <div :class="rowClass">
                <AButton
                  :type="'submit'"
                  :btn-primary="true"
                  data-cy="button-updatePassword"
                  class="mt-4"
                  style="margin-left: auto; margin-right:0px"
                >
                  {{ $t("einstellungen.passwortAendern") }}
                </AButton>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="boxed-container">
          <div class="m-4">
            <div :class="rowClass">
              <h1 class="app-info">
                App Info
              </h1>
            </div>
            <div>
              <div :class="rowClass">
                <div class="label flex-1 grow inline-block">
                  App Version
                </div>
                <div class="flex-1 grow inline-block text-right input">
                  {{ env.BUILD_VERSION }}
                </div>
              </div>
              <div :class="rowClass">
                <div class="label flex-1 grow inline-block">
                  Backend Version
                </div>
                <div class="flex-1 grow inline-block text-right input">
                  {{ backendBuildVersion }}
                </div>
              </div>
              <div :class="rowClass">
                <div class="label flex-1 grow inline-block">
                  Frontend Url
                </div>
                <div class="flex-1 grow inline-block text-right input">
                  {{ env.SERVER_URL }}:{{ env.SERVER_PORT }}
                </div>
              </div>
              <div :class="rowClass">
                <div class="label flex-1 grow inline-block">
                  Backend Url
                </div>
                <div class="flex-1 grow inline-block text-right input">
                  {{ env.BACKEND_URL }}:{{ env.BACKEND_PORT }}
                </div>
              </div>
              <div :class="rowClass">
                <div class="label flex-1 grow inline-block">
                  Production Mode
                </div>
                <div class="flex-1 grow inline-block text-right input">
                  {{ env.production }}
                </div>
              </div>

              <div :class="rowClass">
                <div class="label flex-1 grow inline-block">
                  Logs
                </div>
              </div>
              <div :class="rowClass">
                <AButton
                  :type="'button'"
                  :btn-primary="true"
                  data-cy="button-showDebugLogs"
                  style="margin-left: auto; margin-right:0px"
                  class="mt-4"
                  @click="() => goToLogs()"
                >
                  Show Debugging Logs
                </AButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import AButton from "@/components/Base/AButton";
import AFlatButton from "@/components/Base/AFlatButton.vue";
import AInputMLStyle from "@/components/Base/AInputMLStyle";
import Toolbar from "@/components/Navigation/Toolbar";
import UserDialogButton from "@/components/User/UserDialogButton.vue";
import SelectLanguage from "@/components/selectLanguage";
import useLogoutAlert from "@/composables/User/useLogoutAlert";
import useAlert from "@/composables/useAlert";
import useAuth from "@/composables/useAuth";
import { isDevEnvironment } from "@/composables/useEnvDetector";
import { usePhotoRecording } from "@/composables/usePhotoRecording";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import { getLocalMetaData } from "@/models/local-meta-data";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { logger } from "@/utilities/logging";
import { popoverHelper } from "@/utilities/popover-helper";
import {
IonButton,
IonButtons,
IonContent,
IonImg,
IonPage,
onIonViewWillEnter,
} from "@ionic/vue";
import useVuelidate from "@vuelidate/core";
import {
helpers,
minLength,
required
} from "@vuelidate/validators";
import { computed, defineComponent, reactive, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { environment } from "../../environments/environment";

export default defineComponent({
  name: "Settings",
  components: {
    AFlatButton,
    AButton,
    Toolbar,
    IonPage,
    IonButton,
    IonButtons,
    IonContent,
    IonImg,
    AInputMLStyle,
    UserDialogButton,
    SelectLanguage,
  },
  setup() {
    const store = useStore();
    const { user, updateProfileImage } = useUser();
    const { t } = useI18n({ useScope: "global" });
    const router = useRouter();
    const { isMaxSmall } = useScreenSize();

    onIonViewWillEnter(() => {
      store.commit("app/showBottomNavigation");
    });

    const isDev = isDevEnvironment();

    const auth = useAuth();
    const alert = useAlert();

    const env = environment;
    const processEnv = process.env;

    const { openPhotoDialog } = usePhotoRecording();

    const backendBuildVersion = computed(() => {
      return getLocalMetaData("backendBuildVersion");
    });

    const uploadProfileImage = async () => {
      const photoRes = await openPhotoDialog(t, 1, {hideFile: true});
      photoRes.success && photoRes.data && photoRes.data.length > 0 && await updateProfileImage(photoRes.data[0]);
    };

    const changePasswordData = reactive({
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      userId: user.value?.id
    });

    const rules = {
      currentPassword: {
        required: helpers.withMessage(
          t("einstellungen.eingabeErforderlich"),
          required
        ),
      },
      newPassword: {
        required: helpers.withMessage(
          t("einstellungen.eingabeErforderlich"),
          required
        ),
        minLength: helpers.withMessage(
          t("helper.passwortLaenge"),
          minLength(8)
        ),
        goodPassword: helpers.withMessage(
          t("helper.passwortSicherheit"),
          (password: any) => {
            return (
              password.length >= 6 &&
              (/[a-z]/.test(password) || /[A-Z]/.test(password)) &&
              /[0-9]/.test(password)
            );
          }
        ),
      },
      confirmNewPassword: {
        required: helpers.withMessage(
          t("einstellungen.eingabeErforderlich"),
          required
        ),
        sameAsPassword: helpers.withMessage(
          t("einstellungen.passwortNichtIdent"),
          (confPassword: string) => {
            return (
              confPassword === changePasswordData.newPassword
            );
          }
        ),
      },
    };

    const changePasswordForm = useVuelidate(rules, {
      currentPassword: toRef(changePasswordData, "currentPassword"),
      newPassword: toRef(changePasswordData, "newPassword"),
      confirmNewPassword: toRef(changePasswordData, "confirmNewPassword"),
    });

    const onChangePassword = async () => {
      changePasswordForm.value.$touch();

      try {
        await auth.changePassword(changePasswordData);
        await alert.show(t("einstellungen.passwortGeändert"), "");
      } catch (error: any) {
        if (error.response?.data.message === "wrong-current-password") {
          await alert.show(t("einstellungen.falschesPasswort"), "");
        } else {
          await alert.show(t("alerts.unerwarteterFehlerHead"), t("alerts.unerwarteterFehlerText"));

          logger.error(`Resetting password failed: ${error}`);
        }
      }

      changePasswordData.currentPassword = "";
      changePasswordData.newPassword = "";
      changePasswordData.confirmNewPassword = "";
      changePasswordForm.value.$reset();
    };


    function goToLogs() {
      router.push('/logs')
    }

    return {
      logout: () => useLogoutAlert(router, t),
      getPhotoUrl,
      user,
      openPopover: popoverHelper,
      env,
      processEnv,
      uploadProfileImage,
      backendBuildVersion,
      t,
      changePasswordForm,
      onChangePassword,
      goToLogs,
      rowClass: 'flex mb-4 text-sm',
      isDev,
      isMaxSmall
    };
  },
});
</script>

<style scoped lang="scss">
.label {
  width: 15%;
  min-width: 150px;
  margin-right: 16px;
  letter-spacing: normal;
}
.input {
 color: $mlGrey;
}
.no-margin {
  margin: 0;
}

.app-info {
  margin-top: 0;
}

ion-img::part(image) {
  border-radius: 6px;
}
</style>