<template>
  <ion-page>
    <ion-header>
      <toolbar title="Image Preview">
        <template #leading>
          <ion-buttons slot="start">
            <ion-button @click="onClose()">
              <ion-icon
                slot="icon-only"
                :icon="arrowBack"
              />
            </ion-button>
          </ion-buttons>
        </template>
        <template #trailing>
          <ion-buttons slot="end">
            <ion-button @click="onDelete()">
              <ion-icon
                slot="icon-only"
                :icon="trash"
              />
            </ion-button>
          </ion-buttons>
        </template>
      </toolbar>
    </ion-header>
    <ion-content>
      <ion-img
        class="object-contain h-full"
        data-cy="photo-preview"
        :src="getPhotoUrl(photo)"
      />
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  IonContent,
  modalController,
  IonImg,
  IonButton,
  IonFooter,
  IonButtons,
  IonIcon,
  IonPage,
} from "@ionic/vue";
import HzbaBaseModal from "@/components/Base/BaseModal.vue";
import StrapiMedia, {
  instanceOfStrapiImage,
} from "@/models/photo/strapi-media.model";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { trash, arrowBack } from "ionicons/icons";
import Toolbar from "@/components/Navigation/Toolbar.vue";

/**
 * @deprecated If we don't find a new use case for this modal we could delete it
 * **/
export default defineComponent({
  name: "PhotoViewModal",
  components: {
    IonContent,
    IonImg,
    IonButton,
    IonFooter,
    IonButtons,
    IonIcon,
    HzbaBaseModal,
    IonPage,
    Toolbar,
  },
  props: {
    photo: {
      type: Object as PropType<StrapiMedia | Blob>,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
  },
  emit: ["delete"],
  setup(props) {
    const onClose = async () => {
      await modalController.dismiss();
    };

    return {
      trash,
      arrowBack,
      getPhotoUrl,
      onClose,
    };
  },
});
</script>