<template>
  <div
    v-if="difference.absolutePath.includes(activeTab)"
    class="mt-2 relative p-3 bg-gray-100 rounded-md"
  >
    <p :class="`absolute w-1/3 -top-1 right-3 text-xs text-right overflow-hidden ${seeEverything ? 'h-full' : 'h-8'}`">
      {{ getNestedBreadcrumbTitles(ba, difference.absolutePath, false) }}
    </p>
    <div>
      <div
        class="cursor-pointer w-full"
        @click="() => seeEverything = !seeEverything"
      >
        <p class="m-0 w-2/3 font-bold">
          {{ frage?.titel.en || 'Mangellogik' }} {{ difference.operator }}
        </p>
      </div>
      <div v-if="seeEverything">
        <div v-if="difference.description">
          <p class="mb-0 font-bold">
            {{ t("migration.beschreibung") }}
          </p>
          <p class="m-0">
            {{ difference.description }}
          </p>
        </div>
        <div v-if="difference.absolutePath">
          <p class="mb-0 font-semibold">
            {{ t("migration.absoluterPfad") }}
          </p>
          <p class="m-0">
            {{ difference.absolutePath }}
          </p>
        </div>
        <div v-if="difference.identifier">
          <p class="mb-0 font-semibold">
            {{ t("migration.identifier") }}
          </p>
          <p class="m-0">
            {{ difference.identifier }}
          </p>
        </div>
        <div class="flex gap-2">
          <div
            v-if="difference.was"
            class="w-1/2"
          >
            <p class="mb-0 font-semibold">
              Was:
            </p>
            <p class="m-0">
              <pre class="bg-gray-50 p-2 rounded-md">
                            {{ difference.was }}
<!--                            {{ JSON.parse(JSON.stringify(difference.was).trim()) }}-->
                        </pre>
            </p>
          </div>
          <div
            v-if="difference.is"
            class="w-1/2"
          >
            <p class="mb-0 font-semibold">
              Is:
            </p>
            <p class="m-0">
              <pre class="bg-gray-50 p-2 rounded-md">
                                {{ difference.is }}
                            </pre>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { getNestedBreadcrumbTitles, useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { useStore } from '@/composables/useTypedStore';
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    props: {
        difference: {
            type: Object,
            required: true
        },
        activeTab: {
            type: String,
            required: true
        }
    },
    setup(props: any, ctx: any) {
        const { t } = useI18n({ useScope: "global" });
        const store = useStore();

        const seeEverything = ref(false)

        const ba = computed(() => {
            return store.state.currentHzba.currentBa;
        });

        const frage = useIdentifierFrageFinder(ba.value, props.difference.absolutePath)

        console.log("Frage:      ",  frage)

        return { getNestedBreadcrumbTitles, t, ba, seeEverything, frage }
    },
})
</script>
