<template>
  <ion-page>
    <toolbar :title="t('hzba.bestandsaufnahmen')">
      <template #trailing>
        <UserDialogButton />
      </template>
    </toolbar>
    <ion-content>
      <ion-refresher
        slot="fixed"
        @ionRefresh="doRefresh($event)"
      >
        <ion-refresher-content />
      </ion-refresher>

      <div style="background: white" class="pt-8">
        <div class="container pb-4 px-4 lg:px-4">
          <div class="mb-4">
            <h1 class="flex-1 text-2xl md:text-3xl lg:mx-auto">
              {{ t("hzba.bestandsaufnahmen") }}
            </h1>
          </div>

          <Searchbar
            v-model:search-term="searchTerm"
            v-model:sort-term="sortTerm"
          />
          <Filters
            v-model:selected-filters="selectedFilters"
            :filter-options="filterOptions"
          />
        </div>
      </div>

      <div class="container pb-4 px-4 lg:px-4">
        <div class="py-4 block md:flex flex-row-reverse text-xl items-center">
          <div class="flex-1 md:text-right">
            <sort-by
              v-model:sort-term="sortTerm"
              :sort-terms="supportedSortTerms"
            />
          </div>
          <div>
            {{ t("hzba.zuletztAktualisiertAm") }}: {{ lastFetch }} <a-button @click="() => doRefresh($event)">
              Refresh
            </a-button>
          </div>
        </div>


        <offline-hint
          class="mb-4"
          style="margin-top: 0;"
        >
          Aktuell besteht keine Internetverbindung. Nur offline gespeicherte Bestandsaufnahmen werden angezeigt. <!-- Todo i18n -->
        </offline-hint>


        <hzba-list
          v-if="loaded"
          :hzbas="bas"
        />
        <Skeleton v-else />

        <div
          class="flex flex-row items-center justify-center"
        >
          <AButton
            type="submit"
            :btn-primary="true"
            class="px-16 py-6 mb-2 text-lg"
            data-cy="button-login"
            style="margin-top: 32px !important; margin-left: 0"
            @click="loadMore()"
          >
            {{ t("buttons.loadMore") }}
          </AButton>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { useI18n } from "vue-i18n";
import {
  IonPage,
  IonContent,
  onIonViewWillEnter,
  IonButtons,
  IonButton,
  IonIcon,
  IonImg,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
} from "@ionic/vue";
import { useStore } from "@/composables/useTypedStore";
import Toolbar from "@/components/Navigation/Toolbar";
import { computed, onMounted, Ref, ref, watch } from "vue";
import HzbaList from "@/components/hzba/HzbaList.vue";

import { getPhotoUrl } from "@/utilities/get-media-url";
import useUser from "@/composables/useUser";
import { popoverHelper } from "@/utilities/popover-helper";
import Immobilie from "@/models/immobilie.model";
import useFetchData from "@/composables/useFetchData";
import Skeleton from "../components/Skeleton.vue";
import { getLocalMetaData } from "@/models/local-meta-data";
import { swapVerticalOutline } from "ionicons/icons";
import moment from "moment";
import Searchbar from "@/components/hzba/Base/Searchbar.vue";
import UserDialogButton from "@/components/User/UserDialogButton.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import { useBestandsaufnahmens } from "@/composables/Bestandsaufnahme/useBestandsaufnahmens";
import AButton from "@/components/Base/AButton";
import OfflineHint from "@/components/Other/OfflineHint.vue";
import Filters from "@/components/Base/Filters.vue";
import SortBy from "@/components/Base/SortBy.vue";
import {SortTermActive} from "@/composables/Sort/SortTerms";

export default {
  name: "HzbasPage",
  components: {
    SortBy,
    Filters,
    OfflineHint,
    Searchbar,
    Skeleton,
    Toolbar,
    IonPage,
    IonContent,
    HzbaList,
    IonButton,
    IonButtons,
    IonImg,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    UserDialogButton,
    AButton
  },
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const store = useStore();
    const uUser = useUser();
    const useData = useFetchData();

    const { bestandsaufnahmes, loadBestandsaufnahmens, filterOptions, supportedSortTerms } = useBestandsaufnahmens();


    const searchTerm = ref<string | undefined>("");
    const sortTerm = ref<SortTermActive | undefined>(BestandsaufnahmeModel.getters("sortBy"));

    onIonViewWillEnter(
      () => { 
        store.commit("app/showBottomNavigation"); 
        loadBestandsaufnahmens();
      }
);



    const bas = computed(() => {
      return bestandsaufnahmes.value.map((el) => new Bestandsaufnahme(el.copyJson()));
    });
    const loaded = computed(() => BestandsaufnahmeModel.getters("loaded"));

    const lastFetch = computed(() => {
      const date = moment(getLocalMetaData("lastBackendFetch"));
      return `${date.format("DD.MM.YYYY")} / ${date.format("HH:mm:ss")} Uhr`;
    });

    const selectedFilters = ref([]);

    watch(() => bas, () => {
      console.log('** BA changed***')
      // return bestandsaufnahmes.value.map((el) => new Bestandsaufnahme(el.copyJson()));
    })
    watch([searchTerm, sortTerm, selectedFilters], () => {
      BestandsaufnahmeModel.dispatch("setSearchTerm", searchTerm.value);
      BestandsaufnahmeModel.dispatch("setSortBy", sortTerm.value);
      BestandsaufnahmeModel.dispatch("setStateFilter", selectedFilters);
      BestandsaufnahmeModel.dispatch("setPage", 0);
      BestandsaufnahmeModel.deleteAll();
      loadBestandsaufnahmens();
    });

    const showLoadMore = computed(() => {
      return (
        BestandsaufnahmeModel.getters("pageCount") &&
        BestandsaufnahmeModel.getters("page") <
        BestandsaufnahmeModel.getters("pageCount")!
      );
    });

    const loadMore = () => {
      loadBestandsaufnahmens();
    };

    return {
      bas,
      user: uUser.user,
      getPhotoUrl,
      openPopover: popoverHelper,
      loaded,
      doRefresh: useData.doRefresh,
      searchTerm,
      supportedSortTerms,
      sortTerm,
      lastFetch,
      swapVerticalOutline,
      t,
      filterOptions,
      selectedFilters,
      showLoadMore,
      loadMore,
    };
  },
};
</script>

<style scoped>
.ion-searchbar {
  --background: white !important;
  --background-color: white !important;
  --border-radius: 8px;
  --box-shadow: 1px 1px 1px rgb(206, 206, 206);
  padding: 0px !important;
}
</style>
