<template>
  <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content">
    <div class="landingpage-background w-screen h-screen">
      <div class="flex flex-col justify-between" style="height: 1000px">
        <tng-header :ba="ba" :immobilie="immobilie" :lang="lang" />
        <div class="text-xl md:text-xl font-bold">Zuwegung Mast</div>
        <div class="content h-full max-h-full">
          <div
            class="mt-0 grid grid-cols-8 justify-items-stretch items-center gap-y-3"
          >
            <div class="text-lg md:text-lg col-span-8">Allgemeine Punkte</div>

            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              Frage
            </div>
            <div
              class="text-sm font-bold col-span-3"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Beschreibung/Eingabewerte
            </div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Weitere Eingaben
            </div>
            <div
              class="col-span- text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Bewertung
            </div>
            <!-- Beschaffenheit start-->
            <div class="text-xs col-span-2">Beschaffenheit:</div>
            <div class="text-xs text-gray-500 col-span-5">
              <ul
                v-for="(v, key) in useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.beschaffenheit.beschaffenheit'
                )['eingabeMehrfachAuswahlWerts']"
                :key="key"
                class=""
              >
                <li :key="key" class="text-xs text-gray-500">
                  {{ v }}
                </li>
              </ul>
            </div>
            <bewertung-chip
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.beschaffenheit.beschaffenheit-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            />
            <!-- Nutzungsart Ende-->

            <!-- Gefälle start-->
            <is-vorhanden-char-and-label
              :ba="ba"
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.gefaelle.gefaelle-vorhanden"
              label="Gefälle"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-5">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.gefaelle.gefaelle-beschreibung'
                )['eingabeText']
              }}
            </div>
            <bewertung-chip
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.gefaelle.gefaelle-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            />
            <oneto-five-image-div
              :ba="ba"
              :immobilie="immobilie"
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.gefaelle.gefaelle-bilder"
              :lang="lang"
              class="col-span-8"
            />

            <!-- Gefälle Ende-->
            <!-- Ausholzung Startz-->
            <is-vorhanden-char-and-label
              :ba="ba"
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.ausholzung.ausholzung-erforderlich"
              label="Ausholzung erforderlich"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-3">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.ausholzung.ausholzung-beschreibung'
                )['eingabeText']
              }}
            </div>
            <div class="text-xs">Bereich:</div>
            <div class="text-xs text-gray-500">{{
            useIdentifierFrageFinder(
                  ba,
                   "rippistein-bauflaechen.zuwegung-mast-tab.ausholzung.ausholzung-bereich"
                )['eingabeAuswahlWert']
           }}</div>
            <bewertung-chip
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.ausholzung.ausholzung-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            />
            <oneto-five-image-div
              :ba="ba"
              :immobilie="immobilie"
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.ausholzung.ausholzung-bilder"
              :lang="lang"
              class="col-span-8"
            />

            <!-- Ausholzung ende-->
            <!-- Rodung Start-->
            <is-vorhanden-char-and-label
              :ba="ba"
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.rodung.rodung-erforderlich"
              label="Rodung erforderlich"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-3">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.rodung.rodung-beschreibung'
                )['eingabeText']
              }}
            </div>
            <div class="text-xs">Anzahl Bäume:</div>
            <div class="text-xs text-gray-500">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.rodung.anzahl-baeume'
                )['eingabeText']
              }}
            </div>

            <bewertung-chip
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.rodung.rodung-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            />
            <oneto-five-image-div
              :ba="ba"
              :immobilie="immobilie"
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.rodung.rodung-bilder"
              :lang="lang"
              class="col-span-8"
            />

            <!-- Rodung Ende-->
            <!-- Zuwegung Start-->
            <is-vorhanden-char-and-label
              :ba="ba"
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.ausholzung.ausholzung-erforderlich"
              label="Breite der Zuwegung"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-3">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.zuwegung.zuwegung-breite-beschreibung'
                )['eingabeText']
              }}
            </div>
            <div class="text-xs">Breite der Zuwegung bis:</div>
            <div class="text-xs text-gray-500">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.zuwegung.zuwegung-breitevonbis'
                )?.['eingabeText']
              }}
            </div>
            <bewertung-chip
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.zuwegung.zuwegung-breite-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            />

            <!-- Zuwegung Ende-->
            <!-- Beschränkung Start-->
            <is-vorhanden-char-and-label
              :ba="ba"
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.beschraenkung.beschraenkung-vorhanden"
              label="Beschränkung vorhanden"
              class="col-span-2"
            />
            <div class="text-xs col-span-1">Art:</div>
            <div class="text-xs text-gray-500 col-span-2">
              <ul
                v-for="(v, key) in useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.beschraenkung.beschraenkung-art'
                )['eingabeMehrfachAuswahlWerts']"
                :key="key"
                class=""
              >
                <li :key="key" class="text-xs text-gray-500">
                  {{ v }}
                </li>
              </ul>
            </div>
            <div class="text-xs text-xs">Gewicht:</div>
            <div class="text-xs text-gray-500">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.beschraenkung.beschraenkung-gewicht-wert'
                )['eingabeText']
              }}
            </div>
            <bewertung-chip
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.beschraenkung.beschraenkung-gewicht-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            />
            <div class="col-span-5"></div>
            <div class="text-xs text-xs">Höhe:</div>
            <div class="text-xs text-gray-500">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.beschraenkung.beschraenkung-hoehe-wert'
                )['eingabeText']
              }}
            </div>
            <bewertung-chip
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.beschraenkung.beschraenkung-hoehe-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            />

            <!-- Beschränkung Ende-->
            <!-- Ringverkehr Start-->
            <div class="text-xs col-span-2">Ringverkehr:</div>
            <div class="text-xs text-gray-500 col-span-3">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.ringverkehr.ringverkehr-beschreibung'
                )['eingabeText']
              }}
            </div>
            <div class="text-xs">möglich?</div>
            <div class="text-xs text-gray-500">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.ringverkehr.ringverkehr-moeglich'
                )['eingabeAuswahlWert']
              }}
            </div>
            <bewertung-chip
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.ringverkehr.ringverkehr-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            />

            <!-- Ringverkehr Ende-->
            <!-- Ausweichflächen Start-->
            <div class="text-xs col-span-2">Ausweichflächen:</div>
            <div class="text-xs text-gray-500 col-span-3">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.ausweichflaechen.ausweichflaechen-beschreibung'
                )['eingabeText']
              }}
            </div>
            <div class="text-xs col-span-2">
              <span class="text-xs text-gray-500">{{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.ausweichflaechen.ausweichflaechen'
                )['eingabeAuswahlWert']
              }}</span>
            </div>
            <bewertung-chip
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.ausweichflaechen.ausweichflaechen-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            />
            <!-- Ausweichflächen Ende-->
            <!-- Alternative Zufahrt Start-->
            <div class="text-xs col-span-2">Alternative Zufahrt:</div>
            <div class="text-xs text-gray-500 col-span-3">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.alternative-zufahrt.alternative-zufahrt-beschreibung'
                )['eingabeText']
              }}
            </div>
            <div class="text-xs text-gray-500 col-span-2">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.alternative-zufahrt.alternative-zufahrt'
                )['eingabeAuswahlWert']
              }}
            </div>
            <bewertung-chip
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.alternative-zufahrt.alternative-zufahrt-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            />

            <!-- Alternative Zufahrt Ende-->
            <!-- Sonstiges Start-->
            <is-vorhanden-char-and-label
              :ba="ba"
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.sonstiges.sonstiges-vorhanden"
              label="Sonstiges"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-5">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.zuwegung-mast-tab.sonstiges.sonstiges'
                )['eingabeText']
              }}
            </div>
            <bewertung-chip
              frages-path="rippistein-bauflaechen.zuwegung-mast-tab.sonstiges.sonstiges-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            />

            <!-- Sonstiges Ende-->
            <!-- GRID  ende-->
          </div>
          <div class="mt-4 text-lg md:text-lg">Grabenquerungen</div>
          <!-- Geplante Baumaßnahme Content Ende-->

          <div v-if="getGrabenquerungen(ba).length > 0">
            <div class="grid grid-cols-8 justify-items-stretch items-center">
              <div
                class="col-span-2 text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
              >
                Frage
              </div>
              <div
                class="text-sm font-bold col-span-3"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Beschreibung/Eingabewerte
              </div>
              <div
                class="col-span-2 text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Weitere Eingaben
              </div>
              <div
                class="col-span- text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Bewertung
              </div>
            </div>
            <div
              v-for="(grabenquerung, key) in getGrabenquerungen(ba)"
              :key="key"
              class="ml-2 grid grid-cols-8 justify-items-stretch items-center"
              style="
                border-style: solid;
                border-color: white white white rgb(216, 215, 26);
                border-radius: 5px;
              "
            >
            <div class="text-xs col-span-2">Art: </div>
            <div class="text-xs col-span-3"> {{ grabenquerung['art'] }} </div>
              <div class="text-xs text-gray-500 col-span-2">
                {{ grabenquerung['graben-beschreibung'] }}
              </div>
              <div class="justify-self-end row-span-2">
                <PdfChip
                  v-if="'plus' === grabenquerung['graben-bewertung']"
                  color="green"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
                <PdfChip
                  v-if="'o' === grabenquerung['graben-bewertung']"
                  color="blue"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
                <PdfChip
                  v-if="'minus' === grabenquerung['graben-bewertung']"
                  color="yellow"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
                <PdfChip
                  v-if="'x' === grabenquerung['graben-bewertung']"
                  color="red"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
              </div>
              <div class="col-span-2 text-xs">Abstand</div>
              <div class="text-xs text-gray-500 col-span-3">
                {{ grabenquerung['abstand'] ==null ? '': grabenquerung['abstand']}}
              </div>
              <div class="text-xs md:text-xs">Typ:</div>
              <div class="text-xs text-gray-500 col-span-3">
                {{ grabenquerung['graben-typ'] }}
              </div>
              <oneto-five-image-div
                :ba="null"
                :immobilie="null"
                fragesPath="null"
                :lang="lang"
                :imageObjects="grabenquerung['graben-foto']"
                class="ml-2 col-span-8"
              >
              </oneto-five-image-div>
            </div>
          </div>
          <div v-else>
            <div
              class="mt-2 grid grid-cols-8 justify-items-stretch items-center"
            >
              <div
                class="justify-center items-center col-span-8"
                style="
                  background-color: rgb(235, 239, 240);
                  border-style: solid;
                  border-color: rgb(235, 239, 240) rgb(235, 239, 240)
                    rgb(235, 239, 240) rgb(235, 239, 240);
                  border-radius: 5px;
                "
              >
                <p class="text-xs text-center">
                  Keine Grabenquerungen aufgenommen
                </p>
              </div>
            </div>
          </div>

          <div class="text-lg md:text-lg">Bauwerke</div>

          <div v-if="getBauwerke(ba).length > 0">
          <div class="grid grid-cols-8 justify-items-stretch items-center">
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              Frage
            </div>
            <div
              class="text-sm font-bold col-span-3"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Beschreibung/Eingabewerte
            </div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Weitere Eingaben
            </div>
            <div
              class="col-span- text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Bewertung
            </div>
          </div>
          <div
            v-for="(bauwerk, key) in getBauwerke(ba)"
            :key="key"
            class="ml-2 mt-2 grid grid-cols-8 justify-items-stretch items-center"
            style="
              border-style: solid;
              border-color: white white white rgb(216, 215, 26);
              border-radius: 5px;
            "
          >
            <div class="text-xs col-span-2 ml-2 mt-2">Bauwerke:</div>
            <div class="text-xs col-span-3 text-gray-500">
              {{ bauwerk['freitext'] }}
            </div>
            <div class="text-xs md:text-xs">Tragfähig:</div>
            <div class="text-xs text-gray-500">
              {{ bauwerk['tragfaehig'] }}
            </div>
            <div class="justify-self-end row-span-2">
                <PdfChip
                  v-if="'plus' === bauwerk['bauwerk-bewertung']"
                  color="green"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
                <PdfChip
                  v-if="'o' === bauwerk['bauwerk-bewertung']"
                  color="blue"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
                <PdfChip
                  v-if="'minus' === bauwerk['bauwerk-bewertung']"
                  color="yellow"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
                <PdfChip
                  v-if="'x' === bauwerk['bauwerk-bewertung']"
                  color="red"
                  mangel-amount-mode
                  value="1"
                  pdf-mode
                />
                </div>
            <div class="col-span-2"></div>
            <div class="text-xs col-span-3 text-gray-500">
              {{ bauwerk['beschreibung'] }}
            </div>
            <oneto-five-image-div
              :ba="null"
              :immobilie="null"
              fragesPath="null"
              :lang="lang"
              :imageObjects="bauwerk['bild']"
              class="ml-2 col-span-8"
            >
            </oneto-five-image-div>
          </div>
          </div>
          <div v-else> <div
                class="justify-center items-center col-span-8"
                style="
                  background-color: rgb(235, 239, 240);
                  border-style: solid;
                  border-color: rgb(235, 239, 240) rgb(235, 239, 240)
                    rgb(235, 239, 240) rgb(235, 239, 240);
                  border-radius: 5px;
                "
              >
                <p class="text-xs text-center">
                  Keine Bauwerke aufgenommen
                </p>
              </div></div>
        </div>
      </div>
      <bauflaechen-footer :ba="ba" :immobilie="immobilie" :lang="lang" />
      <!-- ab hier nicht löschen-->
    </div>
  </pdf-a4-page>
</template>

<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { getPhotoUrl } from '@/utilities/get-media-url';
import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';

import PdfChip from '../Components/PdfChip.vue';
import { useBauflaechen } from './useBauflaechen';
import UniversalPdfImageRow from '../Components/UniversalPdfImageRow.vue';
import TngHeader from './TngHeader.vue';
import BauflaechenFooter from './BauflaechenFooter.vue';
import OnetoFiveImageDiv from './OnetoFiveImageDiv.vue';
import BewertungChip from './BewertungChip.vue';
import isVorhandenCharAndLabel from './isVorhandenCharAndLabel.vue';

export default defineComponent({
  name: 'ZuwegungMast',
  components: {
    PdfA4Page,
    PdfChip,
    UniversalPdfImageRow,
    TngHeader,
    BauflaechenFooter,
    OnetoFiveImageDiv,
    BewertungChip,
    isVorhandenCharAndLabel,
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    const { getGrabenquerungen, getBauwerke } = useBauflaechen();

    return {
      user: uUser.user,
      t,
      useIdentifierFrageFinder,
      moment,
      getPhotoUrl,
      getGrabenquerungen,
      getBauwerke,
    };
  },
});
</script>
<style scoped>
* {
  color: rgb(23, 68, 81);
  font-family: 'Avenir-Next-LT-Pro-Regular';
}
.pdf-content {
  width: 800px;
  margin: 0 auto;
  color: white;
}

.landingpage-background {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 700px;
  height: 1050px;
  color: black;
}

.trenner {
  height: 1px;
  border-width: 0;
  color: gray;
  background-color: gray;
}

.content {
  height: 870px;
}
</style>