<template>
  <div
    class="container absolute bottom-12"
    data-cy="section-summary"
  >
    <div class="section-summary py-0 flex flex-row justify-between">
      <div>
        <h2 class="m-1">
          Gesamtergebnis {{ title }}
        </h2>
        <div
          :class="[
            data.description1?.color === 'red' ? 'red' : '',
            data.description1?.color === 'yellow' ? 'yellow' : '',
            data.description1?.color === 'blue' ? 'blue' : '',
          ]"
          class="text-xs m-1"
        >
          {{ data?.description1?.value }}
        </div>
      </div>
      <div class="my-1">
        <PdfMSEValues :data="data" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import PdfChip from "@/components/Pdf/Components/PdfChip";
import PdfMSEValues from "@/components/Pdf/Components/PdfMSEValues";
import { defineComponent, PropType } from "vue";
import { ExtendedMSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
export default defineComponent({
  name: "PdfDataSectionSummary",
  components: { PdfMSEValues, PdfChip },
  props: {
    data: {
      type: Object as PropType<ExtendedMSERow>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped lang="scss">
.container {
  width: 698px;
  padding: 0px 0px;
}
.section-summary {
  display: flex;
  align-items: center;
  border-top: 1px solid $grey-900;
  border-bottom: 1px solid $grey-900;
}

h2 {
  font-size: 14px;
  font-weight: 900;
}

.red {
  color: $pdfRed1;
}

.yellow {
  color: $pdfYellow1;
}

.blue {
  color: $pdfBlue1;
}
</style>