<template>
  <!-- Header -->
  <ion-header>
    <toolbar
      :title="t('hzba.vorschau.mangellisteVorschauTitel')"
      :enable-desktop-nav="false"
    >
      <template #leading>
        <ion-buttons slot="start">
          <ion-button
            data-cy="photo-preview-close"
            @click="closeModal()"
          >
            <ion-icon
              slot="icon-only"
              :icon="close"
            />
          </ion-button>
        </ion-buttons>
      </template>
      <template #trailing>
        <ion-buttons slot="end">
          <ion-button
            data-cy="button-downloadResource"
            @click="downloadResource()"
          >
            <ion-icon
              slot="icon-only"
              :icon="canShare ? share : download"
            />
          </ion-button>
        </ion-buttons>
      </template>
    </toolbar>
  </ion-header>

  <!-- Content -->
  <ion-content>
    <hzba-group
      v-for="mangel in maengelJson"
      :key="mangel"
      hide-progress
      :title="`${mangel.kurztext} (${mangel.maluspunkte} MP)`"
      class="mangel-item"
      data-cy="mangelItem"
    >
      <div class="px-4">
        <ion-chip>{{ correctSpelling(mangel.kategorie) || "-" }}</ion-chip>
        <ion-chip>
          {{
            correctSpelling(mangel.handlungsbedarfTaetigkeit) || "-"
          }}
        </ion-chip>
        <ion-chip>
          {{
            correctSpelling(mangel.handlungsbedarfZeitpunkt) || "-"
          }}
        </ion-chip>
        <ion-chip>{{ correctSpelling(mangel.gewerk) || "-" }}</ion-chip>
      </div>

      <!--TODO: *** get photo input *** <hzba-photo-input
        v-if="mangel.eingabeMedien"
        v-model="mangel.eingabeMedien"
        readonly
        :label="t('hzba.bilder.titel')"
      /> -->

      <ion-row class="ion-wrap d-flex items-center px-0">
        <ion-col class="ion-float-right p-0 pl-0 flex justify-end">
          <ion-input
            class="flex-grow-10"
          >
            {{ t('hzba.mangel.freitext') }}
          </ion-input>
        </ion-col>
      </ion-row>

      <ion-row 
        class="ion-wrap d-flex items-center mb-1 px-0"
        data-cy="input-row-freitext"
      >
        <ion-col class="ion-float-right p-0 pl-0 flex justify-end">
          <ion-input 
            class="inline-block text-right input-text"
            data-cy="base-input"
          >
            {{ mangel.eingabeFreitext }}
          </ion-input>
        </ion-col>
      </ion-row>
      <hzba-choose-input
        v-if="
          mangel.eingabeRelevant === false ||
            mangel.eingabeRelevant === true
        "
        v-model="mangel.eingabeRelevant"
        :label="t('hzba.mangel.relevant')"
        readonly
      />
    </hzba-group>
  </ion-content>
</template>

<script lang="ts">
import { close, download, list, share } from "ionicons/icons";
import { useI18n } from "vue-i18n";
import HzbaBaseModal from "@/components/Base/BaseModal.vue";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup.vue";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput.vue";
import { computed, defineComponent, PropType } from "vue";
import { arrowBack } from "ionicons/icons";
import {
  IonIcon,
  IonContent,
  IonHeader,
  IonChip,
  IonButton,
  IonButtons,
} from "@ionic/vue";
import SectionWrapper from "@/components/Base/SectionWrapper.vue";
import Toolbar from "@/components/Navigation/Toolbar.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import useBaseModal from "@/composables/useModal";
import useDownloadHelper from "@/composables/useDownloadHelper";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { MangelGewerk, MangelKategorie } from "@/models/ba/Mangelvorlage";
import Immobilie from "@/models/immobilie.model";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import moment from "moment";
import HzbaChooseInput from "@/components/hzba/Base/Input/HzbaChooseInput.vue";

export default defineComponent({
  name: "MaengelUebersichtInImmobilileModal",
  components: {
    HzbaChooseInput,
    BaseModal,
    IonChip,
    Toolbar,
    HzbaBaseInput,
    HzbaGroup,
    HzbaBaseModal,
    SectionWrapper,
    IonIcon,
    IonContent,
    IonHeader,
    IonButton,
    IonButtons,
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
  },
  emits: ["close", "save"],
  setup(props: any) {
    const { t } = useI18n({ useScope: "global" });
    const { defaultCancel } = useBaseModal();

    const maengelJson = computed(() => {
      return props.ba.mangellists && props.ba.mangellists.filter((el: any) => el.eingabeRelevant);
    });

    const { canShare, downloadFile } = useDownloadHelper();

    const correctSpelling = (input:string) => {

      switch(input) {
        
        case 'VERSORGUNGSGEFAEHRDEND': return 'Versorgungsrelevant';
        case 'VERSORGUNGSRELEVANT': return 'Versorgungsrelevant';
        case 'SICHERHEITSRELEVANT': return 'Sicherheitsrelevant';
        case 'SONSTIGER': return 'Sonstiger';
        case 'SERVICE': return 'Service';
        case 'BAU_TISCHLER': return 'Bau/Tischler';
        case 'HEIZUNG_SANITAER_MSR': return 'Heizung/Sanitär/MSR';
        case 'ELEKTRO': return 'Elektro';
        case 'ZEITNAH': return 'Zeitnah';
        case 'ABSOFORT': return 'Ab sofort';
        case 'FEUVO': return 'FeuVO';
        case 'EFFICIENCY': return 'Efficiency';
        case 'HINWEIS': return 'Hinweis';
        case 'SAFETY': return 'Safety';

        default: return input;
      }
    };

    const downloadMangelliste = (
      ba: Bestandsaufnahme,
      downloadFile: Function,
      t: any
    ) => {
      const immobilie = computed(() => {
        return Immobilie.find(ba.immobilie);
      });
      // console.log('immobilie', immobilie);
      const header = [
        t("hzba.maengelliste.mangelname"),
        t("hzba.maengelliste.maluspunkte"),
        t("hzba.maengelliste.freitext"),
        t("hzba.maengelliste.relevant"),
        t("hzba.maengelliste.gewerk"),
        t("hzba.maengelliste.handlungsbedarfZeitpunkt"),
        t("hzba.maengelliste.handlungsbedarfTaetigkeit"),
        t("hzba.maengelliste.kategorie"),
      ];

      const mapMangel = (mangel: any) => {
        return [
          mangel.kurztext,
          mangel.maluspunkte,
          mangel.eingabeFreitext,
          mangel.eingabeRelevant,
          mangel.gewerk,
          mangel.handlungsbedarfZeitpunkt,
          mangel.handlungsbedarfTaetigkeit,
          mangel.kategorie,
        ];
      };

      const rows = [header, ...maengelJson.value.map((el: any) => mapMangel(el))];

        const csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(";")).join("\n");

        const encodedURI = encodeURI(csvContent);
        const fixedEncodedURI = encodedURI.replaceAll('#', '%23');

        downloadFile(
            fixedEncodedURI,
            `${immobilie.value?.strasse}, ${immobilie.value?.plz} ${immobilie.value?.stadt} - ${t('hzba.maengelliste.maengelliste')} ${moment(ba.begehungsdatum).format('DD.MM.YYYY')}.csv`
      );
    };

    const downloadResource = () => {
      downloadMangelliste(props.ba, downloadFile, t);
    };

    return {
      arrowBack,
      closeModal: defaultCancel,
      maengelJson,
      MangelKategorie,
      MangelGewerk,
      canShare,
      t,
      share,
      close,
      downloadResource,
      download,
      correctSpelling
    };
  },
});
</script>

<style scoped>
.button-native {
  background-color: unset !important;
}
.mangel-item {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding-top: 8px;
  padding-bottom: 8px;
}

ion-input {
  padding: 4px !important;
  padding-right: 8px !important;
  padding-left: 16px !important;
  height: auto;
  width: auto;
  text-align: left;
  color: rgb(160, 159, 159);
}
</style>
