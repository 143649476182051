import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-369922de"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  "data-cy": "mangel-item-row",
  style: {"height":"130px","overflow":"hidden"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}