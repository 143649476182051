<template>
  <table
    class="flex-1 w-full"
    data-cy="pdf-MSE-table"
  >
    <tr v-if="showTitle">
      <th class="text-left pl-2 align-text-top">
        {{ positionTranslated }}
      </th>

      <th
        v-if="valueTitle !== undefined && hasDescription1Enabled"
        class="text-left value-col text-center align-text-top"
      >
        {{ valueTitle }}
      </th>

      <th class="numberCol align-text-top">
        <!-- description2 -->
        {{
          hideMangelAnzahl
            ? ""
            : t("hzba.pdf.mangelAnzahl", 1, { locale: lang })
        }}
      </th>

      <th class="chipCol align-text-top">
        {{ t("hzba.pdf.maluswert", 1, { locale: lang }) }} <br>
        <span v-if="!shortTitle">{{
          t("hzba.pdf.gesamt", 1, { locale: lang })
        }}</span>
      </th>
      <th class="chipCol align-text-top">
        {{ t("hzba.pdf.hinweis", 1, { locale: lang }) }}
      </th>

      <th class="chipCol align-text-top">
        {{ t("hzba.pdf.safety", 1, { locale: lang }) }}
        <br>
        <span
          v-if="!shortTitle"
          class="text-xxs"
        >
          {{ t("hzba.pdf.akuteMassnahmeNotwendig", 1, { locale: lang }) }}
        </span>
      </th>

      <th class="chipCol w-1/6 align-text-top">
        {{ t("hzba.pdf.effizienz", 1, { locale: lang }) }} <br>
        <span
          v-if="!shortTitle"
          class="text-xxs"
        >
          {{
            t("hzba.pdf.zeitlichPlanbareMassnahmeNotwendig", 1, {
              locale: lang,
            })
          }}
        </span>
      </th>
    </tr>

    <tr
      v-for="(entry, key) in maxShownItems
        ? data.slice(0, maxShownItems)
        : data"
      :key="key"
      class="data-tr items-center"
      data-cy="MSE-table-data-row"
    >
      <td class="text-left pl-2 numberCol w-1/5">
        <div class="overflow-ellipsis">
          {{ entry.title }}
        </div>
      </td>

      <td
        v-if="hasDescription1Enabled"
        class="text-left value-col text-center"
        data-cy="tableofcontent-page"
      >
        <span>{{ entry.description1 }}</span>
      </td>

      <td
        class="numberCol text-center numberCol w-1/12"
        data-cy="mangeldata"
      >
        <!-- {{mangelData}} -->
        {{ hideMangelAnzahl ? "1" : entry.description2 }}
        <!--        <p v-for="(mangel, key) in entry.maengel" :key="key" class="my-1">-->
        <!--          {{ mangel }}-->
        <!--        </p>-->
      </td>

      <!-- <td
        class="text-right"
        :class="[
          entry.hint && entry.hint.color === 'red' ? 'red' : '',
          entry.hint && entry.hint.color === 'yellow' ? 'yellow' : '',
          entry.hint && entry.hint.color === 'green' ? 'green' : '',
        ]"
      >
        {{ entry.hint && entry.hint.value }}
      </td>-->

      <td
        v-if="entry.malus"
        class="text-center numberCol chip-td w-1/6"
      >
        <PdfChip
          :value="entry.malus?.value"
          :color="entry.malus?.color"
          pdf-mode
        />
      </td>
      <td class="text-center numberCol chip-td w-1/6">
        <PdfChip
          v-if="entry?.hinweis"
          :value="entry.hinweis.value"
          :color="entry.hinweis.color"
          pdf-mode
        />
      </td>

      <td class="text-center numberCol chip-td w-1/6">
        <PdfChip
          v-if="entry?.safety"
          :value="entry.safety.value"
          :color="entry.safety.color"
          pdf-mode
        />
      </td>

      <td class="text-center numberCol chip-td w-1/6">
        <PdfChip
          v-if="entry.efficiency"
          :value="entry.efficiency.value"
          :color="entry.efficiency.color"
          pdf-mode
        />
      </td>
    </tr>

    <tr v-if="maxShownItems && (data.length > maxShownItems)">
      <td colspan="4">
        <slot />
      </td>
    </tr>
  </table>
</template>

<script lang="ts">
import { useI18n } from "vue-i18n";
import { computed, PropType } from "vue";
import PdfChip from "@/components/Pdf/Components/PdfChip";
import { defineComponent } from "vue";
import { ExtendedMSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
import { Fragenblock } from "@/models/ba/Fragenblock";
export default defineComponent({
  name: "PdfMSEDataTable",
  components: { PdfChip },

  props: {
    data: {
      type: Array as PropType<ExtendedMSERow[]>,
      required: true,
    },
    valueTitle: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: "Position",
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    shortTitle: {
      type: Boolean,
      default: false,
    },
    hideMangelAnzahl: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: "de",
    },
    hasDescription1Enabled: {
      type: Boolean,
      default: false,
    },
    maxShownItems: {
      type: Number,
      default: undefined,
    },
    // sortedData: {
    //   type: Array,
    //   default: undefined
    // }
  },

  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });
    const positionTranslated = computed(() => {
      return props.name === "Position" ? t("hzba.pdf.position") : props.name;
    });

    // const sortData = (data: Array) => {
    // let newArray = data;
    // let el = data.splice(-2, 1);
    // console.log('return el: ', el);
    // return props.sortedData = newArray.push(el);
    // };

    return {
      positionTranslated,
      t,
      // sortData
    };
  },
});
</script>

<style scoped lang="scss">
table {
  font-size: 12px;
  margin-top: 0px;
}
tr {
  // border-bottom: 1px solid $grey-800 !important;
  border-bottom: 0.5px solid #838383;
  padding-top: 0px;
  height: 19px;
}

th {
  border-top: none;
  font-size: 12px;
  color: #393a3a;
  font-weight: normal;
  padding-bottom: 4px;
  padding-top: 4px;
}
.overflow-ellipsis {
  white-space: nowrap;
  // width: 100%;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.data-tr {
  height: 22px;
}

.numberCol {
  // width: 70px;
  // width: 150px;
}
.chipCol {
  // width: 70px;
  width: 150px;
}

.red {
  color: $pdfRed1;
}

.yellow {
  color: $pdfYellow1;
}

.green {
  color: $pdfGreen1;
}

.value-col {
  padding: 4px;
}
</style>