<template>
  <div>
    <img
      src="../../../../public/assets/img/svg/acon_logo_colored.svg"
      alt="acon_icon"
      class="acon_logo_colored mb-4"
      style="height: 70px; width: 210px"
    >
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "PdfAconLogo"
})
</script>

<style scoped>
 * {
  margin: 0;
 }
</style>