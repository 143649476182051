
<template>
  <pdf-a4-page
    v-for="(customInputPage, key) in pagedCustomInput"
    :key="key"
    :pdf-key="lang"
  >
    <pdf-page-header
      :immobilie="immobilie"
      :hzba="ba"
      :tech-data="techData"
      :is-hinweis-uebersicht="true"
      :page="pagesOptions.currentStartPage + key"
      :max-pages="pagesOptions.maxPages"
      :lang="lang"
    />

    <h2
      v-if="key === 0"
      class="mt-4 text-base font-bold"
      data-cy="custom-input-title"
    >
      {{ t("hzba.pdf.weitereHinweise", 1, { locale: lang }) }}
    </h2>

    <table
      v-if="customInputPage && customInputPage.length > 0"
      class="flex-1 w-full my-2"
      data-cy="custom-input-table"
    >
      <th />
      <th class="text-left numberCol data-tr">
        {{ t("hzba.pdf.customInputBezeichnung", 1, { locale: lang }) }}
      </th>
      <th class="text-left numberCol data-tr">
        {{ t("hzba.pdf.gewerk", 1, { locale: lang }) }}
      </th>

      <pdf-row-custom-input
        v-for="(customInputData, key) in customInputPage"
        :key="key"
        :custom-input-data="customInputData"
        :odd="key % 2 === 0"
      />
    </table>
    <div
      v-else
      data-cy="keine-maengel-vorhanden"
    >
      Keine weiteren Hinweise vorhanden.
    </div>

    <pdf-color-codes
      class="ml-1"
      :lang="lang"
    />
  </pdf-a4-page>
</template>


<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import PdfA4Page from "@/components/Pdf/Components/PdfA4Page.vue";
import PdfDataTable from "@/components/Pdf/Components/PdfDataTable.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { useI18n } from "vue-i18n";
import PdfRowMangel from "@/components/Pdf/Components/PdfRowMangel.vue";
import PdfPageHeader from "@/components/Pdf/Components/PdfPageHeader.vue";
import { Fragenblock } from "@/models/ba/Fragenblock";
import Immobilie from "@/models/immobilie.model";
import PdfColorCodes from "@/components/Pdf/Components/PdfColorCodes.vue";
import PdfRowCustomInput from "@/components/Pdf/Components/PdfRowCustomInput.vue";
import {usePdfContentSlicer} from "@/components/Pdf/Bericht_Heizung/usePdfContentSlicer";
import {CustomInputData} from "@/components/Pdf/Bericht_Heizung/useHzbaPdfReport";

export default defineComponent({
  name: "PdfCustomInputPage",
  components: {
    PdfRowCustomInput,
    PdfColorCodes,
    PdfPageHeader,
    PdfRowMangel,
    PdfDataTable,
    PdfA4Page,
  },
  props: {
    lang: {
      type: String,
      required: true,
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    customInputPageCount: {
      type: Number,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    customInputs: {
      type: Object as PropType<CustomInputData[]>,
      required: true
    },
    pagesOptions: {
      type: Object as PropType<{ currentStartPage: any; maxPages: any }>,
      required: true,
    },
    techData: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
  },
emits: ['update:customInputPageCount'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });

    const pagedCustomInput = computed(() => {
      const pagedInput = usePdfContentSlicer<CustomInputData>(props.customInputs || [], 26, 2, (element: CustomInputData) => {
        return element.bilder ? 4 : null
      });

      emit("update:customInputPageCount", pagedInput.length);
      return pagedInput;
    });

    return {
      pagedCustomInput,
      t,
    };
  },
});
</script>

<style scoped lang="scss">
table {
  border-bottom: none;
}
tr {
  border-bottom: 0.5px solid #838383;
  border-top: 0.5px solid #838383;
}
th {
  // border-bottom: 1px solid $grey-500;
  border-top: none;
  font-size: 12px;
  color: #393a3a;
  font-weight: normal;
  padding-bottom: 4px;
  padding-top: 4px;
}
</style>