import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-fa6f1e08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-4" }
const _hoisted_2 = ["id", "data-cy"]
const _hoisted_3 = { class: "block lg:flex" }
const _hoisted_4 = { class: "my-4" }
const _hoisted_5 = ["id", "data-cy"]
const _hoisted_6 = { class: "block lg:flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_a_pdf_thumbnail = _resolveComponent("a-pdf-thumbnail")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    "data-cy": "pdfGalleryModal",
    title: $props.title,
    "hide-save": "",
    "go-back-mode": "",
    onClose: _cache[0] || (_cache[0] = () => $setup.cancel())
  }, {
    cta: _withCtx(() => [
      _createVNode(_component_ion_buttons, { class: "justify-end" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_button, {
            "data-cy": "downloadResource",
            onClick: $setup.downloadResources
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "icon-only",
                icon: $setup.canShare ? $setup.share : $setup.download
              }, null, 8, ["icon"])
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        ref: "content",
        "data-cy": "pdfGalleryContent"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.pdfs, (pdf, key) => {
              return (_openBlock(), _createElementBlock("div", {
                id: `pdf-${key}`,
                key: key,
                class: "px-4 pt-4",
                "data-cy": `dokument-${key}`
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_a_pdf_thumbnail, {
                    id: `${pdf.name}_${key}`,
                    "set-to-max": true,
                    url: $setup.getPdfUrl(pdf),
                    "data-cy": `dokument-${key}`
                  }, null, 8, ["id", "url", "data-cy"]),
                  ($props.onDelete && !$props.isReadonly)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        fill: "clear",
                        "data-cy": "button-delete-dokument",
                        onClick: ($event: any) => ($props.onDelete(pdf))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: $setup.trashOutline }, null, 8, ["icon"])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true),
                  ($props.downloadEnabled)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        color: "light",
                        class: "lg:ml-2",
                        onClick: () => $setup.downloadResource(pdf)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            icon: $setup.canShare ? $setup.share : $setup.download
                          }, null, 8, ["icon"])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true)
                ])
              ], 8, _hoisted_2))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.imgs, (photo, key) => {
              return (_openBlock(), _createElementBlock("div", {
                id: `photo-${key}`,
                key: photo,
                class: "px-4 pt-4",
                "data-cy": `photo-${key}`
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_a_image, {
                    src: $setup.getPhotoUrl(photo),
                    "image-class": 'w-full h-auto',
                    "data-cy": `image-${key}`
                  }, null, 8, ["src", "data-cy"]),
                  ($props.downloadEnabled)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        color: "light",
                        class: "lg:ml-2",
                        onClick: () => $setup.downloadResource(photo)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            icon: $setup.canShare ? $setup.share : $setup.download
                          }, null, 8, ["icon"])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true)
                ])
              ], 8, _hoisted_5))
            }), 128))
          ])
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["title"]))
}