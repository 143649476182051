<template>
  <ion-content
    data-cy="selector-add-item"
  >
    <ion-item
      v-for="item in items"
      :key="item"
      lines="none"
      class="cursor-pointer select-none"
      @click="chooseItem(item)"
    >
      <div 
        v-if="currentItem && currentItem.label === item.label"
        class="flex-1"
      >
        <strong>{{ item.label }}</strong>
      </div>
      <div
        v-else
        class="flex-1"
      >
        {{ item.label }}
      </div>
      
      <div v-if="currentItem && currentItem.label === item.label && currentItem.orderBy === 'asc'">
        <IonIcon
          :icon="chevronUpOutline"
          size="default"
        />
      </div>
      <div v-else-if="currentItem && currentItem.label === item.label && currentItem.orderBy === 'desc'">
        <IonIcon
          :icon="chevronDownOutline"
          size="default"
        />
      </div>
    </ion-item>
    
    <div class="mx-4 mb-4">
      <AFlatButton
        :expand="true"
        @click="dismiss"
      >
        {{ t("hzba.buttons.filterAnwenden") }}
      </aFlatbutton>
    </div>
  </ion-content>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {IonContent, IonItem, popoverController, IonButton, IonIcon} from '@ionic/vue';
import {defineComponent, PropType, ref, Ref} from 'vue';
import { chevronUpOutline, chevronDownOutline } from "ionicons/icons";
import AFlatButton from "@/components/Base/AFlatButton.vue";
import {SortTerm, SortTermActive} from "@/composables/Sort/SortTerms";

export default defineComponent({
  name: 'SortByPopover',
  components: {AFlatButton, IonContent, IonItem, IonButton, IonIcon },
  props: {
    items: {
      type: Array as PropType<SortTerm[]>,
      required: true
    },
    preselectedItem: {
      type: Object as () => SortTermActive,
      default: undefined,
    }
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })

    const currentItem: Ref<SortTermActive | undefined> = ref(JSON.parse(JSON.stringify(props.preselectedItem)));

    function dismiss() {
      popoverController.dismiss(currentItem.value);
    }

    function chooseItem(item: SortTerm) {
      const orderBy = currentItem.value?.orderBy;

      if (item.label !== currentItem.value?.label) {
        currentItem.value = {
          label: item.label,
          fieldName: item.fieldName,
          subObject: item.subObject,
          orderBy: 'asc'
        }
      } else if (currentItem.value && item.label === currentItem.value?.label && orderBy === 'asc'){
        currentItem.value.orderBy = 'desc';
      } else if (currentItem.value && item.label === currentItem.value?.label && orderBy === 'desc') {
        currentItem.value.orderBy = 'asc';
      }
    }

    return {
      chooseItem,
      currentItem,
      dismiss,
      chevronUpOutline,
      chevronDownOutline,
      t
    }
  }
});
</script>

<style scoped>
ion-content {
  --background: white;
}
</style>