<template>
  <div
    :id="identifier"
    ref="mapContainer"
    class="mapElement"
  >
    <geo-layer-toggle :map="map" />
    <focus-controller
      :map="map"
      :center="props.mapConfig.mapStyle.mapCenter"
    />
    <div :class="['calculation-box', { visible: isGeodataVisible }]">
      <div
        id="info"
        v-html="geodataInfo"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import FocusController from "@/components/hzba/Base/FocusController.vue";
import GeoLayerToggle from "@/components/hzba/Base/GeoLayerToggle.vue";
import { GeoJsonConfig } from "@/models/ba/interfaces/IFrage";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";
import * as turf from "@turf/turf";
import { GeolocateControl, Map, NavigationControl } from "maplibre-gl";
import { PropType, onMounted, onUnmounted, ref } from "vue";
import mapStyleJson from "../../../../public/assets/geodata/style_ario.json";
const geodataInfo = ref("");
const isGeodataVisible = ref(false);

const props = defineProps({
  identifier: {
    type: String,
    required: true
  },
  mapConfig: {
    type: Object as PropType<GeoJsonConfig>,
    default: null
  },
  minMax: {
    type: Object as PropType<any>,
    default: null
  },
  inputJson: {
    type: Object,
    default: undefined
  },
  readonly: {
    type: Boolean,
    default: false
  },
});

const emit = defineEmits([
  "map:loaded",
  "map:storeGeodata",
  "map:deleteGeodata",
  "update:inputJson"
]);

const mapStyleMultilayer = ref(Object.assign(mapStyleJson));
const mapContainer = ref();
const selectedFeature = ref(null);
const selectedGeodataInfo = ref(null);
const map = ref();
let draw: MapboxDraw;
let drawOptions: any;
const controlsMap: any = {
  line_string: "mapbox-gl-draw_line",
  point: "mapbox-gl-draw_point",
  polygon: "mapbox-gl-draw_polygon"
};
const drawControls: any = [];

function collectControlElements() {
  const mapElement = document.getElementById(props.identifier);

  Object.keys(controlsMap).forEach((key) => {
    if (drawOptions.controls[key] === true) {
      drawControls.push(
        mapElement?.getElementsByClassName(controlsMap[key])[0]
      );
    }
  });
}

function setControlVisibility(displayValue: string) {
  drawControls.forEach((control: { style: { display: string } }) => {
    control.style.display = displayValue;
  });
}

function showGeodata(features) {
  for (let i = 0; i < features.length; i++) {
    features[i].properties.calc = calcGeo(features[i]);
    features[i].properties.title =  features[i].properties.calc.label
  }
  let geodataText = "";

  features.forEach((element, index) => {
    const title = element.properties.title ? element.properties.title : "-"; 
    const id = element.id;
    const bold = id == selectedFeature.value?.id ? "bold-text" : "";
    geodataText += `<span class="geodata-entry ${bold}" data-feature-id="${id}">${title}</span><br>`;
  });

  geodataInfo.value = geodataText;
  isGeodataVisible.value = features.length > 0; 
}

function emitStoreGeodata() {
  const drawnFeatures: any = draw.getAll();
  emit("update:inputJson", drawnFeatures);

  if (props.minMax?.max && drawnFeatures.features.length >= props.minMax.max) {
    setControlVisibility("none");
  }
  showGeodata(drawnFeatures?.features);
}

function emitDeleteGeodata() {
  const featuresAfterDelete: any = draw.getAll();

  emit("update:inputJson", featuresAfterDelete);

  if (
    props.minMax?.max &&
    featuresAfterDelete.features.length <= props.minMax.max
  ) {
    setControlVisibility("block");
  }
  showGeodata(featuresAfterDelete?.features);
}

function emitLoaded() {
  emit("map:loaded");
}
function handleSelection(feature) {
  const featureId = feature.id;
  selectedFeature.value = feature;

  if (selectedGeodataInfo.value) {
    selectedGeodataInfo.value.classList.remove("bold-text");
  }

  selectedGeodataInfo.value = document.querySelector(
    `[data-feature-id="${featureId}"]`
  );

  if (selectedGeodataInfo.value) {
    selectedGeodataInfo.value.classList.add("bold-text");
  }
}
function calcGeo(props: any) {
      const { type, coordinates } = props.geometry;
      let val = null;
      let unit = "";

      let lat, lng, latDirection, lngDirection; // Declare the variables outside the switch statement

      switch (type) {
        case "Point":
          lat = coordinates[1].toFixed(4);
          lng = coordinates[0].toFixed(4);
          latDirection = lat > 0 ? "N" : "S";
          lngDirection = lng > 0 ? "E" : "W";

          val = `${Math.abs(lat)} ${latDirection}, ${Math.abs(lng)} ${lngDirection}`;
          break;
        case "LineString":
        case "MultiLineString":
          val = Math.round(turf.length(props.geometry, { units: "meters" }));
          unit = "m";
          break;
        case "Polygon":
        case "MultiPolygon":
          val = Math.round(turf.area(props.geometry));
          unit = "m²";
          break;
      }


      const obj = {
        area: val,
        unit: unit,
        label: val !== null ? val + " " + unit : null,
      };

      return obj;
    }
onMounted(() => {
  const mapOptions: any = {
    container: mapContainer.value,
    style: mapStyleMultilayer.value, // TODO: only for testing. If the proposed json gets approved, it should be adjusted in the BA template.
    center: props.mapConfig.mapStyle.mapCenter,
    minZoom: props.mapConfig?.mapStyle.minZoom,
    maxZoom: props.mapConfig?.mapStyle.maxZoom,
    zoom: props.mapConfig?.mapStyle.mapInitialZoomLevel
  };

  // Drawbox overlay setup
  const modes = MapboxDraw.modes;
  modes.static = StaticMode;
  drawOptions = props.mapConfig?.drawOptions || {};
  drawOptions.modes = modes;
  draw = new MapboxDraw(drawOptions);

  map.value = new Map(mapOptions);
  map.value.addControl(new NavigationControl({}), "top-left");
  map.value?.on("load", emitLoaded);
  map.value?.on("draw.create", () => emitStoreGeodata());
  map.value?.on("draw.delete", () => emitDeleteGeodata());
  map.value?.on("draw.update", () => emitStoreGeodata());
  map.value?.addControl(draw, "top-right");
  map.value?.on("draw.selectionchange", (e) => {
    if (e.features.length > 0) {
      handleSelection(e.features[0]);
    } else {
      selectedFeature.value = null;
      if (selectedGeodataInfo.value) {
        selectedGeodataInfo.value.classList.remove("bold-text");
      }
    }
  });
  map.value.addControl(
    new GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      fitBoundsOptions: {
        maxZoom: 19,
      },
    }),
    "top-left"
  );

  if (props.mapConfig?.initialDrawnFeatures) {
    draw.add(props.mapConfig?.initialDrawnFeatures);
  }
  if (props.mapConfig?.mapStyle?.maxBounds) {
    // comment out for development purposes if the dispayed features are outside the maxBounds
    map.value?.setMaxBounds(props.mapConfig?.mapStyle?.maxBounds);
  }

  map.value.once("load", () => {
    collectControlElements();

    props.inputJson && draw.add(props.inputJson);
    if (props.readonly) {
      draw.changeMode("static");
    }
    if (
      props.minMax?.max &&
      draw.getAll().features.length >= props.minMax.max
    ) {

      setControlVisibility("none");
    }
    const drawnFeatures: any = draw.getAll();
    showGeodata(drawnFeatures?.features);
  });

  map.value.on("idle", () => {
    map.value.resize();
  });
});



onUnmounted(() => {
  map.value?.remove();
});
</script>

<style scoped>
@import "https://unpkg.com/maplibre-gl@2.4.0/dist/maplibre-gl.css";
@import "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.3.0/mapbox-gl-draw.css";
</style>
<style scoped lang="scss">
.mapElement {
  height: 500px;
  width: 100%;
  background-color: powderblue;
  border-radius: 10px;
}
.calculation-box p {
  margin: 1.25px;
}
.calculation-box {
  border-radius: 8px;
  z-index: 99;
  min-width: 85px;
  max-width: 200px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px;
  text-align: center;
  display: none;
  max-height: 100px;
  overflow: auto;
}

.visible {
  display: block;
}
.geodata-entry {
  cursor: pointer;
}
</style>

