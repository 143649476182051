<template>
  <ion-item
    lines="none"
    class="flex py-2"
    style="
        background: white;
        flex-wrap: wrap;
        flex-shrink: 1; display: flex; flex-grow: 1
      "
  >
    <ion-grid class="p-0 w-full">
      <ion-row class="px-0">
        <ion-col v-if="label">
          <p class="flex-1">
            {{ label }}
          </p>
        </ion-col>
        <ion-col class="p-0">
          <div class="flex">
            <div class="flex items-center w-full justify-end flex-wrap">
              <!--            <div class="flex-1" />-->
              <ion-thumbnail
                v-for="(photo, index) in modelValue"
                :key="index"
                class="image-preview relative"
                style="flex-shrink: 0; display: inline-block"
                data-cy="image-preview"
              >
                <a-image
                  v-if="!isPdfUploadType"
                  :src="getPhotoUrl(photo, { thumbnail: true })"
                  :image-class="'w-full h-full object-cover rounded-md'"
                  data-cy="image"
                  @click="openGallery(index)"
                />
                <div
                  v-if="isPdfUploadType"
                  class="w-full h-full object-cover rounded-md flex justify-center items-center"
                  style="background: #efefef"
                  @click="openPdfReader(index)"
                >
                  .pdf
                </div>

                <div
                  v-if="!instanceOfPhoto(photo)"
                  class="absolute bottom-1 right-1"
                  style="width: 8px; height: 8px; border-radius: 50%; background: green;"
                />
              </ion-thumbnail>

              <AButton
                v-if="!readonly && !isReadonly && !maxAllowed()"
                :btn-primary="true"
                data-cy="button-add-photo"
                class="flex-shrink-0"
                :class="[
                  !extendedButton && isPdfUploadType ? 'pdf-button' : ''
                ]"
                :style="
                  isPdfUploadType ? 'padding-top: 12px; padding-bottom: 12px ' : ''"
                @click="onTakePhoto()"
              >
                <ion-icon
                  v-if="!extendedButton && !isPdfUploadType"
                  src="/assets/mlIcons/camera-icon.svg"
                ></ion-icon>

                <span
                  v-if="!extendedButton && isPdfUploadType"
                >+</span>


                <ion-icon
                  v-if="extendedButton && !isPdfUploadType"
                  src="/assets/mlIcons/camera-icon.svg"
                ></ion-icon>

                <span
                  v-if="!((modelValue?.length ?? 0) > 0) && extendedButton"
                  class="pl-2 pr-2"
                  data-cy="photo-add-button-text"
                > {{ isPdfUploadType ? t("hzba.buttons.pdfHinzufuegen") : t("hzba.buttons.hinzufuegen") }} </span>
            </AButton>
            </div>
          </div>

          <div
            v-if="minMax"
            class="flex justify-end mt-2 tc-grey-500 text-base mr-2"
          >
            <div v-if="minMax.min === minMax.max">
              genau {{ minMax.min }}
            </div>
            <div v-else>
              <span v-if="minMax.min || minMax.max">(</span>
              <span v-if="minMax.min">min. {{ minMax.min }}</span>
              <span v-if="minMax.min && minMax.max">&nbsp;/&nbsp;</span>
              <span v-if="minMax.max">max. {{ minMax.max }}</span>
              <span v-if="minMax.min || minMax.max">)</span>
            </div>
          </div>
        </ion-col>
      </ion-row>

      <input-error :errors="errors" />
    </ion-grid>
  </ion-item>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {computed, defineComponent, PropType, ref} from "vue";
import {
  IonItem,
  IonButton,
  IonIcon,
  IonCol,
  IonImg,
  IonRow,
  IonGrid,
  IonThumbnail,
  modalController, toastController,
} from "@ionic/vue";
import { camera, close, trash } from "ionicons/icons";
import { usePhotoRecording } from "@/composables/usePhotoRecording";
import StrapiMedia, { instanceOfStrapiImage } from "@/models/photo/strapi-media.model";
import {getPhotoUrl, instanceOfPhoto} from "@/utilities/get-media-url";
import { deleteFile } from "@/api/UploadApi";
import {openBaseModal} from "@/utilities/modal-helper";
import {store} from "@/store/store";
import AImage from "@/components/Base/AImage.vue";
import AButton from "@/components/Base/AButton.vue";
import InputError from "@/components/hzba/Base/Input/InputElements/InputError.vue";
import {getPhotoMinMaxLabel} from "@/utilities/helper";
import { Device } from "@capacitor/device";

export default defineComponent({
  name: "HzbaPhotoInput",
  components: {
    InputError,
    AImage,
    AButton,
    IonItem,
    IonRow,
    IonGrid,
    IonIcon,
    IonCol,
    IonImg,
    IonThumbnail,
    IonButton,
  },
  props: {
    modelValue: {
      type: Object as PropType<(StrapiMedia | Blob)[]>,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: undefined
    },
    minMax: {
      type: Object,
      default: undefined,
    },
    errors: {
      type: Array,
      default: () => []
    },
    isPdfUploadType: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const { openPhotoDialog } = usePhotoRecording();
    const modalPhotoView = ref(false);

    const limit = computed(() => {
      if (!props.minMax?.max) {
        return -1;
      }

      return props.minMax.max - (props.modelValue?.length || 0)
    })
    const maxAllowed = () => {
      const reachedMaxAllowed = props.minMax?.max && props.modelValue && props.modelValue.length >= props.minMax?.max && getPhotoMinMaxLabel(t, props.minMax);
      if (reachedMaxAllowed) {
        return true
      } else {
        return false
      }
    }
    const onTakePhoto = async () => {
      console.log('onTakePhoto triggered');
      const notAllowed = props.minMax?.max && props.modelValue && props.modelValue.length >= props.minMax?.max && getPhotoMinMaxLabel(t, props.minMax);
      // isAllowed = !notAllowed;
      if (notAllowed) {

        const toast = await toastController
            .create({
              message: notAllowed,
              duration: 2000
            })
        return toast.present();
      }
      const photoRes = await openPhotoDialog(t, limit.value, props.isPdfUploadType ? { hidePhoto: true, hideGallery: true } : { hideFile: true });

      // const testRes = photo.webPath && await fetch(photo.webPath);
      // const test = testRes && await testRes.blob();
      // if (photoRes.messageKey === 'portraitOnly') {
      //   const toast = await toastController
      //       .create({
      //         message: t('hzba.portraitOnly'),
      //         duration: 2000
      //       })
      //   await toast.present();
      // }

      console.log('photoRes success?', photoRes?.success)
      console.log('photoRes data?', photoRes?.data);
        if (photoRes.success && photoRes.data) {
        // console.log("pdf media", props.modelValue, ...photoRes.data);

        emit('update:modelValue', [...(props.modelValue ?? []), ...photoRes.data ]);
        emit('change');
      }
    };

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    // const device = Device.getInfo();
    // console.log('+++device***', device);
    const deviceWidth = computed(() => {
      return window.innerWidth
    });
    // console.log('deviceWidth: ', deviceWidth.value);
    const extendedButton = computed(() => {
      return !((props.modelValue?.length ?? 0) > 0) && (deviceWidth.value >= 1024)
    })

    function openGallery(index: number){
      openBaseModal('gallery', {
        images: props.modelValue,
        isReadonly: isReadonly.value,
        title: 'Fotos',
            downloadEnabled: isReadonly.value,
        index,
        onDelete: (photo: StrapiMedia | Blob) => {
          if (instanceOfStrapiImage(photo)) {
            deleteFile(photo.id)
          }
          emit('update:modelValue', props.modelValue?.filter((_, i) => i !== index));
          emit('change');
          modalController.dismiss();
        },
      },
          () => ({}))
    }

    function openPdfReader(index: number) {
      const pdf = props.modelValue && props.modelValue[index];
      openBaseModal('pdf.view', {
        pdf: pdf,
        deleteMode: true,
        onDelete: (pdf: StrapiMedia | Blob) => {
          if (instanceOfStrapiImage(pdf)) {
            deleteFile(pdf.id)
          }
          emit('update:modelValue', props.modelValue?.filter((_, i) => i !== index));
          emit('change');
          modalController.dismiss();
        },
      })
    }

    return {
      camera,
      trash,
      close,
      onTakePhoto,
      getPhotoUrl,
      modalPhotoView,
      instanceOfPhoto,
      openGallery,
      isReadonly,
      t,
      extendedButton,
      openPdfReader,
      maxAllowed
    };
  },
});
</script>

<style scoped lang="scss">
.image-preview {
  width: 50px;
  height: 50px;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 4px;
  --border-radius: 10px;
  object-fit: cover;
}

.item-background-color{
  --ion-item-background: transparent;

  margin-right: -22px;
}

.photo-button {
  height: 50px;
  border-radius: 6px;
  overflow: hidden;
  background: #efefef;
}

.photo-button.compact {
  width: 50px;
}

.horizontal-scroll {
  overflow-x: auto;
}

.camera-button-extended {
  border-radius: 36px;
  background: $primary;
  color: white;
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 0px 24px;
}

.pdf-button {
  padding-top: 12px;
  padding-bottom: 12px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>