<template>
  <ion-page>
    <toolbar title="Logs">
      <template #leading>
        <ion-buttons slot="start">
          <ion-back-button />
        </ion-buttons>
      </template>
    </toolbar>
    <ion-content>
      <div>
        <a-button @click="refreshLogs">
          refresh
        </a-button>
        <div style=" margin-left: 36px; margin-right: 36px">
          <div
            v-for="(log, index) in logs"
            :key="index"
            class="wrapper"
            :class="log.stage"
            @click="expandedItem === index ? expandedItem = -1 : expandedItem = index"
          >
            <h3>{{ log.logs[0] }}</h3>
            <div>{{ moment(log.date).format('HH:mm:ss - DD.MM.YYYY') }}</div>
            <div
              :class="expandedItem === index ? 'expanded' : 'collapsed'"
            >
              <pre>{{ log.logs }}</pre>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import moment from "moment";
import { useI18n } from 'vue-i18n';
import Toolbar from "@/components/Navigation/Toolbar.vue";
import Immobilie from "@/models/immobilie.model";
import {
  IonBackButton,
  IonCard,
  IonButton,
  IonButtons,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonPage,
  IonIcon,
  IonLabel,
  IonContent,
  IonImg,
  onIonViewWillEnter,
  IonSlide,
  IonSlides,
} from "@ionic/vue";
import { addCircleOutline } from "ionicons/icons";
import {computed, defineComponent, onMounted, ref} from "vue";
import { useRouter } from "vue-router";
import HzbaList from "@/components/hzba/HzbaList.vue";
import Skeleton from "@/components/Skeleton.vue";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { useStore } from "@/composables/useTypedStore";
import PdfModal from "@/components/Base/PdfModal.vue";
import InfoField from "@/components/properties/InfoField.vue";
import HorizontalItemScroll from "@/components/hzba/HorizontalItemScroll.vue";
import {openBaseModal} from "@/utilities/modal-helper";
import AImage from "@/components/Base/AImage.vue";
// import DokumentList from "@/components/properties/DokumentList.vue";
import AButton from "@/components/Base/AButton.vue";

export default defineComponent({
  name: "LogsPage",
  components: {
    AButton,
    AImage,
    HorizontalItemScroll,
    InfoField,
    Toolbar,
    IonPage,
    IonButtons,
    IonBackButton,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    IonLabel,
    IonContent,
    IonImg,
    PdfModal,
    HzbaList,
    Skeleton,
    IonSlide,
    IonSlides,
    // DokumentList
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })
    const router = useRouter();
    const store = useStore();
    
    const logs = ref([]);

    const expandedItem = ref();


    // const reversedLogs = computed(() => {
    //   return store.state.app.consoleLogs.slice().reverse();
    // })
    
    const refreshLogs = () => {
      logs.value = store.state.app.consoleLogs.slice().reverse();
    }

    onMounted(() => {
      refreshLogs();
    })


    return {
      addCircleOutline,
      getPhotoUrl,
      openBaseModal,
      t,
      logs,
      moment,
      refreshLogs,
      expandedItem
    };
  },
});
</script>

<style scoped>

ion-img::part(image) {
  border-radius: 10px;
}

.overview-item {
  /*position: sticky;*/
  /*top: 32px;*/
  align-self: flex-start !important;
  /*position: -webkit-sticky; !* for Safari *!*/
}
</style>

<style lang="scss" scoped>

.wrapper {
  padding-left: 16px;

  &.error {
    border-left: 3px solid red;
  }

  &.warn {
    border-left: 3px solid #d9d948;
  }


  .collapsed {
    max-height: 150px;
    overflow: hidden;
  }

  .expanded {
    max-height: 100%;
    overflow-y: auto;
  }
}
</style>