<template>
    <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content" v-for="(grabenquerungenPage, key) in getGrabenquerungenPages(ba)" :key="key">
    <div class="landingpage-background w-screen h-screen">
      <div class="flex flex-col" style="height:1020px">
        <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
        <div class="text-lg md:text-lg">Grabenquerungen</div>
        <div v-if="grabenquerungenPage.length > 0">
            <div class="grid grid-cols-8 justify-items-stretch items-center">
              <div
                class="col-span-2 text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
              >
                Frage
              </div>
              <div
                class="text-sm font-bold col-span-3"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Beschreibung/Eingabewerte
              </div>
              <div
                class="col-span-2 text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Weitere Eingaben
              </div>
              <div
                class="col-span- text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Bewertung
              </div>
            </div>
            <div
              v-for="(grabenquerung, key) in grabenquerungenPage"
              :key="key"
              class="ml-2 grid grid-cols-8 justify-items-stretch items-center"
              style="
                border-style: solid;
                border-color: white white white rgb(216, 215, 26);
                border-radius: 5px;
              "
            >
              <div
                v-if="grabenquerung['grabenquerung-erforderlich']"
                class="text-xs col-span-2"
              >
                <span class="ml-2 text-xs text-gray-500">{{ '\u{2611}' }}</span>
                Erforderlich?
              </div>
              <div v-else class="ml-2 text-xs col-span-2">
                <span class="text-xs text-gray-500">{{ '\u{2610}' }}</span>
                Erforderlich?
              </div>
              <div class="text-xs text-gray-500 col-span-3">
                {{ grabenquerung['graben-beschreibung'] }}
              </div>
              <div class="text-xs md:text-xs">Typ:</div>
              <div class="text-xs text-gray-500">
                {{ grabenquerung['graben-typ'] }}
              </div>
              <PdfChip
                color="green"
                mangel-amount-mode
                :value="grabenquerung['graben-bewertung']"
                pdf-mode
                class="justify-self-end"
              />
              <div class="col-span-2"></div>
              <div class="text-xs">Anzahl</div>
              <div class="text-xs text-gray-500 col-span-5">
                {{ grabenquerung['graben-anzahl'] }}
              </div>
              <oneto-five-image-div
                :ba="null"
                :immobilie="null"
                fragesPath="null"
                :lang="lang"
                :imageObjects="grabenquerung['graben-foto']"
                class="ml-2 col-span-8"
              >
              </oneto-five-image-div>
            </div>
          </div>
          <div v-else>
            <div
              class="mt-2 grid grid-cols-8 justify-items-stretch items-center"
            >
              <div
                class="justify-center items-center col-span-8"
                style="
                  background-color: rgb(235, 239, 240);
                  border-style: solid;
                  border-color: rgb(235, 239, 240) rgb(235, 239, 240)
                    rgb(235, 239, 240) rgb(235, 239, 240);
                  border-radius: 5px;
                "
              >
                <p class="text-xs text-center">
                  Keine Grabenquerungen aufgenommen
                </p>
              </div>
            </div>
          </div>
      </div>
    </div>
    </pdf-a4-page>
</template>

<script lang="ts">

import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { getPhotoUrl } from '@/utilities/get-media-url';
import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';

import PdfChip from '../Components/PdfChip.vue';
import { useBauflaechen } from './useBauflaechen';
import UniversalPdfImageRow from '../Components/UniversalPdfImageRow.vue';
import IsVorhandenCharAndLabel from './isVorhandenCharAndLabel.vue';

import TngHeader from './TngHeader.vue';
import BauflaechenFooter from './BauflaechenFooter.vue';
import OnetoFiveImageDiv from './OnetoFiveImageDiv.vue';

export default defineComponent( {
  name: 'Grabenquerungen',
  components: {
    PdfA4Page,
    PdfChip,
    UniversalPdfImageRow,
    IsVorhandenCharAndLabel,
    TngHeader,
    BauflaechenFooter,
    OnetoFiveImageDiv
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup ( props: any ) {
    const { t } = useI18n( { useScope: 'global' } );
    const uUser = useUser();
    const { getGrabenquerungenPages } = useBauflaechen();
    return {
      user: uUser.user,
      t,
      useIdentifierFrageFinder,
      moment,
      getPhotoUrl,
      getGrabenquerungenPages,
    };
  },
} );
</script>
<style scoped>
* {
  color: rgb(23,68,81);
  font-family: 'Avenir-Next-LT-Pro-Regular';
}

.pdf-content {
  width: 800px;
  margin: 0 auto;
  color: white;
}

.landingpage-background {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 700px;
  height: 1050px;
  color: black;
}

.trenner {
  height: 1px;
  border-width: 0;
  color: black;
  background-color: black;
}

.content {
  height: 800px;
}
</style>