<template>
  <div>
    <section
      class="pdf-item pdf-container"
      :class="[
        noMargin ? 'no-margin' : '',
        `pdf-container-page-${pdfKey}`
      ]"
      style="height: 1100px"
    >
      <slot />
    </section>

    <div class="html2pdf__page-break" />
  </div>
  <hr>
  <!--  <div-->
  <!--    data-html2canvas-ignore="true"-->
  <!--    style="background: rgba(95, 95, 95, 0.8); height: 16px;"-->
  <!--  >-->
  <!--  </div>-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PdfA4Page",
  props: {
    noMargin: {
      type: Boolean,
      default: false,
    },
    pdfKey: {
      type: String,
      required: true,
    }
  },
});
</script>

<style scoped>
.pdf-container {
  padding-left: 50px;
  padding-right: 50px;
  height: 1100px;
  width: 800px;
  padding-top: 32px;
  background: white;
  /* border-bottom: 1px solid grey; */
  position: relative;
}

.no-margin {
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}
</style>