<template>
  <table
    class="flex-1 w-full mt-0 mb-2"
    data-cy="pdf-overview-generalinfo-table"
  >
    <!-- Heizzentrale -->
    <tr
      class="data-tr border-top border-bottom"
      data-cy="generalinfo-table-heizzentrale"
    >
      <td
        style="min-width: 120px; max-width: 200px"
        class="pl-2"
      >
        {{ t("hzba.pdf.heizzentrale", 1, { locale: lang }) }}
      </td>
      <td
        style="min-width: 120px; max-width: 200px"
        class="pl-2"
      >
        <div data-cy="generalInfo-standort">
          {{
            useIdentifierFrageFinder(
              hzba,
              "hzba.heizzentrale-tab.allgemein-group.allgemein-modal.allgemein.standort"
            )?.getTitle() || "Standort"
          }}:

          <span class="font-semibold">
            {{
              useIdentifierFrageFinder(
                hzba,
                "hzba.heizzentrale-tab.allgemein-group.allgemein-modal.allgemein.standort"
              )?.getSelectedAuswahlLabelsString() || "Keine Angabe"
            }}
          </span>
        </div>
      </td>
      <td
        style="min-width: 120px; max-width: 200px"
        class="pl-2"
      >
        <div data-cy="generalInfo-zugangsart">
          {{
            useIdentifierFrageFinder(
              hzba,
              "hzba.heizzentrale-tab.allgemein-group.allgemein-modal.allgemein.zugangsart"
            )?.getTitle()
          }}:

          <span class="font-semibold">
            {{
              useIdentifierFrageFinder(
                hzba,
                "hzba.heizzentrale-tab.allgemein-group.allgemein-modal.allgemein.zugangsart"
              )?.getSelectedAuswahlLabelsString() || "-"
            }}
          </span>
        </div>
      </td>
      <td
        style="min-width: 120px; max-width: 200px"
        class="pl-2"
      >
        <div data-cy="generalInfo-heizraumtuer">
          {{ t("hzba.pdf.heizraumtuer", 1, { locale: lang }) }}:
          <span class="font-semibold">
            {{
              isBeschildert === true
                ? "Beschildert"
                : isBeschildert === false
                  ? "Nicht beschildert"
                  : "Keine Angabe"
            }}
          </span>
        </div>
      </td>
    </tr>

    <!-- Wärmeerzeuger -->
    <tr
      class="data-tr"
      data-cy="generalinfo-table-waermeerzeuger"
    >
      <td
        style="min-width: 120px; max-width: 200px"
        class="pl-2"
        :rowspan="waermeerzeugers?.length + 2"
      >
        {{ t("hzba.pdf.waermeerzeugung", 1, { locale: lang }) }}
      </td>
      <td
        style="min-width: 120px; max-width: 200px"
        class="pl-2 border-bottom"
      >
        <div data-cy="generalInfo-energietraeger">
          {{
            useIdentifierFrageFinder(
              hzba,
              "hzba.uebersicht-tab.heizungsdaten.energietraeger"
            )?.getTitle()
          }}:

          <span class="font-semibold">
            {{
              useIdentifierFrageFinder(
                hzba,
                "hzba.uebersicht-tab.heizungsdaten.energietraeger"
              )?.getSelectedAuswahlLabelsString()
            }}
          </span>
        </div>
      </td>

      <td class="pl-2 border-bottom">
        <div
          v-for="(waermeerzeuger, key) in waermeerzeugers?.slice(0, 5)"
          :key="key"
          data-cy="generalInfo-leistung"
        >
          Wärmeerzeuger {{ key + 1 }}: Leistung:
          <strong>
            {{
              waermeerzeuger
                .findFrageByRelativePath(
                  hzba,
                  "allgemein-modal.allgemein.nennleistung"
                )
                ?.getCurrentInput() || "-"
            }}
            kW
          </strong>
        </div>
        <div
          v-if="waermeerzeugers?.length > 5"
          class="text-xxs h-2.5"
        >
          Weitere Wärmeerzeuger wurden gefunden.
        </div>
      </td>
      <td class="pl-2 border-bottom">
        <div
          v-for="(waermeerzeuger, key) in waermeerzeugers?.slice(0, 5)"
          :key="key"
          data-cy="generalInfo-baujahr"
        >
          Baujahr:
          <strong>{{
            waermeerzeuger
              .findFrageByRelativePath(
                hzba,
                "allgemein-modal.allgemein.baujahr"
              )
              ?.getCurrentInput() || "-"
          }}</strong>
        </div>
        <div
          v-if="waermeerzeugers?.length > 5"
          class="h-2.5"
        />
      </td>

      <!--      <td-->
      <!--        class="pl-2"-->
      <!--      >-->
      <!--        <div-->
      <!--          v-for="(waermeerzeuger, key) in waermeerzeugers?.slice(0,5)"-->
      <!--          :key="key"-->
      <!--          class=""-->
      <!--        >-->
      <!--          <div>-->
      <!--            &lt;!&ndash;            {{ waermeerzeuger.findFrageByRelativePath(hzba, 'allgemein-modal.allgemein.nennleistung')?.getTitle() }}: &ndash;&gt;-->
      <!--            Wärmeerzeuger {{ key+1 }} Leistung:-->
      <!--          </div>-->
      <!--          <div class="title">-->
      <!--            {{ waermeerzeuger.findFrageByRelativePath(hzba, 'allgemein-modal.allgemein.nennleistung')?.getCurrentInput() || '-' }} kW-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div v-if="waermeerzeugers?.length > 5" class="text-xxs">Weitere Wärmeerzeuger wurden gefunden.</div>-->
      <!--      </td>-->
      <!--      <td>-->
      <!--        <div-->
      <!--          v-for="(waermeerzeuger, key) in waermeerzeugers?.slice(0,5)"-->
      <!--          :key="key"-->
      <!--          class=""-->
      <!--        >-->
      <!--          <div>-->
      <!--            Wärmeerzeuger {{ key+1 }} Baujahr:-->
      <!--            &lt;!&ndash;            {{ waermeerzeuger.findFrageByRelativePath(hzba, 'allgemein-modal.allgemein.baujahr')?.getTitle() }}:&ndash;&gt;-->
      <!--          </div>-->
      <!--          <div class="title">-->
      <!--            {{ waermeerzeuger.findFrageByRelativePath(hzba, 'allgemein-modal.allgemein.baujahr')?.getCurrentInput() || '-' }}-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div v-if="waermeerzeugers?.length > 5"><br />&lt;!&ndash; Placeholder to have same padding as above&ndash;&gt; </div>-->
      <!--      </td>-->
    </tr>

    <tr data-cy="generalinfo-table-anlagenkomponenten">
      <td
        colspan="3"
        class="pl-2"
      >
        <div>
          {{
            useIdentifierFrageFinder(
              hzba,
              "hzba.uebersicht-tab.anlagenschema-group.kurzbeschreibunganlagenkomponenten"
            )?.getCurrentInput()
          }}
        </div>
      </td>
    </tr>

    <tr class="border-bottom" />
  </table>
</template>

<script lang="ts">
import { useI18n } from "vue-i18n";
import { computed, PropType } from "vue";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import { defineComponent } from "vue";
import {
  useIdentifierFrageFinder,
  useIdentifierFragenblockFinder,
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import { ExtendedMSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
export default defineComponent({
  name: "PdfHzbaOverviewGeneralInfo",
  components: { PdfChip },

  props: {
    data: {
      type: Array as PropType<ExtendedMSERow[]>,
      required: true,
    },
    hzba: {
      type: Bestandsaufnahme,
      required: true,
    },
    valueTitle: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: "Position",
    },
    lang: {
      type: String,
      default: "de",
    },
    isTableOfContent: {
      type: Boolean,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });
    const positionTranslated = computed(() => {
      return props.name === "Position" ? t("hzba.pdf.position") : props.name;
    });

    const isBeschildert = computed(() => {
      return useIdentifierFrageFinder(
        props.hzba,
        "hzba.heizzentrale-tab.heizraumtuer-group.heizraumtuer-modal[FIRST_INSTANCE].heizraumtuer.beschildert"
      )?.eingabeBoolean;
    });

    const waermeerzeugers = computed(() => {
      const parent = useIdentifierFragenblockFinder(
        props.hzba,
        "hzba.waermeerzeuger-tab"
      );
      return parent?.getMultipleFragenblockInstances("waermeerzeuger");
    });

    return {
      positionTranslated,
      t,
      useIdentifierFragenblockFinder,
      useIdentifierFrageFinder,

      isBeschildert,
      waermeerzeugers,
    };
  },
});
</script>

<style scoped lang="scss">
table {
  font-size: 12px;
  border-collapse: collapse;
}
.border-bottom {
  border-bottom: 0.5px solid #838383;
}
// .border-top {
//   border-top: 0.5px solid #838383;
// }

tr,
td {
  font-weight: 300;
}

th,
td {
  font-size: 12px;
  padding: 8px 0;
}

td {
  min-width: 120px;
  max-width: 200px;
}

.data-tr {
  height: 32px;
}

.numberCol {
  width: 70px;
}

.red {
  color: $pdfRed1;
}

.yellow {
  color: $pdfYellow1;
}

.green {
  color: $pdfGreen1;
}

.value-col {
  padding: 2px;
}

.title {
  color: #090808;
  font-weight: 600;
  display: block;
}

.subtitle {
  //font-weight: 700;
  font-size: inherit;
}
</style>