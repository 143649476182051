<template>
  <MangelItemTemplate
    v-if="mangel.isFired(frage.isShown())"
    v-model:collapsed="mangel.collapsed"
    :mangel="mangel"
    :errors="mangel.errors(t)"
    :is-readonly="isReadonly"
    :mangel-config="mangel.config"
  />
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, defineComponent, onMounted, PropType, ref } from "vue";

import { chevronUpOutline, chevronDownOutline } from "ionicons/icons";
import {store} from "@/store/store";
import MangelItemTemplate from "@/components/hzba/Base/MangelItemTemplate.vue";
import {Mangelzuordnung} from "@/models/ba/Mangelzuordnung";
import {Frage} from "@/models/ba/Frage";

export default defineComponent({
  name: "MangelItem",
  components: {
    MangelItemTemplate,
  },
  props: {
    mangel: {
      type: Object as PropType<Mangelzuordnung>,
      default: undefined,
    },
    frage: {
      type: Object as PropType<Frage>,
      default: undefined,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })

    onMounted(() => {
      if (
        props.mangel &&
        props.mangel.eingabeMedien &&
        props.mangel.eingabeMedien.length > 0
      ) {
        props.mangel.collapsed = true;
      } else if (props.mangel) {
        props.mangel.collapsed = false;
      }

      props.mangel?.setupFireDetector()
    });

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    return {
      isReadonly,
      chevronUpOutline,
      chevronDownOutline,
      t
    };
  },
});
</script>
