<template>
  <div class="focus-controller-container">
    <ion-button
      class="focus-button"
      size="small"
      color="light"
      @click="focusGeoObject"
    >
      <ion-icon
        slot="icon-only"
        :icon="homeOutline"
      />
    </ion-button>
  </div>
</template>
<script setup lang="ts">
import { IonButton, IonIcon } from "@ionic/vue";
import { homeOutline } from "ionicons/icons";
import { Map } from "maplibre-gl";
import { PropType } from "vue";

const props = defineProps({
  map: {
    type: Map,
    required: true,
  },
  center: {
    type: Array as PropType<number[]>, // [lng, lat]
    required: true,
  },
});

function focusGeoObject() {
  props.map.flyTo({
    center: props.center,
    zoom: 17.5,
    minZoom: 15,
    speed: 0.5,
    essential: true,
  });
}
</script>
<style scoped lang="scss">
.focus-controller-container {
  position: absolute;
  top: 179px;
  left: 8px;
  z-index: 3;

}
.focus-button {
  width: 30px;
  color: black;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  --padding-bottom: 3px;
  --padding-top: 3px;
  --padding-start: 3px;
  --padding-end: 3px;
}

ion-icon {
  color: #282622;
}
</style>