<template>
  <ion-page>
    <ion-header
      :is-open="isOpen"
      css-class="modal-fullscreen"
      @didDismiss="setOpen(false)"
    >
      <toolbar
        :title="title"
        :enable-desktop-nav="false"
        data-cy="toolbar"
      >
        <template #leading>
          <ion-buttons
            slot="start"
            data-cy="ion-buttons"
          >
            <ion-button
              v-if="!goBackMode && !isReadonly"
              data-cy="toolbar-button-back"
              @click="askForClose"
            >
              <ion-icon
                :icon="close"
                size="large"
              />
            </ion-button>

            <ion-button
              v-if="goBackMode || isReadonly"
              data-cy="toolbar-button-back-readonly"
              @click="askForClose"
            >
              <ion-icon :icon="arrowBack" /> {{ t("buttons.zurueck") }}
            </ion-button>
          </ion-buttons>

          <div slot="end">
            <ion-buttons
              v-if="!isReadonly && !hideSave"
            >
              <ion-button
                slot="end"
                data-cy="toolbar-button-save"
                color="light"
                @click="$emit('save')"
              >
                {{ t("buttons.speichern") }}
              </ion-button>
            </ion-buttons>

            <slot name="cta" />
          </div>
        </template>
      </toolbar>
    </ion-header>

    <slot />
  </ion-page>
</template>

<script lang="ts">
import Toolbar from "@/components/Navigation/Toolbar";
import {
IonBackButton,
IonButton,
IonButtons,
IonContent,
IonHeader,
IonIcon,
IonModal,
IonPage,
IonTitle,
onIonViewWillEnter
} from '@ionic/vue';
import { computed, defineComponent, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import useAlert from "@/composables/useAlert";
import useCurrentBaState from "@/composables/useCurrentBaState";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { store } from "@/store/store";
import {
arrowBack,
close,
} from 'ionicons/icons';
import { ref } from "vue";

export default defineComponent({
  name: 'BaseModal',
  components: {Toolbar, IonContent, IonHeader, IonBackButton, IonTitle, IonModal, IonIcon, IonButton, IonButtons, IonPage },
  props: {
    title: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    },
    askBeforeClose: {
      type: Boolean,
      default: false
    },
    hideSave: {
      type: Boolean,
      default: false
    },
    goBackMode: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'save', 'open'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const { ba } = useCurrentBaState();
    const isOpen = ref(false);
    const alert = useAlert();
    const { isLockedByCurrentDeviceAndUser } = Bestandsaufnahme;

    function setOpen(val: boolean) {
      isOpen.value = val;

      if (isOpen.value) {
        emit('open')
      }

      if (!isOpen.value) {
        emit('close');
      }
    }

    function askForClose() {
      if (props.askBeforeClose && ba?.value?.isDownloaded) {
        alert.showAdv(
            'Aktion abbrechen?',
            'Sind Sie sicher, dass Sie diese Eingabe abbrechen möchten? Ihre ungespeicherten Daten gehen verloren.',
            [
              {
                text: 'Weiterbearbeiten'
              },
              {
                text: 'Dateneingabe abbrechen',
                handler: () => {
                  setOpen(false);
                }
              },
            ]
        )
      } else {
        setOpen(false);
      }
    }

    onIonViewWillEnter(() => {
      setOpen(props.open)
    })

    watch(() => props.open, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        setOpen(newVal)
      }
    });


    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    return {
      isReadonly,
      isOpen,
      setOpen,
      close,
      arrowBack,
      askForClose,
      t
    }
  }
});
</script>

<style scoped lang="scss">

//.modal-fullscreen {
//  .modal-wrapper {
//    position: absolute;
//    top: 0;
//    left: 0;
//    display: block;
//    width: 100%;
//    height: 100%;
//  }
//}
ion-icon {
  color: $color3;
}

ion-button {
  color: $color3
}

</style>