<template>
  <div
    v-if="!isReadonly"
    class="boxed-container text-center mt-16"
  >
    <ion-button
      fill="clear"
      color="danger"
      class="d-button"
      @click="$emit('delete')"
    >
      {{ fragenblock.getTitle() }} {{ t("hzba.buttons.loeschen") }}
    </ion-button>
  </div>
  <div
    v-if="!isReadonly"
    class="boxed-container text-center"
  >
    <ion-button 
      fill="clear" 
      color="info"
      class="d-button"
      :disabled="fragenblock.isFormDirty()"
      @click="$emit('duplicate')"
    >
      {{ fragenblock.getTitle() }} {{ t("hzba.buttons.duplizieren") }}
    </ion-button>
    <div
      v-if="fragenblock.isFormDirty()"
      style="color: #a9a9a9;"
      class="mb-4"
    >
      {{ t("hzba.duplizierenInfoText") }}
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {computed, PropType} from "vue";
import {store} from "@/store/store";
import {IonButton} from "@ionic/vue";
import {Fragenblock} from "@/models/ba/Fragenblock";

export default {
  name: "HzbaDeleteDuplicateItem",
  components: { IonButton },
  props: {
    fragenblock: {
      type: Object as PropType<Fragenblock>,
      required: true
    }
  },
  emits: ['duplicate', 'delete'],
  setup() {
const { t } = useI18n({ useScope: 'global' })
    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    return {
      isReadonly,
      t
    }
  }
}
</script>

<style scoped>
.d-button {
  width: 100%;
  white-space: break-spaces;
  height: unset;
  min-height: 48px;
  display: flex;
  align-items: center;
}

</style>
