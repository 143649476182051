<template>
  <div class="flex flex-col mt-8 gap-1 px-4">
    <h4 class="m-0 text-lg">
      Gefundene Änderungen<span v-if="activeTab != ''"> im {{ activeTab }}</span> <span v-else>: </span>
    </h4>
    <div class="flex gap-4 no-scrollbar py-3 bg-white flex-wrap sticky top-0 z-10">
      <div
        :class="`flex-shrink-0 bg-gray-200 px-4 py-1 rounded-full cursor-pointer snap-start ${activeTab === '' ? 'bg-gray-500 text-white' : ''}`"
        @click="() => activeTab = ''"
      >
        Alle anzeigen
      </div>
      <div
        v-for="tab in BaTabs"
        :key="tab"
        :class="`flex-shrink-0 bg-gray-200 px-4 py-1 rounded-full cursor-pointer snap-start ${activeTab === tab ? 'bg-gray-500 text-white' : ''}`"
        @click="() => activeTab = tab"
      >
        {{ tab }}
      </div>
    </div>
    <ba-difference
      v-for="(difference, key) in baDifferences"
      :key="key"
      :difference="difference"
      :active-tab="activeTab"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import BaDifference from "./BaDifference.vue"
export default defineComponent({
    components: { BaDifference },
    props: {
        baDifferences: {
            type: Object as PropType<any>,
            required: true
        }
    },
    setup(props) {
        const BaTabs: any = [];

        let activeTab = ref("")

        props.baDifferences.forEach((difference: { absolutePath: string; }) => {
            const tabPath = difference.absolutePath.split(".")[1]
            if (!BaTabs.includes(tabPath)) { BaTabs.push(tabPath) }
        });

        const setTabActive = (tab: any) => {
            activeTab = tab
        }

        return { BaTabs, setTabActive, activeTab }
    }
})
</script>

<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.scroll-snap {
    scroll-snap-type: x mandatory;
}

.snap-start {
    scroll-snap-align: start;
}
</style>
