<template>
  <!-- Header -->
  <ion-header>
    <toolbar
      :title="t('hzba.vorschau.mangellisteVorschauTitel')"
      :enable-desktop-nav="false"
    >
      <template #leading>
        <ion-buttons slot="start">
          <ion-button
            data-cy="photo-preview-close"
            @click="closeModal()"
          >
            <ion-icon
              slot="icon-only"
              :icon="close"
            />
          </ion-button>
        </ion-buttons>
      </template>
      <template #trailing>
        <ion-buttons slot="end">
          <!--          <ion-spinner-->
          <!--            v-if="isProcessing"-->
          <!--            color="light"-->
          <!--          />-->
          <ion-button
            data-cy="button-downloadResource"
            @click="downloadResource"
          >
            <ion-icon
              slot="icon-only"
              :icon="canShare ? share : download"
            />
          </ion-button>
        </ion-buttons>
      </template>
    </toolbar>
  </ion-header>

  <!-- Content -->
  <ion-content>
    <pre>
  {{ maengel }}
    </pre>

    <hzba-group
      v-for="mangel in maengel"
      :key="mangel"
      hide-progress
      :title="`${mangel.titel()} (${mangel.maluspunkte() } MP)`"
      class="mangel-item"
      data-cy="mangelItem"
    >
      <div class="px-4">
        <ion-chip>{{ MangelKategorie[mangel.kategorie()] || '-' }}</ion-chip>
        <ion-chip>{{ mangel.handlungsbedarfTaetigkeit() || '-' }}</ion-chip>
        <ion-chip>{{ mangel.handlungsbedarfZeitpunkt() }}</ion-chip>
        <!-- <ion-chip>{{ MangelZeitpunkt[mangel.handlungsbedarfZeitpunkt()] || '-' }}</ion-chip> -->
        <ion-chip>{{ MangelGewerk[mangel.gewerk()] || '-' }}</ion-chip>
      </div>

      <hzba-photo-input
        v-if="mangel.eingabeMedien"
        v-model="mangel.eingabeMedien"
        readonly
        :label="t('hzba.bilder.titel')"
      />

      <!-- <hzba-base-input
        v-if="mangel.eingabeFreitext"
        v-model="mangel.eingabeFreitext"
        :label="t('hzba.mangel.freitext')"
        hide-unit-space
        readonly
      /> -->
      <ion-row class="ion-wrap d-flex items-center px-0">
        <ion-col class="ion-float-right p-0 pl-0 flex justify-end">
          <ion-input
            class="flex-grow-10"
          >
            {{ t('hzba.mangel.freitext') }}
          </ion-input>
        </ion-col>
      </ion-row>

      <ion-row 
        class="ion-wrap d-flex items-center mb-1 px-0"
        data-cy="input-row-freitext"
      >
        <ion-col class="ion-float-right p-0 pl-0 flex justify-end">
          <ion-input 
            class="inline-block text-right input-text"
            data-cy="base-input"
          >
            {{ mangel.eingabeFreitext }}
          </ion-input>
        </ion-col>
      </ion-row>
      <hzba-choose-input
        v-if="mangel.eingabeRelevant === false || mangel.eingabeRelevant === true"
        v-model="mangel.eingabeRelevant"
        :label="t('hzba.mangel.relevant')"
        readonly
      />
    </hzba-group>
  </ion-content>
</template>


<script lang="ts">

import { close, download, share } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import HzbaBaseModal from "@/components/Base/BaseModal";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput";
import {computed, defineComponent, PropType} from "vue";
import { arrowBack } from "ionicons/icons";
import {IonIcon, IonContent, IonHeader, IonChip, IonButton, IonButtons, IonRow, IonCol, IonText, IonInput} from "@ionic/vue";
import SectionWrapper from "@/components/Base/SectionWrapper.vue";
import Toolbar from "@/components/Navigation/Toolbar.vue";
import PhotoViewModal from "@/components/hzba/Base/HzbaPhotoViewModal.vue";
import HzbaPhotoInput from "@/components/hzba/Base/Input/HzbaPhotoInput.vue";
import HzbaChooseInput from "@/components/hzba/Base/Input/HzbaChooseInput.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import useBaseModal from "@/composables/useModal";
import useDownloadHelper from "@/composables/useDownloadHelper";
import {MangelGewerk, MangelKategorie, MangelZeitpunkt} from "@/models/ba/Mangelvorlage";
import useDownloadMaengel from "@/composables/useDownloadMaengel";

import { Mangelzuordnung } from '@/models/ba/Mangelzuordnung';


export default defineComponent({
  name: "MaengelUebersichtModal",
  components: {
    BaseModal,
    HzbaChooseInput,
    HzbaPhotoInput,
    PhotoViewModal,
    IonChip,
    Toolbar, HzbaBaseInput, HzbaGroup, HzbaBaseModal, SectionWrapper, IonIcon, IonContent, IonHeader, IonButton, IonButtons, IonRow, IonCol, IonText, IonInput },
  props: {
    maengel: {
      type: Object as PropType<Mangelzuordnung[]>,
      required: false,
    },
    fileName: {
      type: String,
      required: true,
    },
  },
  emits: ["close", "save"],
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' })
    const { defaultCancel } = useBaseModal();

    const { canShare, downloadFile } = useDownloadHelper();

    const downloadResource = () => { useDownloadMaengel(props.fileName, props.maengel, downloadFile, t); }

    return {
      arrowBack,
      closeModal: defaultCancel,
      MangelKategorie,
      MangelZeitpunkt,
      MangelGewerk,
      canShare,
      t,
      share,
      close,
      downloadResource,
      download,
    }
  },
});
</script>


<style scoped>
.button-native {
  background-color: unset !important;
}
</style>

<style scoped lang="scss">
.mangel-item {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding-top: 8px;
  padding-bottom: 8px;
}

ion-input {
  padding: 4px !important;
  padding-right: 8px !important;
  padding-left: 16px !important;
  height: auto;
  width: auto;
  text-align: left;
  color: rgb(160, 159, 159);
}
</style>