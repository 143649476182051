<template>
  <div class="layer-switcher-container">
    <ion-button
      class="layer-button"
      size="small"
      color="light"
      @click="isLayerSwitcherOpen = !isLayerSwitcherOpen"
    >
      <ion-icon
        slot="icon-only"
        :icon="layersOutline"
      />
    </ion-button>
    <ion-list
      v-show="isLayerSwitcherOpen"
    >
      <ion-item
        v-for="source in displayedLayerStructure"
        :key="source.id"
      >
        <ion-checkbox
          :checked="source.checked"
          @ionChange="toggleLayer(source)"
        />
        <ion-label>
          {{ source.id }}
        </ion-label>
      </ion-item>
    </ion-list>
  </div>
</template>
<script setup lang="ts">
import { IonButton, IonCheckbox, IonIcon, IonItem, IonLabel, IonList } from "@ionic/vue";
import { layersOutline } from 'ionicons/icons';
import { Map } from "maplibre-gl";
import { ref } from 'vue';
import mapStyleJson from '../../../../public/assets/geodata/style_ario.json';

const props = defineProps({
  map: {
    type: Map,
    required: true
  }
});

const isLayerSwitcherOpen = ref(false);

// TODO: only for testing. If this json structure is approved by business requirements, it should be adjusted in the BA template.
const mapStyleMultilayer = ref(Object.assign(mapStyleJson));
const displayedLayerStructure: any = ref([]);

function initDisplayedLayerStructure() {
  Object.keys(mapStyleMultilayer.value.sources).forEach((key: string) => {
        // Added Exception for offline Map
    if ( mapStyleMultilayer.value.sources[key].type === "geojson" || key === "Offlinekarte") {
      displayedLayerStructure.value.push({ id: key, checked: true, layers: [] });
    }

  });

  displayedLayerStructure.value.forEach((displayedLayerStructureItem: any) => {
    mapStyleMultilayer.value.layers.forEach((layer: any) => {
      if (layer.source === displayedLayerStructureItem.id) {
        displayedLayerStructureItem.layers.push(layer );
      }
    });
  });
}

function toggleLayer(source: any) {
  source.checked = !source.checked;  
  mapStyleMultilayer.value.layers.forEach((layer: any) => {
    if (layer.source === source.id) {
      props.map.setLayoutProperty(layer.id, 'visibility', source.checked ? 'visible' : 'none');
    }
  });
}

initDisplayedLayerStructure()

</script>
<style scoped lang="scss">
.layer-switcher-container {
  position: absolute;
  top: 142px;
  left: 8px;
  z-index: 4;

  ion-list {
    max-height: 300px;
    overflow: scroll;
  }

  ion-label {
    padding-left: 5px;
  }

  ion-checkbox {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  ion-item {
    min-height: 0.5rem;
    max-height: 8rem;
  }
}
 .layer-button {
  width: 30px;
  color: black;
  background-color: $white;
  border-radius: 4px;;
  box-shadow: 0 0 0 2px rgba(0,0,0,.1);
  --padding-bottom: 3px;
  --padding-top: 3px;
  --padding-start: 3px;
  --padding-end: 3px;
}

ion-icon {
  color: #282622;
}
</style>