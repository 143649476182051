<template>
  <ion-page>
    <toolbar
      v-if="routeName !== 'propertiesMap'"
      title="Immobilien"
    >
      <template #trailing>
        <UserDialogButton />
      </template>
    </toolbar>
    <toolbar
      v-if="routeName === 'propertiesMap'"
      class="lg:hidden"
    >
      <template #leading>
        <div class="flex items-center">
          
          <ion-button
            slot="start"
            fill="clear"
            data-cy="button-goBack"
            @click="toggleView('list')"
          >
          <ion-icon 
          :icon="arrowBack"  
          size="large"
          color="light"
          /> 

          </ion-button>
          <Searchbar
            v-if="routeName === 'propertiesList' || !isMinLarge"
            v-model:sort-term="sortTerm"
            v-model:search-term="searchTerm"
            :toolbar-mode="true"
            class="w-full pr-4"
            :sort-terms="supportedSortTerms"
          />
        </div>
      </template>
      <!--      <template #trailing>-->
      <!--        <Searchbar-->
      <!--          v-if="routeName === 'propertiesList' || !isMinLarge"-->
      <!--          v-model:sort-term="sortTerm"-->
      <!--          v-model:search-terms="searchTerm"-->
      <!--          class="container"-->
      <!--          :sort-terms="sortTerms"-->
      <!--        />-->
      <!--      </template>-->
    </toolbar>

    <ion-content>
      <div class="h-full flex flex-col lg:px-0">
        <ion-refresher
          v-if="routeName === 'propertiesList'"
          slot="fixed"
          @ionRefresh="doRefresh($event)"
        >
          <ion-refresher-content />
        </ion-refresher>
        <div
          style="background: white"
          class="md:px-4 2xl:px-0 pt-6"
        >
          <div
            v-if="routeName === 'propertiesList'"
            class="row items-center container px-4 md:px-0"
          >
            <div class="my-2 flex-1">
              <h1 class="text-2xl md:text-3xl lg:mx-auto">
                {{ t("immobilie.uebersichtImmobilies") }}
                <!--                ({{ properties.length }})-->
              </h1>
            </div>

            <!--        :style="'height: ' + objectCountHeight + 'px'"-->
            <!--            <ion-button-->
            <!--              fill="clear"-->
            <!--            >-->
            <AIconButton data-cy="button-listView">
              <ListIcon
                :active="routeName === 'propertiesList'"
                @click="toggleView('list')"
              /> 
            </AIconButton>
            <!--            </ion-button>-->
            <!--            <ion-button-->
            <!--              fill="clear"-->
            <!--            >-->
            <AIconButton data-cy="button-mapView">
              <MapIcon
                :active="routeName === 'propertiesMap'"
                @click="toggleView('map')"
              />
            </AIconButton>
            <!--            </ion-button>-->
          </div>
          <div class="container px-4 md:px-0">
            <Searchbar
              v-if="routeName === 'propertiesList'"
              v-model:search-term="searchTerm"
              class="mb-4"
            />
          </div>
        </div>

        <div
          v-if="routeName === 'propertiesList'"
          class="container px-4 md:px-0 md:px-4 2xl:px-0"
          style="flex: 1 1 auto"
        >
          <div class="py-4 block md:flex flex-row-reverse text-xl items-center">
            <div class="flex-1 md:text-right">
              <sort-by
                v-model:sort-term="sortTerm"
                :sort-terms="supportedSortTerms"
              />
            </div>
            <div>
              Letzte Aktualisierung: {{ lastFetch }}
            </div>
          </div>

          <offline-hint class="mb-4">
            Aktuell besteht keine Internetverbindung. Nur offline gespeicherte Immobilien werden angezeigt. <!-- Todo i18n -->
          </offline-hint>

          <Skeleton v-if="!propertiesLoaded" />
          <property-list
            v-else
            :properties="properties"
          />
          <!--          <p style="margin-top: 32px;">Zuletzt aktualisiert am {{ lastFetch }}</p>-->

          <div
            v-if="showLoadMore"
            class="flex flex-row items-center justify-center"
          >
            <AButton
              type="submit"
              :btn-primary="true"
              class="px-16 py-6 mb-2 text-lg"
              data-cy="button-login"
              style="margin-top: 32px !important; margin-left: 0"
              @click="loadMore()"
            >
              {{ t("buttons.loadMore") }}
            </AButton>
          </div>
        </div>

        <div
          v-else-if="routeName === 'propertiesMap'"
          class="container-fluent"
          style="flex: 1 1 auto"
        >
          <div
            v-if="!propertiesLoaded"
            class="container"
          >
            <Skeleton />
          </div>
          <property-map
            v-else
            :properties="properties"
            @goBack="() => $router.push('/properties')"
          >
            <Searchbar
              v-model:search-term="searchTerm"
              v-model:sort-term="sortTerm"
              :sort-terms="supportedSortTerms"
            />
          </property-map>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useProperties } from "@/composables/Property/useProperties";
import PropertyList from "../components/properties/PropertyList.vue";
import PropertyMap from "../components/properties/PropertyMap.vue";
import {
  IonContent,
  onIonViewWillEnter,
  IonIcon,
  IonPage,
  IonBackButton,
  IonButtons,
  IonButton,
  IonImg,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
} from "@ionic/vue";
import {
  locationOutline,
  listOutline,
  swapVerticalOutline,
  arrowBack
} from "ionicons/icons";
import Skeleton from "../components/Skeleton.vue";
import Toolbar from "../components/Navigation/Toolbar.vue";
import { useStore } from "@/composables/useTypedStore";
import AButton from "@/components/Base/AButton";
import useUser from "@/composables/useUser";
import { popoverHelper } from "@/utilities/popover-helper";
import useFetchData from "@/composables/useFetchData";
import Immobilie from "@/models/immobilie.model";
import moment from "moment";
import { getLocalMetaData } from "@/models/local-meta-data";
import useScreenSize from "@/composables/useScreenSize";
import { useRouter } from "vue-router";
import Searchbar from "@/components/hzba/Base/Searchbar.vue";
import UserDialogButton from "@/components/User/UserDialogButton.vue";
import SortBy from "@/components/Base/SortBy.vue";
import ListIcon from "../../public/assets/icon/ListIcon.vue";
import MapIcon from "../../public/assets/icon/MapIcon.vue";
import AIconButton from "@/components/Base/AIconButton.vue";
import { useI18n } from "vue-i18n";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import OfflineHint from "@/components/Other/OfflineHint.vue";
import {SortTermActive} from "@/composables/Sort/SortTerms";

export default defineComponent({
  name: "PropertiesPage",
  components: {
    OfflineHint,
    PdfChip,
    AIconButton,
    MapIcon,
    ListIcon,
    SortBy,
    Searchbar,
    Toolbar,
    IonContent,
    IonIcon,
    IonButtons,
    PropertyList,
    PropertyMap,
    Skeleton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    AButton,
    IonBackButton,
    IonButton,
    IonImg,
    IonSearchbar,
    UserDialogButton,
  },
  setup() {
    const store = useStore();
    const useData = useFetchData();
    const router = useRouter();
    const uUser = useUser();
    const { t } = useI18n({ useScope: "global" });
    const { isMinLarge } = useScreenSize();
    const routeName = computed(() => { return router.currentRoute.value.name; });

    /**
     * View mode handling: map/list
     */
    const selectedView = ref("list");
    const toggleView = (selected: string) => {
      selectedView.value = selected;
      if (selected === "map") {
        router.push("/properties/map");
      } else {
        router.push("/properties");
      }
    };

    /**
     * Data handling: Fetch data, sort, search
     */
    const { properties, loadImmobilienPreviews, propertiesLoaded, supportedSortTerms } = useProperties();

    const searchTerm = ref<string>(Immobilie.getters("searchTerm"));
    const sortTerm = ref<SortTermActive | undefined>(Immobilie.getters("sortBy"));

    watch([searchTerm, sortTerm], () => {
      Immobilie.dispatch("setSearchTerm", searchTerm.value);
      Immobilie.dispatch("setSortBy", sortTerm.value);
      Immobilie.dispatch("setPage", 0);
      Immobilie.deleteAll();
      loadImmobilienPreviews();
    });

    const search = (e: any) => { searchTerm.value = e.target.value; };

    const showLoadMore = computed(() => {
      return (
        Immobilie.getters("pageCount") &&
        Immobilie.getters("page") < Immobilie.getters("pageCount")!
      );
    });

    const loadMore = () => {
      loadImmobilienPreviews();
    };


    /**
     * Set and Manage bottom navigation bar
     */
    const manageToolbar = (newVal: any) => {
      if (newVal === "propertiesMap") {
        store.commit("app/hideBottomNavigation");
      } else {
        store.commit("app/showBottomNavigation");
      }
    };
    onIonViewWillEnter(() => { manageToolbar(router.currentRoute.value.name); });
    watch(routeName, (newVal: any) => { manageToolbar(newVal); });


    /**
     * Others
     */
    const lastFetch = computed(() => {
      const date = moment(getLocalMetaData("lastBackendFetch"));
      return `${date.format("DD.MM.YYYY")} / ${date.format("HH:mm:ss")} Uhr`;
    });

    return {
      selectedView,
      properties,
      propertiesLoaded,
      toggleView,
      searchTerm,
      objectCountHeight: 62,
      user: uUser.user,
      search,
      supportedSortTerms,
      sortTerm,
      t,
      showLoadMore,
      loadMore,
      locationOutline,
      listOutline,
      routeName,
      doRefresh: useData.doRefresh,
      openPopover: popoverHelper,
      lastFetch,
      isMinLarge,
      swapVerticalOutline,
      arrowBack,
    };
  },
});
</script>

<style scoped>
.btn {
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
</style>
