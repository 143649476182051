<template>
  <div>
    <hzba-separator>Zusätzliche Mängel</hzba-separator>

    <div>
      <MangelItemTemplate
        v-for="(mangel, key) in localMangels"
        :key="key"
        v-model:collapsed="mangel.collapsed"
        :is-custom-mangel="true"
        :errors="mangel.errors(t)"
        :mangel="mangel"
        :mangel-config="config"
      >
        <div class="px-4">
          <AButton
            :btn-secondary="true"
            fill="flat"
            data-cy="btn-delete-custom-mangel"
            @click="() => removeMangelzuordnung(mangel)"
          >
            Löschen
          </AButton>
          <AButton
            :btn-secondary="true"
            fill="flat"
            data-cy="btn-update-custom-mangel"
            @click="() => updateMangelzuordnung(mangel, key)"
          >
            Bestätigen
          </AButton>
        </div>
      </MangelItemTemplate>
    </div>

    <div
      v-if="!addMangelBox && !isReadonly"
      class="px-4"
    >
      <AButton
        :btn-primary="true"
        fill="flat"
        data-cy="btn-add-custom-mangel"
        @click="toggleAddMangelBox"
      >
        Mangelzuordnung hinzufügen
      </AButton>
    </div>

    <MangelItemTemplate
      v-if="addMangelBox"
      :mangel="newMangel"
      :errors="newMangelErrors"
      :is-custom-mangel="true"
      :is-custom-mangel-add-mode="true"
      :mangel-config="config"
    >
      <div class="px-4">
        <AButton
          :btn-secondary="true"
          fill="flat"
          data-cy="btn-submit-custom-mangel"
          @click="addMangelzuordnung"
        >
          Bestätigen
        </AButton>

        <AButton
          :btn-secondary="true"
          fill="clear"
          color="dark"
          data-cy="btn-cancel-custom-mangel"
          @click="toggleAddMangelBox"
        >
          Abbrechen
        </AButton>
      </div>

    <!--    <div>-->
    <!--      <pre>{{ newMangelErrors }}</pre>-->
    <!--      <pre>{{ filteredMaengel }}</pre>-->
    <!--      <div> {{ parentUid }}</div>-->
    <!--    </div>-->
    </MangelItemTemplate>
    <hzba-separator />
  </div>
</template>

<script lang="ts">
import HzbaSeparator from "@/components/hzba/Base/Input/HzbaSeparator";
import {computed, PropType, Ref, ref, watch} from "vue";
import MangelItemTemplate from "@/components/hzba/Base/MangelItemTemplate";
import { store } from "@/store/store";
import { useI18n } from "vue-i18n";
import useToasts from "@/composables/useToasts";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import { Fragenblock } from "@/models/ba/Fragenblock";
import {MangelConfig} from "@/models/ba/interfaces/IMangelzuordnung";
import AButton from "@/components/Base/AButton";

export default {
  name: "AdditionalMaengel",
  components: { MangelItemTemplate, HzbaSeparator, AButton },
  props: {
    mangels: {
      type: Object,
      required: true,
    },
    fragenblock: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
    config: {
      type: Object as PropType<MangelConfig>,
      required: true,
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: "global" });
    const useToast = useToasts();

    const addMangelBox = ref(false);
    const newMangel = ref(
      new Mangelzuordnung({ isTriggered: true }, "", props.fragenblock.mode, true)
    );
    const showErrors = ref(false);


    const localMangels: Ref<any[]> = ref([]);

    watch(props.mangels, async (maengelsUpdated: any, oldval) => {
      localMangels.value = [];

      for (let i = 0; i < maengelsUpdated.length; i++) {
        const newMangel = await maengelsUpdated[i].copyMangelzuordnung(true)
        localMangels.value.push(newMangel);
      }

      console.log("MANGEL UPDATED", maengelsUpdated, localMangels.value);
    }, { immediate: true })

    const newMangelErrors = computed(() => {
      if (!showErrors.value) {
        return [];
      }
      return newMangel.value.errors(t);
    });

    const toggleAddMangelBox = () => {
      addMangelBox.value = !addMangelBox.value;
    };

    const addMangelzuordnung = () => {
      showErrors.value = true;

      if (!newMangel.value.isInputValid()) {
        newMangel.value.setShowErrors();
        return;
      }


      if (newMangelErrors.value.length > 0) {
        console.log(newMangelErrors.value);
        return;
      }

      props.mangels.push(newMangel.value);

      newMangel.value = new Mangelzuordnung(
        { isTriggered: true, config: props.config },
        "",
        props.fragenblock.mode
      );
      addMangelBox.value = false;
      showErrors.value = false;
      store.dispatch("currentHzba/saveHzbaLocally");
    };

    const removeMangelzuordnung = async (mangel: any) => {

      const index = props.mangels.findIndex((m: any) => {
        return m.id === mangel.id;
      });

      props.mangels.splice(index, 1);
      await store.dispatch("currentHzba/saveHzbaLocally");
      useToast.changesSavedLocally();
    };

    const updateMangelzuordnung = async (mangel: any, key: number) => {

      if (!mangel.isInputValid()) {
        mangel.setShowErrors();
        return;
      }

      props.mangels[key] = mangel;
      await store.dispatch("currentHzba/saveHzbaLocally");
      await useToast.changesSavedLocally();
      mangel.collapsed = true;
    };


    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    return {
      addMangelzuordnung,
      toggleAddMangelBox,
      newMangel,
      t,
      localMangels,
      isReadonly,
      addMangelBox,
      removeMangelzuordnung,
      newMangelErrors,
      updateMangelzuordnung,
    };
  },
};
</script>

<style scoped>
</style>