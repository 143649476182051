
<template v-if="immobilie">
  <div
    class="text-xs"
    data-cy="pdf-page-header"
  >
    <div class="flex justify-between py-1">
      <div
        class="font-semibold"
        data-cy="header-titel"
      >
        {{ t("hzba.pdf.acon", 1, { locale: lang }) }}
        {{ t("hzba.pdf.kopfzeile") }}
        {{ t("hzba.pdf.heizzentrale") }}
      </div>
      <div data-cy="header-seite">
        {{ t("hzba.pdf.seite", 1, { locale: lang }) }} {{ page }} /
        {{ maxPages }}
      </div>
    </div>
    <div
      class="top-header mb-1 pb-1"
      data-cy="header-data"
    >
      {{ t("hzba.pdf.objektnummer", 1, { locale: lang }) }}:
      {{ immobilie.externeObjektNr }} /
      {{ t("hzba.pdf.datum", 1, { locale: lang }) }}:
      {{ moment(hzba.begehungsdatum).format("DD.MM.YYYY") }} /
      {{ t("hzba.pdf.auftraggeber", 1, { locale: lang }) }}:
      {{ getVerwalterLabel() || "-" }}
    </div>
    <div
      v-if="showChapters"
      class="chapter mt-2"
      data-cy="header-breadcrumbs"
    >
      <span
        v-for="(entry, key) in techData && techData"
        :key="key"
        :class="active === entry.getTitle(lang) ? 'active' : ''"
      > 
        {{ (key > 0 ? " > " : "") + entry.getTitle(lang) }}
      </span>
      <span :class="isMangelUebersicht === true ? 'active' : ''">
        > {{ t("hzba.pdf.mangeluebersicht", 1, { locale: lang }) }}
      </span>
      <span :class="isHinweisUebersicht === true ? 'active' : ''">
        > {{ t("hzba.pdf.weitereHinweise", 1, { locale: lang }) }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from "vue-i18n";
import moment from "moment";
import { computed, defineComponent, PropType } from "vue";
import Immobilie from "../../../models/immobilie.model";
import { Fragenblock } from "@/models/ba/Fragenblock";
import useNewHzbaJson from "@/composables/useNewHzbaJson";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import useImmobilieHelper from "@/composables/Property/useImmobilieHelper";

export default defineComponent({
  name: "PdfPageHeader",
  props: {
    hzba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    techData: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
    isMangelUebersicht: {
      type: Boolean,
    },
    isHinweisUebersicht: {
      type: Boolean,
    },
    page: {
      type: Number,
      required: true,
    },
    maxPages: {
      type: Number,
      required: true,
    },
    active: {
      type: String,
      default: "",
    },
    showChapters: {
      type: Boolean,
      default: true,
    },
    lang: {
      type: String,
      default: "de",
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });

    const { getVerwalterLabel } = useImmobilieHelper(props.immobilie);

    return {
      moment,
      t,
      getVerwalterLabel,
    };
  },
});
</script>

<style scoped lang="scss">
.top-header {
  // padding-bottom: 4px;
  border-bottom: 1px solid $grey-800;
}

.chapter {
  color: $grey-800;

  .active {
    font-weight: 700;
    color: black;
  }
}
</style>