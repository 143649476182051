<template>
  <ion-page>
    <h1>Coming soon</h1>
  </ion-page>
</template>

<script lang=ts>

import {defineComponent} from "vue";
import IonPage from "@/App.vue";

export default defineComponent({
  name: "Demo",
  components: {IonPage}
})
</script>

<style>
</style>