<template>
  <ion-button
    v-if="!isReadonly"
    fill="clear"
    color="danger"
    class="my-0"
    data-cy="delete-entity-button"
    :size="largeIcons ? 'default' : 'small'"
    @click="$emit('delete')"
  >
    <ion-icon
      :icon="trashOutline"
      :size="largeIcons ? 'large' : 'small'"
    />
  </ion-button>
  <ion-button
    v-if="!isReadonly"
    fill="clear"
    color="dark"
    data-cy="duplicate-entity-button"
    class="my-0"
    :size="largeIcons ? 'default' : 'small'"
    @click="$emit('duplicate')"
  >
    <ion-icon
      :icon="duplicateOutline"
      :size="largeIcons ? 'large' : 'small'"
    />
  </ion-button>

  <button
    v-if="!isReadonly && !hideAddButton"
    class="btn-no-styling my-0"
    data-cy="button-add-items"
    fill="clear"
    color="dark"
    :size="largeIcons ? 'default' : 'small'"
    @click="$emit('add')"
  >
    <ion-icon
      :icon="addOutline"
      :size="largeIcons ? 'large' : 'small'"
    />
  </button>
</template>

<script lang="ts">
import { addCircle } from 'ionicons/icons';
import { duplicateOutline, trashOutline , addOutline } from 'ionicons/icons';
import {
  IonSelect,
  IonIcon,
  IonSelectOption,
  IonButton,
} from "@ionic/vue";
import {popoverHelper} from "@/utilities/popover-helper";
import {computed} from "vue";
import useScreenSize from "@/composables/useScreenSize";
import {store} from "@/store/store";

import {defineComponent} from "vue";

export default defineComponent({
  name: "HzbaGroupCtas",
  components: {
    IonSelectOption,
    IonSelect,
    IonIcon,
    IonButton
  },
  props: {
    hideAddButton: {
      type: Boolean,
      default: false
    },
    onAdd: {
      type: Function,
      required: false
    },
  },
  emits: ['add', 'duplicate', 'delete'],
  setup() {

    const screenSize = useScreenSize();

    const largeIcons = computed(() => {
      return screenSize.screenWidth.value >= screenSize.screenTypes.SCREEN_LG_START
    })

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );


    return {
      addCircle,
      isReadonly,
      openPopover: popoverHelper,
      duplicateOutline,
      trashOutline,
      addOutline,
      screenSize,
      largeIcons
    }
  }
})
</script>

<style>

.button-native {
  padding-left: 0 !important;
  padding-inline-start: 0 !important;
}

</style>