<template>
  <div class="flex text-sm font-medium border">
    <table class="flex-1">
      <tr>
        <td>{{ t("hzba.pdf.objektnummer", 1, { locale: lang }) }}:</td>
        <td data-cy="generalData-objektNr">
          {{ immobilie.externeObjektNr }}
        </td>
      </tr>
      <tr>
        <td>{{ t("hzba.pdf.objekt", 1, { locale: lang }) }}:</td>
        <td data-cy="generalData-adresse">
          {{ immobilie.strasse }}, {{ immobilie.plz }} {{ immobilie.stadt }}
        </td>
      </tr>
      <tr>
        <td>{{ t("hzba.pdf.datum", 1, { locale: lang }) }}:</td>
        <td data-cy="generalData-datum">
          {{ moment(hzba.begehungsdatum).format("DD.MM.YYYY") }}
        </td>
      </tr>

      <!-- <tr>
        <td>{{ t("hzba.pdf.objekbegeher", 1, { locale: lang}) }}:</td>
        <td />
      </tr>

 -->
      <tr>
        <td>{{ t("hzba.pdf.auftraggeber", 1, { locale: lang }) }}:</td>
        <td data-cy="generalData-auftraggeber">
          {{ getVerwalterLabel() }}
        </td>
      </tr>
      <tr>
        <td>{{ t("hzba.pdf.eigentuemer", 1, { locale: lang }) }}:</td>
        <td data-cy="generalData-eigentuemer">
          {{ immobilie?.eigentuemer }}
        </td>
      </tr>

      <tr>
        <td>{{ t("hzba.pdf.energietraeger", 1, { locale: lang }) }}:</td>
        <td data-cy="generalData-energietraeger">
          {{
            // To do -> noch wird wert angezeigt, nicht label
            useIdentifierFrageFinder(
              hzba,
              "hzba.uebersicht-tab.heizungsdaten.energietraeger"
            )?.getSelectedAuswahlLabelsString()
          }}
        </td>
      </tr>
    </table>
    <div class="ml-4">
      <img
        :src="getPhotoUrl(immobilie?.vorschaubild)"
        style="max-height: 130px; max-width: 180px"
        height="130"
        width="180"
        data-cy="generalData-vorschaubild"
      >
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from "vue-i18n";
import { defineComponent, PropType } from "vue";
import { getPhotoUrl } from "../../../utilities/get-media-url";
import moment from "moment";

import {
  useIdentifierFrageFinder,
  useIdentifierFragenblockFinder,
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import Immobilie, { immobilie } from "@/models/immobilie.model";
import useImmobilieHelper from "@/composables/Property/useImmobilieHelper";

export default defineComponent({
  name: "PdfGeneralData",
  props: {
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    hzba: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      default: "de",
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });

    const { getVerwalterLabel } = useImmobilieHelper(props.immobilie);

    return {
      getPhotoUrl,
      moment,
      t,
      useIdentifierFragenblockFinder,
      useIdentifierFrageFinder,
      getVerwalterLabel,
    };
  },
});
</script>

<style scoped>
.border {
  border-top: 1px solid #a9a9a9;
  border-bottom: 1px solid #a9a9a9;
  padding: 15px 0;
  font-weight: 550;
}
</style>