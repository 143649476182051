<template>
  <ion-buttons
    slot="primary"
  >
    <!-- <ion-button data-cy="userDialogButton-settings" @click="goToSettings">
      <ion-icon src="/assets/icon/SettingIcon.svg"/>
    </ion-button> -->
    <AIconButton 
      data-cy="userDialogButton-settings" 
      @click="goToSettings"
    >
      <SettingsIcon
        :is-active="routeName === 'settings'"
      />
    </AIconButton>
    <ion-button
      data-cy="userDialogButton-popover"
      @click="(e) => openPopover(e, 'user.profileImage')"
    >
      <IonImg
        :src="
          user?.profilbild
            ? getPhotoUrl(user.profilbild)
            : './assets/img/profiles/placeholder_profile_image.png'
        "
        class="rounded-full overflow-hidden"
        style="width: 32px; height: 32px; background: white"
      />
    </ion-button>
  </ion-buttons>
</template>

<script lang="ts">
import useUser from "@/composables/useUser";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { popoverHelper } from "@/utilities/popover-helper";
import SettingsIcon from "../../../public/assets/mlIcons/SettingsIcon.vue";
import { defineComponent, ref, computed } from "vue";
import { IonButton, IonButtons, IonContent, IonImg, IonPage } from "@ionic/vue";
import {useRouter} from "vue-router";
import AIconButton from "@/components/Base/AIconButton.vue";

export default defineComponent({
  name: "UserDialogButton",
  components: {
    IonPage,
    IonButton,
    IonButtons,
    IonContent,
    IonImg,
    SettingsIcon,
    AIconButton
  },
  setup() {
    const { user } = useUser();
    const router = useRouter();
    const isActive = ref(false);
    const routeName = computed(() => { return router.currentRoute.value.name; });

    function goToSettings() {
      router.push('/settings')
    }

    return {
      goToSettings,
      user,
      openPopover: popoverHelper,
      getPhotoUrl,
      isActive,
      routeName
    };
  },
});
</script>
<style scoped lang="scss">
</style>