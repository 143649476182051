<template>
  <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content">
    <div class="landingpage-background w-screen h-screen">
      <!-- Header Startz-->
      <div class="flex flex-col justify-between" style="height: 1020px">
        <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
        <div class="text-xl md:text-xl font-bold">Arbeitsfläche Mast</div>
        <div class="content h-full max-h-full">
          <!-- Allgemeine Punkte Start-->
          <div class="grid grid-cols-8 justify-items-stretch items-center gap-y-3">
            <div class="text-lg md:text-lg col-span-8">Allgemeine Punkte</div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              Frage
            </div>
            <div
              class="text-sm font-bold col-span-3"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Beschreibung/Eingabewerte
            </div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Weitere Eingaben
            </div>
            <div
              class="col-span- text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Bewertung
            </div>
            <!-- Nutzungsart start-->
            <div class="text-xs col-span-2">Nutzungsarten der Fläche:</div>
            <div class="text-xs text-gray-500 col-span-5">
              <ul
                v-for="(v, key) in useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.arbeitsflaeche-mast-tab.allgemeine-punkte-group.nutzungsart-der-flaeche'
                )['eingabeMehrfachAuswahlWerts']"
                :key="key"
                class=""
              >
                <li :key="key" class="text-xs text-gray-500">{{ v }}</li>
              </ul>
            </div>
            <bewertung-chip
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.allgemeine-punkte-group.nutzungsart-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>

            <!-- Nutzungsart Ende-->
            <!-- Fremdobjekte start-->

            <div class="text-xs col-span-2">Fremdobjekte auf Fläche:</div>
            <div class="text-xs text-gray-500 col-span-5">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.arbeitsflaeche-mast-tab.allgemeine-punkte-group.weitere-fremdobjekte-auf-flaeche'
                )['eingabeText']
              }}
            </div>
            <bewertung-chip
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.allgemeine-punkte-group.fremdobjekte-auf-flaeche-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>

            <!-- Fremdobjekte Ende-->
            <!-- Sonstiges start-->

            <is-vorhanden-char-and-label
              :ba="ba"
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.allgemeine-punkte-group.sonstiges-vorhanden"
              label="Sonstiges"
              class="col-span-2"
            />
            <div class="text-xs col-span-4 text-gray-500">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.arbeitsflaeche-mast-tab.allgemeine-punkte-group.sonstiges-beschreibung'
                )['eingabeText']
              }}
            </div>
            <bewertung-chip
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.allgemeine-punkte-group.sonstiges-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end col-span-2"
            ></bewertung-chip>

            <!-- Fremdobjekte Ende-->
            <oneto-five-image-div
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.allgemeine-punkte-group.allgemein-bilder"
              :lang="lang"
              class="col-span-8"
            >
            </oneto-five-image-div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              Analyse
            </div>
            <div
              class="text-sm font-bold col-span-6"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Analyseergebnisse
            </div>
            <div class="text-xs col-span-2">Fremdobjekte auf Fläche:</div>
            <div class="text-xs text-gray-500 col-span-6">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.arbeitsflaeche-mast-tab.allgemeine-punkte-group.fremdobjekte-auf-flaeche'
                )['eingabeText']
              }}
            </div>
          </div>

          <!-- Allgemeine Punkte Ende-->
          <!-- Geländebeschaffenheit Start-->
          <div class="mt-6 text-lg md:text-lg">Geländebeschaffenheit</div>

          <div
            class="mt-0 grid grid-cols-8 justify-items-stretch items-center gap-y-3"
          >
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              Frage
            </div>
            <div
              class="text-sm font-bold col-span-3"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Beschreibung/Eingabewerte
            </div>
            <div
              class="col-span-2 text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Weitere Eingaben
            </div>
            <div
              class="col-span- text-sm font-bold"
              style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
            >
              Bewertung
            </div>
            <!-- Gefälle start-->
            <is-vorhanden-char-and-label
              :ba="ba"
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.gefaelle-group.gefaelle-vorhanden"
              label="Gefälle"
              class="col-span-2"
            />
            <div class="text-xs text-gray-500 col-span-5">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.arbeitsflaeche-mast-tab.gefaelle-group.gefaelle-beschreibung'
                )['eingabeText']
              }}
            </div>
            <bewertung-chip
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.gefaelle-group.gefaelle-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>
            <oneto-five-image-div
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.gefaelle-group.gefaelle-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div>

            <!-- Gefälle Ende-->

            <!-- Geländebeschaffenheit Ende-->
            <!-- Hang/Boeaschung Start-->
            <is-vorhanden-char-and-label
              :ba="ba"
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.hangboeschung-group.hang-boeschung-vorhanden"
              label="Hang/B&ouml;schung"
              class="col-span-2"
            />

            <!-- Gefälle start-->
            <div class="text-xs text-gray-500 col-span-3">
              {{
                useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.arbeitsflaeche-mast-tab.hangboeschung-group.hang-boeschung-beschreibung'
                )['eingabeText']
              }}
            </div>
            <div class="text-xs">Art:</div>
            <div class="text-xs text-gray-500">
              <ul
                v-for="(v, key) in useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.arbeitsflaeche-mast-tab.hangboeschung-group.hang-boeschung-art'
                )['eingabeMehrfachAuswahlWerts']"
                :key="key"
              >
                <li :key="key" class="text-xs text-gray-500">{{ v }}</li>
              </ul>
            </div>
            <bewertung-chip
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.hangboeschung-group.hang-boeschung-bewertung"
              :ba="ba"
              :immobilie="immobilie"
              :lang="lang"
              class="justify-self-end"
            ></bewertung-chip>

            <oneto-five-image-div
              :ba="ba"
              :immobilie="immobilie"
              fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.hangboeschung-group.hangboeschung-bilder"
              :lang="lang"
              class="col-span-8 ml-2"
            >
            </oneto-five-image-div>

            <!-- Gefälle Ende-->
          </div>
          <!-- Hang/Boeaschung Ende-->
        </div>
        <!-- last div-->
        <bauflaechen-footer
          :ba="ba"
          :immobilie="immobilie"
          :lang="lang"
        ></bauflaechen-footer>
      </div>
    </div>
  </pdf-a4-page>
  <pdf-a-4-page no-margin :pdf-key="lang" class="pdf-content">
    <div class="landingpage-background w-screen h-screen">
      <!-- Ausholzung Start-->
      <div class="flex flex-col" style="height:1000px">
        <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
        <div class="content">
          <div class="h-full max-h-full">
            <div class="mt-4 text-lg md:text-lg">
              Geländebeschaffenheit - Fortsetzung
            </div>
            <div
              class="grid grid-cols-8 justify-items-stretch items-center gap-y-3"
            >
              <div
                class="col-span-2 text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
              >
                Frage
              </div>
              <div
                class="text-sm font-bold col-span-3"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Beschreibung/Eingabewerte
              </div>
              <div
                class="col-span-2 text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Weitere Eingaben
              </div>
              <div
                class="col-span- text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Bewertung
              </div>
              <is-vorhanden-char-and-label
                :ba="ba"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.hangboeschung-group.hang-boeschung-vorhanden"
                label="Ausholzung erforderlich"
                class="col-span-2"
              />
              <!-- Gefälle start-->
              <div class="text-xs text-gray-500 col-span-3">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.ausholzung-group.ausholzung-beschreibung'
                  )['eingabeText']
                }}
              </div>
              <div class="text-xs">Art:</div>
              <div class="text-xs text-gray-500">
                <ul
                  v-for="(v, key) in useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.ausholzung-group.ausholzung-art'
                  )['eingabeMehrfachAuswahlWerts']"
                  :key="key"
                >
                  <li :key="key" class="text-xs text-gray-500">{{ v }}</li>
                </ul>
              </div>
              <bewertung-chip
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.ausholzung-group.ausholzung-bewertung"
                :ba="ba"
                :immobilie="immobilie"
                :lang="lang"
                class="justify-self-end"
              ></bewertung-chip>
              <oneto-five-image-div
                :ba="ba"
                :immobilie="immobilie"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.ausholzung-group.ausholzung-bilder"
                :lang="lang"
                class="col-span-8"
              >
              </oneto-five-image-div>
              <!--  Ende-->

              <!-- Ausholzung Ende-->
              <!-- Rodung Start-->
              <is-vorhanden-char-and-label
                :ba="ba"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.hangboeschung-group.hang-boeschung-vorhanden"
                label="Rodung erforderlich"
                class="col-span-2"
              />
              <div class="text-xs text-gray-500 col-span-3">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.rodung-group.rodung-beschreibung'
                  )['eingabeText']
                }}
              </div>
              <div class="text-xs">Art:</div>
              <div class="text-xs text-gray-500">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.rodung-group.rodung-anzahl-baeume'
                  )['eingabeText']
                }}
              </div>

              <bewertung-chip
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.rodung-group.rodung-bewertung"
                :ba="ba"
                :immobilie="immobilie"
                :lang="lang"
                class="justify-self-end"
              ></bewertung-chip>

              <oneto-five-image-div
                :ba="ba"
                :immobilie="immobilie"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.rodung-group.rodung-bilder"
                :lang="lang"
                class="col-span-8"
              >
              </oneto-five-image-div>
              <!--  Ende-->

              <!-- Rodung Ende-->
              <!-- Graben Start-->
              <is-vorhanden-char-and-label
                :ba="ba"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.hangboeschung-group.hang-boeschung-vorhanden"
                label="Graben vorhanden"
                class="col-span-2"
              />
              <div class="text-xs text-gray-500 col-span-3">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.graben-group.graben-beschreibung'
                  )['eingabeText']
                }}
              </div>
              <div class="text-xs">Art:</div>
              <div class="text-xs text-gray-500">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.graben-group.graben-anzahl'
                  )['eingabeText']
                }}
              </div>
              <bewertung-chip
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.graben-group.graben-bewertung"
                :ba="ba"
                :immobilie="immobilie"
                :lang="lang"
                class="justify-self-end"
              ></bewertung-chip>

              <oneto-five-image-div
                :ba="ba"
                :immobilie="immobilie"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.rodung-group.rodung-bilder"
                :lang="lang"
                class="col-span-8"
              >
              </oneto-five-image-div>

              <is-vorhanden-char-and-label
                :ba="ba"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.feuchtgebiete-group.feuchtgebiete-vorhanden"
                label="Feuchtgebiete vorhanden"
                class="col-span-2 "
              />
              <div class="text-xs text-gray-500 col-span-3">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.feuchtgebiete-group.feuchtgebiete-beschreibung'
                  )['eingabeText']
                }}
              </div>
              <bewertung-chip
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.feuchtgebiete-group.feuchtgebiete-bewertung"
                :ba="ba"
                :immobilie="immobilie"
                :lang="lang"
                class="col-span-3 justify-self-end"
              ></bewertung-chip>
              <oneto-five-image-div
                :ba="ba"
                :immobilie="immobilie"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.feuchtgebiete-group.feuchtgebiete-bilder"
                :lang="lang"
                class="col-span-8"
              >
              </oneto-five-image-div>

              <is-vorhanden-char-and-label
                :ba="ba"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.naturdenkmaeler-group.naturdenkmaeler-vorhanden"
                label="Naturdenkmäler vorhanden"
                class="col-span-2 "
              />
              <div class="text-xs text-gray-500 col-span-3">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.naturdenkmaeler-group.naturdenkmaeler-beschreibung'
                  )['eingabeText']
                }}
              </div>
              <bewertung-chip
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.naturdenkmaeler-group.naturdenkmaeler-bewertung"
                :ba="ba"
                :immobilie="immobilie"
                :lang="lang"
                class="col-span-3 justify-self-end"
              ></bewertung-chip>
              <oneto-five-image-div
                :ba="ba"
                :immobilie="immobilie"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.naturdenkmaeler-group.naturdenkmaeler-bilder"
                :lang="lang"
                class="col-span-8"
              >
              </oneto-five-image-div>

              <is-vorhanden-char-and-label
                :ba="ba"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.kampfmittel-group.kampfmittel-vorhanden"
                label="Kampfmittel vorhanden"
                class="col-span-2 "
              />
              <div class="text-xs text-gray-500 col-span-2">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.kampfmittel-group.kampfmittel-beschreibung'
                  )['eingabeText']
                }}
              </div>
                         <is-vorhanden-char-and-label
                :ba="ba"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.kampfmittel-group.kampfmittel-freimessung"
                label="freimessen"
                class="col-span-2 "
              />
              <div class="text-xs">Art:</div>
              <div class="text-xs"> {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.kampfmittel-group.kampfmittel-art'
                  )['eingabeText']
                }}</div>
              <bewertung-chip
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.kampfmittel-group.kampfmittel-bewertung"
                :ba="ba"
                :immobilie="immobilie"
                :lang="lang"
                class=" justify-self-end"
              ></bewertung-chip>
              <oneto-five-image-div
                :ba="ba"
                :immobilie="immobilie"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.kampfmittel-group.kampfmittel-bilder"
                :lang="lang"
                class="col-span-8"
              >
              </oneto-five-image-div>

              <is-vorhanden-char-and-label
                :ba="ba"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.sonstiges-group.sonstiges-vorhanden"
                label="Sonstiges vorhanden"
                class="col-span-2"
              />
              <div class="text-xs text-gray-500 col-span-5">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.sonstiges-group.sonstiges-beschreibung'
                  )['eingabeText']
                }}
              </div>
              <bewertung-chip
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.sonstiges-group.sonstiges-bewertung"
                :ba="ba"
                :immobilie="immobilie"
                :lang="lang"
                class="justify-self-end"
              ></bewertung-chip>
              <oneto-five-image-div
                :ba="ba"
                :immobilie="immobilie"
                fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.sonstiges-group.sonstiges-bilder"
                :lang="lang"
                class="col-span-8"
              >
              </oneto-five-image-div>

              <!--  Ende-->
            </div>
            <!-- Graben Ende-->
          </div>
        </div>
      </div>
      <bauflaechen-footer
        :ba="ba"
        :immobilie="immobilie"
        :lang="lang"
      ></bauflaechen-footer>
    </div>
  </pdf-a-4-page>

  <pdf-a-4-page no-margin :pdf-key="lang" class="pdf-content">
    <div class="landingpage-background w-screen h-screen">
    <div class="flex flex-col" style="height:1120px">
        <tng-header :ba="ba" :immobilie="immobilie" :lang="lang"></tng-header>
        <div class="content">
          <div class="h-full max-h-full">
            <div class="mt-4 text-lg md:text-lg">
              Ökologie
            </div>
            <div
              class="grid grid-cols-8 justify-items-stretch items-center gap-y-3"
            >
              <div
                class="col-span-2 text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
              >
                Frage
              </div>
              <div
                class="text-sm font-bold col-span-5"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Beschreibung/Eingabewerte
              </div>
              <div
                class="col-span- text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Bewertung
              </div>

<div class="text-xs col-span-2 ">Ampelkarte</div>
              <div class="col-span-5 text-xs">
                {{  useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.ampelkarte'
                )['eingabeAuswahlWert'] }}
              </div>

                <bewertung-chip
                  fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.ampelkarte-bewertung"
                  :ba="ba"
                  :immobilie="immobilie"
                  :lang="lang"
                  class="justify-self-end "
                ></bewertung-chip>

              <div class="text-xs col-span-2">FFH-Gebiet</div>
              <div class="col-span-5 text-xs">{{ useIdentifierFrageFinder(
                  ba,
                  'rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.ffh-gebiet'
                )['eingabeAuswahlWert'] }}
              </div>

                <bewertung-chip
                  fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.ffh-gebiet-bewertung"
                  :ba="ba"
                  :immobilie="immobilie"
                  :lang="lang"
                  class="justify-self-end"
                ></bewertung-chip>

              <div class="text-xs col-span-2">Biotop</div>
              <div class="text-xs col-span-5">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.biotop'
                  )['eingabeAuswahlWert']
                }}
              </div>

                <bewertung-chip
                  fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.biotop-bewertung"
                  :ba="ba"
                  :immobilie="immobilie"
                  :lang="lang"
                  class="justify-self-end"
                ></bewertung-chip>

              <div class="text-xs col-span-2">Vogelschutzgebiet</div>
              <div class="text-xs col-span-5">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.vogelschutzgebiet'
                  )['eingabeAuswahlWert']
                }}
              </div>

                <bewertung-chip
                  fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.vogelschutzgebiet-bewertung"
                  :ba="ba"
                  :immobilie="immobilie"
                  :lang="lang"
                  class="justify-self-end"
                ></bewertung-chip>

              <div class="text-xs col-span-2">Bodenschutzgebiet</div>
              <div class="text-xs col-span-5">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.bodenschutzgebiet'
                  )['eingabeAuswahlWert']
                }}
              </div>

                <bewertung-chip
                  fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.bodenschutzgebiet-bewertung"
                  :ba="ba"
                  :immobilie="immobilie"
                  :lang="lang"
                  class="justify-self-end"
                ></bewertung-chip>

              <div class="text-xs col-span-2">Wasserschutzgebiet</div>
              <div class="text-xs col-span-5">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.wasserschutzgebiet'
                  )['eingabeAuswahlWert']
                }}
              </div>

                <bewertung-chip
                  fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.wasserschutzgebiet-bewertung"
                  :ba="ba"
                  :immobilie="immobilie"
                  :lang="lang"
                  class="justify-self-end"
                ></bewertung-chip>

              <div class="text-xs col-span-2">Bewuchs</div>
              <div class="text-xs col-span-5">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.bewuchs'
                  )['eingabeAuswahlWert']
                }}
              </div>

                <bewertung-chip
                  fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.bewuchs-bewertung"
                  :ba="ba"
                  :immobilie="immobilie"
                  :lang="lang"
                  class="justify-self-end"
                ></bewertung-chip>

              <div class="text-xs col-span-2">Sonstiges</div>
              <div class="text-xs col-span-5">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.sonstiges'
                  )['eingabeAuswahlWert']
                }}
              </div>
                <bewertung-chip
                  fragesPath="rippistein-bauflaechen.arbeitsflaeche-mast-tab.oekologie-group.sonstiges-bewertung"
                  :ba="ba"
                  :immobilie="immobilie"
                  :lang="lang"
                  class="justify-self-end"
                ></bewertung-chip>

            </div>
          </div>
        </div>
        <bauflaechen-footer
          :ba="ba"
          :immobilie="immobilie"
          :lang="lang"
        ></bauflaechen-footer>
      </div>
    </div>
  </pdf-a-4-page>
</template>

<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { getPhotoUrl } from '@/utilities/get-media-url';
import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';
import UniversalPdfImageRow from '../Components/UniversalPdfImageRow.vue';
import PdfChip from '../Components/PdfChip.vue';
import TngHeader from './TngHeader.vue';
import BauflaechenFooter from './BauflaechenFooter.vue';
import OnetoFiveImageDiv from './OnetoFiveImageDiv.vue';
import BewertungChip from './BewertungChip.vue';

import isVorhandenCharAndLabel from './isVorhandenCharAndLabel.vue';

export default defineComponent({
  name: 'ArbeitsFlaecheMastPage',
  components: {
    PdfA4Page,
    PdfChip,
    UniversalPdfImageRow,
    TngHeader,
    BauflaechenFooter,
    OnetoFiveImageDiv,
    BewertungChip,
    isVorhandenCharAndLabel,
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();

    return {
      user: uUser.user,
      t,
      useIdentifierFrageFinder,
      moment,
      getPhotoUrl,
    };
  },
});
</script>
<style scoped>
* {
  color: rgb(23, 68, 81);
  font-family: 'Avenir-Next-LT-Pro-Regular';
}
.pdf-content {
  width: 800px;
  margin: 0 auto;
  color: white;
}

.landingpage-background {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 700px;
  height: 1050px;
  color: black;
}

.trenner {
  height: 1px;
  border-width: 0;
  color: gray;
  background-color: gray;
}

.content {
  height: 870px;
}
</style>