<template>
  <div
    class="grid grid-cols-1 lg:p-0 sm:grid-cols-1 md:grid-cols-2  gap-5"
    :class="[
      propertyView ? 'lg:grid-cols-2' : 'lg:grid-cols-3',
      propertyView ? 'xl:grid-cols-3' : 'xl:grid-cols-4',
    ]
    "
  >
    <hzba-card
      v-for="ba in hzbas"
      :key="ba.id"
      :ba="ba"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonButton,
  IonLabel,
  IonBadge,
  IonChip,
} from "@ionic/vue";
import HzbaCard from "./HzbaCard.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";

export default defineComponent({
  name: "HzbaList",
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonButton,
    IonLabel,
    IonBadge,
    IonChip,
    HzbaCard
  },
  props: {
    hzbas: {
      type: Object as PropType<Bestandsaufnahme[]>,
      required: true,
    },
    propertyView: {
      type: Boolean,
      default: false
    }
  },
});
</script>

<style scoped>

.forceOneCol {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

</style>


