<template>
  <pdf-row-base>
    <td style="width: 15%;">
      <img
        v-if="imgSrc"
        :src="imgSrc"
        class="mt-0"
        style="height: 120px; width:90px "
      >
      <!--        style="height: 100% !important; object-fit: scale-down; overflow: hidden"
-->
    </td>
    <td
      class="text-left font-semibold"
      style="width: 65%; word-break: break-word"
    >
      {{ customInputData.titel }}
      <br>
      <p class="font-normal whitespace-normal">
        {{ customInputData.freitext }}
      </p>
    </td>
    <td class="text-left font-semibold">
      {{ customInputData.gewerk }}
    </td>
  </pdf-row-base>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from "vue";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import { getPhotoUrl } from "@/utilities/get-media-url";
import AImage from "@/components/Base/AImage.vue";
import PdfRowBase from "@/components/Pdf/Components/PdfRowBase.vue";
import {CustomInputData} from "@/components/Pdf/Bericht_Heizung/useHzbaPdfReport";
export default defineComponent({
  name: "PdfRowCustomInput",
  components: {
    PdfRowBase,
    AImage,
    PdfChip,
  },
  props: {
    customInputData: {
      type: Object as PropType<CustomInputData>,
      required: true,
    },
  },
  setup(props) {

    const imgSrc = computed(() => {
      return props.customInputData.bilder &&
          props.customInputData.bilder.length > 0 &&
          getPhotoUrl(props.customInputData.bilder[0])
    })
    return {
      getPhotoUrl,
      imgSrc,
    };
  },
});
</script>
