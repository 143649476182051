<template>
  <BaseModal
    :title="t('hzba.vorschau.fazit')"
    :open="open"
    :ask-before-close="true"
    @close="close()"
    @save="submit()"
  >
    <ion-content>
      <div class="m-6">
        <div>
          <HzbaTextareaInput
            v-model="currentFazit"
            :label="t('hzba.vorschau.fazit')"
            :placeholder="'...'"
            :readonly="isReadonly"
            :limit="maxFazitLength"
          />
        </div>


        <div class="mt-12 ml-6 mr-6 mb-12 flex items-center">
          <div
            :class="fazitTooLong ? 'red' : ''"
            class="flex-1"
          >
            <!--            {{ currentFazitLength ?? 0 }} / {{ maxFazitLength }} {{ t("hzba.vorschau.fazitZeichen") }}-->
          </div>

          <AButton
            v-if="!isReadonly"
            :btn-primary="true"
            class="text-lg py-5 px-8"
            shape="round"
            :disabled="!(currentFazitLength > 0) || fazitTooLong"
            @click="submit"
          >
            {{ t("hzba.buttons.bestaetigen") }}
          </AButton>
        </div>
      </div>
    </ion-content>
  </BaseModal>
</template>


<script lang="ts">
import AButton from "@/components/Base/AButton";
import HzbaBaseModal from "@/components/Base/BaseModal";
import BaseModal from "@/components/Base/BaseModal.vue";
import SectionWrapper from "@/components/Base/SectionWrapper.vue";
import Toolbar from "@/components/Navigation/Toolbar.vue";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import PhotoViewModal from "@/components/hzba/Base/HzbaPhotoViewModal.vue";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput";
import HzbaChooseInput from "@/components/hzba/Base/Input/HzbaChooseInput.vue";
import HzbaPhotoInput from "@/components/hzba/Base/Input/HzbaPhotoInput.vue";
import HzbaTextareaInput from "@/components/hzba/Base/Input/HzbaTextareaInput.vue";
import { useStore } from "@/composables/useTypedStore";
import { IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, modalController } from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "SignAndSubmitModal",
  components: {
    AButton,
    BaseModal,
    HzbaTextareaInput,
    HzbaChooseInput,
    HzbaPhotoInput,
    PhotoViewModal,
    IonButtons,
    IonButton,
    IonChip,
    Toolbar, HzbaBaseInput, HzbaGroup, HzbaBaseModal, SectionWrapper, IonIcon, IonContent, IonHeader },
  props: {
    parent: {
      type: Function,
      required: true,
    },
    maxFazitLength: {
      type: Number,
      required: true,
    }
  },
  emits: ["close", "save"],
  setup(props: any) {

    const store = useStore();
    const { t } = useI18n({ useScope: 'global' })

    // const fazitBackup = JSON.parse(JSON.stringify(props.hzba.fazit));
    const currentFazit = ref(props.parent().fazit);

    const currentFazitLength = computed( () => {
      return currentFazit.value?.trim().length
    })

    const fazitTooLong = computed(() => {
      return currentFazitLength.value > props.maxFazitLength
    });


    const submit = async () => {
      if (currentFazitLength.value > 0 ) {
        props.parent().fazit = currentFazit.value.trim();

        store.dispatch('currentHzba/saveHzbaLocally');
        await modalController.dismiss();
      }
    }

    const close = async () => {
      // props.hzba.fazit = fazitBackup;
      await modalController.dismiss();
    };

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );


    return {
      close,
      currentFazit,
      submit,
      isReadonly,
      arrowBack,
      fazitTooLong,
      currentFazitLength,
      t
    }
  },
});
</script>

<style lang="scss" scoped>
.mangel-item {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding-top: 8px;
  padding-bottom: 8px;
}

.red {
  color: $aconRed;
}
</style>