<template>
  <div class="block lg:flex h-full lg:h-screen">
    <div
      v-if="isMinLarge"
      class="sidebar ios-fix"
    >
      <div>
        <div class="properties-sidebar-wrapper">
          <div class="properties-sidebar">
            <div class="mb-4">
              <div class="flex items-center">
                <IonButton
                  fill="clear"
                  color="dark"
                  class="px-0 -ml-4"
                  size="small"
                  @click="() => $emit('goBack')"
                >
                  <IonIcon
                    :icon="arrowBack"
                    size="large"
                  />
                </IonButton>
                <h1 class="text-2xl md:text-3xl lg:mx-auto mb-0-important">
                  {{ t("immobilie.kartenAnsicht") }} ({{ properties.length }})
                </h1>
              </div>
              <div>{{ t("immobilie.aktualisiertAm") }}: {{ lastFetch }}</div>
            </div>

            <slot />

            <property-card
              v-for="item in properties"
              :key="item.id"
              :property="item"
              :class="hoveredProp && hoveredProp.id === item.id ? 'hovered' : ''"
              class="w-full max-w-md m-0 mb-4"
              :custom-click-function="() => cardClicked(item)"
            >
              <div class="absolute bottom-0 right-0 pr-4 pb-2">
                <IonButton 
                  size="small"
                  fill="flat" 
                  @click="() => openProperty(item)"
                >
                  {{ t("immobilie.oeffnen") }}
                </IonButton>
              </div>
            </property-card>
          </div>
        </div>
      </div>
    </div>

    <div
      id="map-root"
      ref="mapEl"
      style="width: 100%;"
      class="h-full lg:h-screen"
    >
      <!-- <geo-layer-toggle :map="propertyMap.map.value" /> -->
    </div>
  </div>

  <div 
    v-if="!isMinLarge"
    class="properties-preview"
  >
    <ion-slides
      v-if="propertySlideList.length > 0 && !changesLoading"
      ref="swiper"
      pager
      :options="{
        speed: 300,
        spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        loop: (propertySlideList.length > 1)
      }"
      @ionSlideReachStart="jumpToEnd()"
      @ionSlideReachEnd="jumpToStart()"
    >
      <ion-slide
        v-for="item in propertySlideList"
        :key="item.id"
        class="mb-3"
      >
        <property-card
          :property="item"
          is-slide-mode
          class="w-full max-w-md ml-12 mr-12"
        />
      </ion-slide>
    </ion-slides>
    <div
      v-if="propertySlideList.length > 1"
      class="swiper-button-next md:right-32"
      @click="slideNext()"
    />
    <div
      v-if="propertySlideList.length > 1"
      class="swiper-button-prev md:left-32"
      @click="slidePrev()"
    />
  </div>
</template>

<script lang="ts">
import GeoLayerToggle from "@/components/hzba/Base/GeoLayerToggle.vue";
import useScreenSize from "@/composables/useScreenSize";
import Property from "@/models/immobilie.model";
import { getLocalMetaData } from "@/models/local-meta-data";
import {
IonButton,
IonCard,
IonCardHeader,
IonCardSubtitle,
IonCardTitle,
IonIcon,
IonSlide,
IonSlides
} from "@ionic/vue";
import { arrowBack, locationOutline } from "ionicons/icons";
import _ from 'lodash';
import moment from "moment";
import { PropType, computed, defineComponent, ref, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
import { usePropertyMap } from "../../composables/usePropertyMap";
import PropertyCard from "./PropertyCard.vue";

export default defineComponent({
  name: "PropertiesMap",
  components: {
    IonSlides,
    IonSlide,
    IonCard,
    IonCardTitle,
    IonButton,
    IonCardSubtitle,
    IonCardHeader,
    IonIcon,
    PropertyCard,
    GeoLayerToggle
  },
  props: {
    properties: {
      type: Object as PropType<Property[]>,
      required: true,
    },
  },
emits: ['goBack'],
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })
    const propertyMap = usePropertyMap("map-root", props.properties);
    const swiper = ref();
    const activeSlide = ref();
    const hoveredProp = ref();
    const mapEl = ref();
    const router = useRouter();
    const changesLoading = ref(false);
    const propertySlideList = ref([]);


    const slideNext = (async () => {
      const currentIndex = await swiper.value.$el.getActiveIndex();
      if (currentIndex <= props.properties.length-1) {
        swiper.value.$el.slideNext();
      } else {
        swiper.value && swiper.value.$el.slideTo(1);
      }
      await slideChanged({ target: swiper.value.$el });
    })
    const slidePrev = (async () => {
      const currentIndex = await swiper.value.$el.getActiveIndex();
      if (currentIndex > 0) {
        swiper.value.$el.slidePrev();
      } else {
        swiper.value && swiper.value.$el.slideTo(props.properties.length-1);
      }
        await slideChanged({ target: swiper.value.$el });
    })
    const slideChanged = async (event: any) => {
      const index = await event.target.getActiveIndex();

      activeSlide.value = (index-1) % (props.properties.length);
      if (activeSlide.value === -1) {
        activeSlide.value = props.properties.length-1
      }

      await propertyMap.animateToProperty(props.properties[activeSlide.value]);
    }

    // propertyMap.setPropertyPointClickedListener((prop: any) => {
    //   const index = props.properties.findIndex(p => p.id === prop.id);
    //   swiper.value && swiper.value.$el.slideTo(index);
    //   propertyMap.animateToProperty(prop);
    // })
    //
    // propertyMap.setPropertyPointHoveredListener((prop: any) => {
    //   hoveredProp.value = prop;
    // })

    watch(() => props.properties, (newVal) => {
      const debouncedFctn = _.debounce(() => {
        propertySlideList.value = JSON.parse(JSON.stringify(props.properties));
        changesLoading.value = true;
        setTimeout(() => { changesLoading.value = false}, 400)
      }, 600)

      debouncedFctn();
      propertyMap.updateProperties(newVal);
    })

    setTimeout(() => {
      propertySlideList.value = JSON.parse(JSON.stringify(props.properties));

      propertyMap.reload()

      const interval = setInterval(() => {
        const mapWidth =  document.getElementById('map-root')?.clientWidth;
        const mapHeight = document.getElementById('map-root')?.clientHeight;

        if (mapWidth && mapHeight && mapWidth > 0 && mapHeight > 0) {
          console.log("Map already loaded.", mapWidth, mapHeight)
          propertyMap.reload();
          clearInterval(interval);
        } else {
          console.log("Map not loaded yet. Reloading...", mapHeight, mapWidth);
          propertyMap.reload();
        }
      }, 100)

    }, 250)

    const { isMinLarge } = useScreenSize();


    const lastFetch = computed(() => {
      const date = moment(getLocalMetaData('lastBackendFetch'));
      return `${date.format('DD.MM.YYYY')} um ${date.format('HH:mm:ss')}`
    });


    const cardClicked = (prop: any) => {
      hoveredProp.value = prop;
      propertyMap.animateToProperty(prop);
    }

    const openProperty = (prop: any) => {
      const routeData = router.resolve({ path: `/property/${prop.id }` });
      window.open(routeData.href, '_blank');
    }

    /**
     * Workaround as ionic would have a weird bug that you cannot click on the card otherwise when using infinite loop
     */
    const jumpToEnd = () => {
      swiper.value.$el.slideTo(props.properties.length)
    }
    const jumpToStart = () => {
      swiper.value.$el.slideTo(1)
    }

    return {
      locationOutline,
      slideChanged,
      swiper,
      activeSlide,
      slideNext,
      slidePrev,
      isMinLarge,
      lastFetch,
      cardClicked,
      hoveredProp,
      arrowBack,
      changesLoading,
      jumpToEnd,
      jumpToStart,
      openProperty,
      propertySlideList,
      t,
      propertyMap,
    }
  },
});
</script>

<style scoped lang="scss">
.properties-preview {
  position: absolute;
  bottom: 42px;
  left: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
}

.sidebar {
  border-right: 1px solid #9f9f9f;
}

.properties-sidebar-wrapper {
  width: 500px;
  background: #f3f5f9;
  overflow: auto;
  padding-right: 24px;
  padding-left: 24px;
  height: 100vh;
  overflow-y: scroll;
}
.properties-sidebar {
  padding-bottom: 24px;
}

h1 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.hovered {
  border: 1px solid black;
}

@media (max-width: 400px) {
  .property-card {
    width: 300px;
  }
}

</style>

<style>

.swiper-pagination-bullet-active {
  background: black !important;
}
.swiper-pagination-bullets {
  bottom: 0px !important;
}


.ios-fix {
  padding-top: unquote('constant(safe-area-inset-top))') !important;
  padding-top: env(safe-area-inset-top) !important;
}

</style>

<style scoped>

@media (max-width: 1024px) {
  #map-root {
    width: 100vw !important;
    /*height: calc( 100vh - 82px);*/
  }
}

</style>