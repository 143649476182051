<template>
  <tr
    data-cy="mangel-item-row"
    style="height: 130px; overflow: hidden"
  >
    <slot />
  </tr>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "PdfRowBase",
  components: {
  },
});
</script>

<style scoped lang="scss">
/deep/ td {
  vertical-align: top;
  font-size: 12px;
  padding: 4px 4px 4px 0px;
}
tr {
  border: none;
}

</style>