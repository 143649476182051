<template>
  <div
    class="chip"
    :data-cy="`pdf-chip-${color}`"
    :class="[
      big
        ? 'inline-flex items-center mr-2.5 px-4 py-1.5 text-sm font-medium text-white '
        : 'normal',
      mangelAmountMode ? 'mangelAmountMode' : '',
      value === undefined ? 'small' : '',
      color === 'green' ||
        color === 'yellow' ||
        color === 'red' ||
        color === 'blue'
        ? 'color-white'
        : '',
      pdfMode ? 'pdfMode' : '',
      !pdfMode && color === 'green' ? 'gradient-green' : '',
      !pdfMode && color === 'yellow' ? 'gradient-yellow' : '',
      !pdfMode && color === 'red' ? 'gradient-red' : '',
      !pdfMode && color === 'blue' ? 'gradient-blue' : '',
    ]"
  >
    <img
      v-if="pdfMode && color === 'green'"
      src="../../../../public/assets/icon/pdf/green-gradient.png"
      style="width: 100%; height: 100%"
      class="bg-img"
    >
    <img
      v-if="pdfMode && color === 'yellow'"
      src="../../../../public/assets/icon/pdf/yellow-gradient.png"
      style="width: 100%; height: 100%"
      class="bg-img"
    >
    <img
      v-if="pdfMode && color === 'red'"
      src="../../../../public/assets/icon/pdf/red-gradient.png"
      style="width: 100%; height: 100%"
      class="bg-img"
    >
    <img
      v-if="pdfMode && color === 'blue'"
      src="../../../../public/assets/icon/pdf/blue-gradient.png"
      style="width: 100%; height: 100%"
      class="bg-img"
    >
    <div class="relative z-50 top-0 p-0">
      {{ value }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PdfChip",
  props: {
    value: {
      type: [String, Number],
      default: undefined,
    },
    color: {
      type: String,
      default: "default",
    },
    mangelAmountMode: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
    /**
     * html2pdf cannot handle rounded corners, so in pdf mode we have to use images instead of styled divs
     */
    pdfMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
.chip {
  * {
  color: rgb(23, 68, 81);
  font-family: 'Avenir-Next-LT-Pro-Regular';
}
  //border: 1px solid $grey-500;
  //border-radius: 16px;
  position: relative;
  display: inline-block;
  font-weight: 500;
  color: $pdfRed1;
  text-align: center;
  font-size: 12px;

  &.normal {
    width: 48px;
    //padding: 4px;
    margin: 4px;
  }

  &.pdfMode {
    padding: 2px;
    margin: 0px 3px 0px 0px;
  }

  &.small {
    width: 30px;
    height: 14px;
  }

  &.gradient-green,
  &.gradient-yellow,
  &.gradient-blue,
  &.gradient-red {
    color: white;
    border: none;
    border-radius: 16px;
  }

  &.mangelAmountMode {
    width: unset;
    padding: 6px 12px;
  }
}

.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.color-white {
  color: white;
}
</style>