<template>
  <ion-page>
    <ion-content>
      <img
        src="../../public/assets/img/svg/acon_logo_colored.svg"
        alt="acon_icon"
        class="m-4 w-48"
      >
      <div class="text-wrapper flex items-center text-center justify-center">
        <div>
          <h1>
            {{ t("warteraum.titel") }}
          </h1>
          <div>{{ t("warteraum.infoText") }}</div>
          <div class="mt-2">
            <IonButton
              fill="flat"
              size="small"
              color="dark"
              @click="logout"
            >
              {{ t("buttons.logout") }}
            </IonButton>
          </div>
        </div>
      </div>

      <Footer />
    </ion-content>
  </ion-page>
</template>

<script lang=ts>
import { useI18n } from 'vue-i18n';
import {defineComponent} from "vue";
import { IonPage, IonButton } from "@ionic/vue";
import AButton from "@/components/Base/AButton.vue";
import useAuth from "@/composables/useAuth";
import FooterComp from "@/components/FooterComp.vue";

export default defineComponent({
  name: "WaitingRoom",
  components: {
    FooterComp,
    AButton,
    IonPage,
    IonButton,
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const logout = () => {
      useAuth().logout();
    }

    return {
      logout,
      t
    }
  }
})
</script>

<style>
.text-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
</style>