
<template>
  <pdf-a4-page
    v-for="(maengel, key) in pagedMaengel"
    :key="key"
    :pdf-key="lang"
  >
    <pdf-page-header
      :immobilie="immobilie"
      :hzba="ba"
      :tech-data="techData"
      :is-mangel-uebersicht="true"
      :page="pagesOptions.currentStartPage + key"
      :max-pages="pagesOptions.maxPages"
      :lang="lang"
    />

    <h2
      v-if="key === 0"
      class="mt-4 text-base font-bold"
      data-cy="mangeluebersicht-title"
    >
      {{ t("hzba.pdf.mangelUebersicht", 1, { locale: lang }) }}
    </h2>

    <table
      v-if="maengel && maengel.length > 0"
      class="flex-1 w-full my-2"
      data-cy="mangeluebersicht-table"
    >
      <th />
      <th class="text-left numberCol data-tr">
        {{ t("hzba.pdf.mangelBezeichnung", 1, { locale: lang }) }}
      </th>
      <th class="text-left numberCol data-tr">
        {{ t("hzba.pdf.gewerk", 1, { locale: lang }) }}
      </th>
      <th class="text-right numberCol data-tr">
        {{ t("hzba.pdf.maluswert", 1, { locale: lang }) }}
      </th>

      <pdf-row-mangel
        v-for="(mangel, key) in maengel"
        :key="key"
        :mangel="mangel"
        :odd="key % 2 === 0"
      >
        {{ mangel.titel() }} | {{ !!mangel.eingabeMedien }}
      </pdf-row-mangel>
    </table>
    <div
      v-else
      data-cy="keine-maengel-vorhanden"
    >
      Keine Mängel vorhanden.
    </div>

    <pdf-color-codes
      class="ml-1"
      :lang="lang"
    />
  </pdf-a4-page>
</template>


<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import PdfA4Page from "@/components/Pdf/Components/PdfA4Page.vue";
import PdfDataTable from "@/components/Pdf/Components/PdfDataTable.vue";
import { MSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import { getNestedBreadcrumbTitles } from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { useI18n } from "vue-i18n";
import PdfRowMangel from "@/components/Pdf/Components/PdfRowMangel.vue";
import PdfPageHeader from "@/components/Pdf/Components/PdfPageHeader.vue";
import { Fragenblock } from "@/models/ba/Fragenblock";
import Immobilie from "@/models/immobilie.model";
import PdfColorCodes from "@/components/Pdf/Components/PdfColorCodes.vue";
import {usePdfContentSlicer} from "@/components/Pdf/Bericht_Heizung/usePdfContentSlicer";

export default defineComponent({
  components: {
    PdfColorCodes,
    PdfPageHeader,
    PdfRowMangel,
    PdfDataTable,
    PdfA4Page,
  },
  props: {
    lang: {
      type: String,
      required: true,
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    mangelPages: {
      type: Number,
      required: true,
    },
    pagesOptions: {
      type: Object as PropType<{ currentStartPage: any; maxPages: any }>,
      required: true,
    },
    techData: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
  },
emits: ['update:mangelPages'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });

    const maengelComp = computed(() => {
      return props.ba.getFiredMaengel({ relevantMangelsOnly: true });
    });

    const maengelCompCustom = computed(() => {
      return props.ba.getFiredMaengel({
        freieMangelsOnly: true,
        relevantMangelsOnly: true,
      });
    });


    const pagedMaengel = computed(() => {
      const includeCustomMaengel = maengelComp.value.concat(
          maengelCompCustom.value
      );

      const pagedMaengel = usePdfContentSlicer<Mangelzuordnung>(includeCustomMaengel || [], 26, 2, (element) => {
        return element.eingabeMedien ? 4 : null
      });

      emit("update:mangelPages", pagedMaengel.length);
      return pagedMaengel;
    });

    return {
      pagedMaengel,
      t,
    };
  },
});
</script>

<style scoped lang="scss">
table {
  border-bottom: none;
}
tr {
  border-bottom: 0.5px solid #838383;
  border-top: 0.5px solid #838383;
}
th {
  // border-bottom: 1px solid $grey-500;
  border-top: none;
  font-size: 12px;
  color: #393a3a;
  font-weight: normal;
  padding-bottom: 4px;
  padding-top: 4px;
}
</style>