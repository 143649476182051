<template>
  <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content">
    <div class="landingpage-background w-screen h-screen">
      <div class="flex flex-col justify-between" style="height: 1020px">
        <div class="flex justify-between">
          <div class="text-3xl md:text-3xl">Maststandort Checkliste</div>
          <div>
            <img
              src="../../../../public/assets/img/pdf/transnetBW/tng-logo.png"
              width="200"
            />
          </div>
        </div>
        <div class="content">
          <div class="h-full max-h-full">
            <div
              class="mt-2 grid grid-cols-8 justify-items-stretch items-center"
            >
              <!-- Baumaßnahme Ende-->
              <div
                class="col-span-3 text- font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
              >
                Vorhaben
              </div>
              <div
                class="text-sm font-bold col-span-3"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Aufnahmedaten
              </div>
              <div
                class="col-span-2 text-sm font-bold"
                style="border-style:solid;border-color:white white rgb(216,215,26); white;border-radius: 5px;"
              >
                Mast-Foto
              </div>

              <div class="text-xs ml-2">Vorhaben:</div>
              <div class="text-gray-500 col-span-2 text-xs">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.allgemein-tab.allgemein-group.vorhaben'
                  )['eingabeText']
                }}
              </div>
              <div class="text-xs ml-2">Bearbeiterin:</div>
              <div
                v-if="ba?.bearbeitenderNutzer"
                class="text-gray-500 text-xs col-span-2"
              >
                {{ ba?.bearbeitenderNutzer?.username }}
              </div>
              <div v-else class="col-span-2"></div>
              <div
                v-if="getPhotoUrl(immobilie?.vorschaubild)"
                class="mt-2 row-span-5 col-span-2"
              >
                <img
                  :src="getPhotoUrl(immobilie?.vorschaubild)"
                  style="max-height: 150px"
                  height="150"
                  width="180"
                  data-cy="generalData-vorschaubild"
                />
              </div>
              <div
                v-else
                class="row-span-5 col-span-2 flex item-stretch justify-center items-center mt-2"
                style="
                  height: 150px;
                  max-height: 150px;
                  background-color: rgb(235, 239, 240);
                  border-radius: 5px;
                  border-style: solid;
                  border-color: rgb(235, 239, 240) rgb(235, 239, 240)
                    rgb(235, 239, 240) rgb(235, 239, 240);
                "
              >
                <p class="text-xs text-center self-center">
                  Kein Bild hinzugefügt
                </p>
              </div>
              <div class="text-xs ml-2">Anlagennummer:</div>
              <div class="text-gray-500 text-xs col-span-2">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.allgemein-tab.allgemein-group.anlage'
                  )['eingabeText']
                }}
              </div>
              <div class="text-xs ml-2">Firma:</div>
              <div class="text-gray-500 col-span-2 text-xs">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.allgemein-tab.allgemein-group.firma'
                  )['eingabeText']
                }}
              </div>
              <div class="text-xs ml-2">Mastnummer:</div>
              <div class="text-gray-500 text-xs col-span-2">
                {{
                  useIdentifierFrageFinder(
                    ba,
                    'rippistein-bauflaechen.allgemein-tab.allgemein-group.mastnummer'
                  )['eingabeText']
                }}
              </div>
              <div class="text-xs col-span-2 ml-2">Begehungsdatum:</div>
              <div class="text-gray-500 text-xs">
                {{ moment(ba?.begehungsdatum).format('DD-MM-YYYY') }}
              </div>
              <div class="col-span-3"></div>
              <div class="text-xs col-span-2 ml-2">Bearbeitungsstatus:</div>
              <div class="text-gray-500 text-xs">
                <div v-if="ba?.status === 'ANGELEGT'" class="text-gray-500">
                  angelegt
                </div>
                <div
                  v-else-if="ba?.status === 'IN_DURCHFUEHRUNG'"
                  class="text-gray-500"
                >
                  in Durchführung
                </div>
                <div
                  v-else-if="ba?.status === 'ABGESCHLOSSEN'"
                  class="text-gray-500"
                >
                  abgeschlossen
                </div>
                <div
                  v-else-if="ba?.status === 'FREIGEGEBEN'"
                  class="text-gray-500"
                >
                  freigegeben
                </div>
                <div
                  v-else-if="ba?.status === 'ARCHIVIERT'"
                  class="text-gray-500"
                >
                  archiviert
                </div>
              </div>
              <div class="col-span-3"></div>
              <div class="text-xs col-span-2 ml-2">Aktualisierungsdatum:</div>
              <div class="text-gray-500 text-xs">
                {{ moment(ba?.updatedAt).format('DD-MM-YYYY') }}
              </div>
              <div class="text-xs">Unterzeichnet von:</div>
              <div class="col-span-2">
                {{ ba?.unterschrifts[0]?.name || '' }}
              </div>
              <div
                v-if="getPhotoUrl(ba?.unterschrifts[0]?.bild) != undefined"
                class="h-2 text-xs ml-2 col-span-5"
              >
                <img
                  :src="getPhotoUrl(ba?.unterschrifts[0]?.bild)"
                  style="
                    max-height: 50px;
                    max-width: 180px;
                    background: #efefef;
                  "
                  height="50"
                  width="180"
                />
              </div>
              <div
                v-else
                class="h-2 text-xs ml-2 col-span-3 flex item-stretch justify-center items-center"
                style="
                  height: 50px;
                  max-height: 50px;
                  background-color: rgb(235, 239, 240);
                  border-radius: 5px;
                  border-style: solid;
                  border-color: rgb(235, 239, 240) rgb(235, 239, 240)
                    rgb(235, 239, 240) rgb(235, 239, 240);
                "
              >
                <p class="text-xs text-center self-center">
                  keine Unterschrift hinzugefügt
                </p>
              </div>
            </div>
            <!-- RESULTS-->

            <div
              class="mt-6 flex justify-between"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              <div class="text-xl md:text-xl font-bold">Gesamt-Bewertung</div>
              <div>
                <PdfChip
                  :color="colorOverall"
                  mangel-amount-mode
                  :value="amountOverall"
                  pdf-mode
                />
              </div>
            </div>
            <div class="mt-2 ml-2 text-xs">
              Bewertung der Bearbeiterin:
              <span class="text-xs text-gray-500">{{ ba?.fazit }}</span>
            </div>
            <!-- RESULTS Arbeitsfläche-->
            <div
              class="mt-6 flex justify-between"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              <div class="text-lg md:text-lg">Arbeitsfl&auml;che</div>

              <div
                class="place-self-center"
                v-if="
                  getAuswahlLabel(
                    ba,
                    'rippistein-bauflaechen.ergebnis-tab.ergebnis-group.ergebnisbauflaechen'
                  )?.length > 0
                "
              >
                {{
                  getAuswahlLabel(
                    ba,
                    'rippistein-bauflaechen.ergebnis-tab.ergebnis-group.ergebnisbauflaechen'
                  )
                }}
              </div>
              <div v-else>keine Bewertung bisher</div>
              <div>
                <PdfChip
                  :color="colorArbeitsFlaechen"
                  mangel-amount-mode
                  :value="amountArbeitsFlaechen"
                  big
                  pdf-mode
                />
              </div>
            </div>
            <div class="p-2 grid grid-cols-8 gap-1 justify-item-stretch">
              <div class="col-span-4 text-sm ml-1">Allgemeine Punkte:</div>
              <div class="justify-self-end">
                <PdfChip
                  color="green"
                  mangel-amount-mode
                  :value="
                    Object?.values(
                      getArbeitsFlaecheBewertungen(ba)['allgemein']
                    )?.filter((f) => f === 'plus')?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="blue"
                  mangel-amount-mode
                  :value="
                    Object?.values(
                      getArbeitsFlaecheBewertungen(ba)['allgemein']
                    )?.filter((f) => f === 'o')?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="yellow"
                  mangel-amount-mode
                  :value="
                    Object?.values(
                      getArbeitsFlaecheBewertungen(ba)['allgemein']
                    )?.filter((f) => f === 'minus')?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="red"
                  mangel-amount-mode
                  :value="
                    Object?.values(
                      getArbeitsFlaecheBewertungen(ba)['allgemein']
                    )?.filter((f) => f === 'x')?.length
                  "
                  pdf-mode
                />
              </div>
              <!-- jfklads-->
              <div class="col-span-4 text-sm ml-1">Geländebeschaffenheit:</div>
              <div class="justify-self-end">
                <PdfChip
                  color="green"
                  mangel-amount-mode
                  :value="
                    Object?.values(
                      getArbeitsFlaecheBewertungen(ba)['gelaende']
                    )?.filter((f) => f === 'plus')?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="blue"
                  mangel-amount-mode
                  :value="
                    Object?.values(
                      getArbeitsFlaecheBewertungen(ba)['gelaende']
                    )?.filter((f) => f === 'o')?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="yellow"
                  mangel-amount-mode
                  :value="
                    Object?.values(
                      getArbeitsFlaecheBewertungen(ba)['gelaende']
                    )?.filter((f) => f === 'minus')?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="red"
                  mangel-amount-mode
                  :value="
                    Object?.values(
                      getArbeitsFlaecheBewertungen(ba)['gelaende']
                    )?.filter((f) => f === 'x')?.length
                  "
                  pdf-mode
                />
              </div>
              <!-- jfklads-->
              <div class="col-span-4 text-sm ml-1">&Ouml;kologie:</div>
              <div class="justify-self-end">
                <PdfChip
                  color="green"
                  mangel-amount-mode
                  :value="
                    Object?.values(
                      getArbeitsFlaecheBewertungen(ba)['oekologie']
                    )?.filter((f) => f === 'plus')?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="blue"
                  mangel-amount-mode
                  :value="
                    Object?.values(
                      getArbeitsFlaecheBewertungen(ba)['oekologie']
                    )?.filter((f) => f === 'o')?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="yellow"
                  mangel-amount-mode
                  :value="
                    Object?.values(
                      getArbeitsFlaecheBewertungen(ba)['oekologie']
                    )?.filter((f) => f === 'minus')?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="red"
                  mangel-amount-mode
                  :value="
                    Object?.values(
                      getArbeitsFlaecheBewertungen(ba)['oekologie']
                    )?.filter((f) => f === 'x')?.length
                  "
                  pdf-mode
                />
              </div>
            </div>

            <!-- RESULTS Zuwegung-->
            <div
              class="flex justify-between"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              <div class="text-lg md:text-lg">Zuwegung</div>
              <div>
                <div
                  v-if="
                    getAuswahlLabel(
                      ba,
                      'rippistein-bauflaechen.ergebnis-tab.ergebnis-group.ergebniszuwegung'
                    )?.length > 0
                  "
                  class="place-self-center"
                >
                  {{
                    getAuswahlLabel(
                      ba,
                      'rippistein-bauflaechen.ergebnis-tab.ergebnis-group.ergebniszuwegung'
                    )
                  }}
                </div>
                <div v-else>keine Bewertung bisher</div>
              </div>
              <div>
                <PdfChip
                  :color="colorZuwegung"
                  mangel-amount-mode
                  :value="amountZuwegung"
                  big
                  pdf-mode
                />
              </div>
            </div>
            <div class="p-2 grid grid-cols-8 gap-2 w-fit">
              <div class="col-span-4 text-sm ml-2">Allgemeine Punkte:</div>
              <div class="justify-self-end">
                <PdfChip
                  color="green"
                  mangel-amount-mode
                  :value="
                    Object?.values(getZuwegungBewertungen(ba))
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'plus';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="blue"
                  mangel-amount-mode
                  :value="
                    Object?.values(getZuwegungBewertungen(ba))
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'o';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="yellow"
                  mangel-amount-mode
                  :value="
                    Object?.values(getZuwegungBewertungen(ba))
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'minus';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="red"
                  mangel-amount-mode
                  :value="
                    Object?.values(getZuwegungBewertungen(ba))
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'x';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
            </div>
            <!-- RESULTS Fremdleitungen-->
            <div
              class="flex justify-between"
              style="border-style:solid;border-color:white white rgb(216,215,26); white; border-radius: 5px;"
            >
              <div class="text-lg md:text-lg">Fremdleitungen</div>
              <div>
                <PdfChip
                  :color="colorFremdleitung"
                  mangel-amount-mode
                  :value="amountFremdleitung"
                  big
                  pdf-mode
                />
              </div>
            </div>
            <div class="p-2 grid grid-cols-8 gap-2 w-fit">
              <div class="col-span-4 ml-2 text-sm">Fremdleitungen:</div>
              <div class="justify-self-end">
                <PdfChip
                  color="green"
                  mangel-amount-mode
                  :value="
                    Object?.values(getFremdleitungenBewertungen(ba))
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'plus';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="blue"
                  mangel-amount-mode
                  :value="
                    Object?.values(getFremdleitungenBewertungen(ba))
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'o';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="yellow"
                  mangel-amount-mode
                  :value="
                    Object?.values(getFremdleitungenBewertungen(ba))
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'minus';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
              <div class="justify-self-end">
                <PdfChip
                  color="red"
                  mangel-amount-mode
                  :value="
                    Object?.values(getFremdleitungenBewertungen(ba))
                      ?.flat(1)
                      ?.filter((f) => {
                        return f === 'x';
                      })?.length
                  "
                  pdf-mode
                />
              </div>
            </div>
          </div>
        </div>
        <bauflaechen-footer
          :ba="ba"
          :immobilie="immobilie"
          :lang="lang"
          class=""
        ></bauflaechen-footer>
      </div>
    </div>
  </pdf-a4-page>
</template>


<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import {
  useIdentifierFrageFinder,
  getAuswahlLabel,
} from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { getPhotoUrl } from '@/utilities/get-media-url';
import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';
import { useBauflaechen } from './useBauflaechen';

import PdfChip from '../Components/PdfChip.vue';
import BauflaechenFooter from './BauflaechenFooter.vue';

export default defineComponent({
  name: 'CoverPage',
  components: {
    PdfA4Page,
    PdfChip,
    BauflaechenFooter,
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    const {
      getZuwegungBewertungen,
      getArbeitsFlaecheBewertungen,
      getFremdleitungenBewertungen,
      summarizeFremdleitungenBewertung,
      summarizeZuwegungBewertung,
      summarizeArbeitsFlaechenBewertung,
      summarizeOverall,
    } = useBauflaechen();

    return {
      user: uUser.user,
      t,
      useIdentifierFrageFinder,
      moment,
      getPhotoUrl,
      getZuwegungBewertungen,
      getArbeitsFlaecheBewertungen,
      getFremdleitungenBewertungen,
      amountFremdleitung: summarizeFremdleitungenBewertung(props.ba)['amount'],
      colorFremdleitung: summarizeFremdleitungenBewertung(props.ba)['color'],
      amountZuwegung: summarizeZuwegungBewertung(props.ba)['amount'],
      colorZuwegung: summarizeZuwegungBewertung(props.ba)['color'],
      amountArbeitsFlaechen: summarizeArbeitsFlaechenBewertung(props.ba)[
        'amount'
      ],
      colorArbeitsFlaechen: summarizeArbeitsFlaechenBewertung(props.ba)[
        'color'
      ],
      amountOverall: summarizeOverall(props.ba)['amount'],
      colorOverall: summarizeOverall(props.ba)['color'],
      getAuswahlLabel,
    };
  },
});
</script>

<style scoped>
* {
  color: rgb(23, 68, 81);
  font-family: 'Avenir-Next-LT-Pro-Regular';
}
.pdf-content {
  width: 800px;
  margin: 0 auto;
  color: white;
}

.landingpage-background {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 700px;
  height: 1050px;
  color: black;
}
.trenner {
  height: 1px;
  border-width: 0;
  color: gray;
  background-color: gray;
}
.content {
  height: 800px;
}
</style>