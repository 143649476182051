<template>
  <table>
    <tr>
      <th>{{ t("hzba.pdf.maluswert", 1, { locale: lang }) }}</th>
      <th>{{ t("hzba.pdf.hinweis", 1, { locale: lang }) }}</th>
      <th>{{ t("hzba.pdf.safety", 1, { locale: lang }) }}</th>
      <th>{{ t("hzba.pdf.effizienz", 1, { locale: lang }) }}</th>
    </tr>
    <tr>
      <td
        class="text-center"
        data-cy="MSE-malus"
      >
        <PdfChip
          :value="data.malus?.value"
          :color="data.malus?.color"
          pdf-mode
        />
      </td>
      <td
        class="text-center"
        data-cy="MSE-hinweis"
      >
        <PdfChip
          :value="data.hinweis?.value"
          :color="data.hinweis?.color"
          pdf-mode
        />
      </td>
      <td
        class="text-center"
        data-cy="MSE-safety"
      >
        <PdfChip
          :value="data.safety?.value"
          :color="data.safety?.color"
          pdf-mode
        />
      </td>
      <td
        class="text-center"
        data-cy="MSE-efficiency"
      >
        <PdfChip
          :value="data.efficiency?.value"
          :color="data.efficiency?.color"
          pdf-mode
        />
      </td>
    </tr>
  </table>
</template>

<script lang="ts">
import { useI18n } from "vue-i18n";
import PdfChip from "@/components/Pdf/Components/PdfChip";
import { defineComponent, PropType } from "vue";
import { MSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
export default defineComponent({
  name: "PdfMSEValues",
  components: { PdfChip },
  props: {
    data: {
      type: Object as PropType<MSERow>,
      required: true,
    },
    lang: {
      type: String,
      default: "de",
    },
  },
  setup(_, params) {
    const { t } = useI18n({ useScope: "global" });

    return {
      t,
    };
  },
});
</script>

<style scoped>
table {
  font-size: 12px;
}

th {
  font-weight: 300;
  width: 70px;
}
</style>