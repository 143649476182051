<template>
  <pdf-a4-page
    data-cy="pdf-overview-page"
    :pdf-key="lang"
  >
    <pdf-acon-logo />

    <h1
      class="pdf-title"
      data-cy="pdf-overviewpage-mainheader"
    >
      {{ t("hzba.pdf.kopfzeile", 1, { locale: lang }) }}_{{
        t("hzba.pdf.heizzentrale", 1, { locale: lang })
      }}
    </h1>

    <!-- * Property / Ba first details * -->
    <pdf-general-data
      :hzba="ba"
      :immobilie="immobilie"
      :lang="lang"
    />

    <!-- Basic information / Overview -->

    <h2 data-cy="pdf-overviewpage-overviewheader">
      {{ t("hzba.pdf.uebersicht", 1, { locale: lang }) }}
    </h2>
    <pdf-hzba-overview-general-info
      v-if="ba"
      :data="tableOfContentMSEData"
      :hzba="ba"
      :immobilie="immobilie"
      :value-title="t('hzba.pdf.seite', 1, { locale: lang })"
      :name="t('hzba.pdf.spaltenTitel', 1, { locale: lang })"
      :lang="lang"
    />
    <!-- Mängel-summary table of every Gewerk e.g. Heizzentrale, Wärmeerzeuger -->
    <div>
      <h2 data-cy="pdf-overviewpage-ergebnisheader">
        {{ t("hzba.pdf.ergebnis", 1, { locale: lang }) }} Zustand
      </h2>
      <PdfMSEDataTable
        :data="tableOfContentMSEData"
        :has-description1-enabled="true"
        :value-title="t('hzba.pdf.seite', 1, { locale: lang })"
        :name="t('hzba.pdf.spaltenTitel', 1, { locale: lang })"
        :lang="lang"
      />
    </div>
    <div>
      <div class="flex section-summary text-xs">
        <div class="flex-1">
          <h2 class="mt-8 mb-0">
            {{ t("hzba.pdf.fazit", 1, { locale: lang }) }}
          </h2>
          <div
            class="text-xs"
            data-cy="pdf-overviewpage-fazit"
          >
            {{ ba.fazit || "-" }}
          </div>
        </div>
      </div>

      <div>
        <pdf-data-section-summary
          :data="baSummaryMSE"
          :title="`${ba?.getName()}`"
        />
      </div>
    </div>

    <pdf-color-codes
      class="ml-1"
      :lang="lang"
    />
  </pdf-a4-page>
</template>

<script lang="ts">
import {
  useIdentifierFrageFinder,
  useIdentifierFragenblockFinder,
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import PdfA4Page from "../Components/PdfA4Page";
import PdfAconLogo from "../Components/PdfAconLogo";
import PdfGeneralData from "../Components/PdfGeneralData";
import PdfGeneralInfo from "./PdfHzbaOverviewGeneralInfo.vue";
import PdfPageBreak from "../Components/PdfPageBreak";
import PdfImageRow from "../Components/PdfImageRow";
import PdfColorCodes from "../Components/PdfColorCodes";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { ExtendedMSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import PdfMSEDataTable from "@/components/Pdf/Components/PdfMSEDataTable.vue";
import PdfDataSectionSummary from "@/components/Pdf/Components/PdfDataSectionSummary.vue";
import { MaengelReport } from "@/models/ba/MaengelReport";
import Immobilie from "@/models/immobilie.model";
import PdfHzbaOverviewGeneralInfo from "@/components/Pdf/Bericht_Heizung/PdfHzbaOverviewGeneralInfo.vue";
export default defineComponent({
  name: "PdfHzbaOverviewPage",
  components: {
    PdfHzbaOverviewGeneralInfo,
    PdfDataSectionSummary,
    PdfMSEDataTable,
    PdfColorCodes,
    PdfImageRow,
    PdfPageBreak,
    PdfGeneralInfo,
    PdfGeneralData,
    PdfAconLogo,
    PdfA4Page,
  },
  props: {
    lang: {
      type: String,
      required: true,
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    tableOfContentMSEData: {
      type: Object as PropType<ExtendedMSERow[] | undefined>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });

    const baMaengelReport = new MaengelReport(
      props.ba?.getFiredMaengel({ allRelevantMangels: true })
    );
    const baSummaryMSE = baMaengelReport.getMSESummary();

    return {
      t,
      baSummaryMSE,
      useIdentifierFrageFinder,
      useIdentifierFragenblockFinder,
    };
  },
});
</script>

<style scoped>
h2 {
  margin-top: 16px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 900;
}
</style>