<template>
  <ion-page
    @click="() => $emit('done')"
  >
    <video
      class="welcome-video"
      autoplay
      playsinline
      muted
      :onended="() => $emit('done')"
    >
      <source
        src="../../../public/assets/videos/ml_launch_video.mp4"
        type="video/mp4"
      >
      Your browser does not support the video tag.
    </video>
    <!-- <div
      class="landing_page"
    >
      <img
        src="../../../public/assets/img/svg/acon_logo_white.svg"
        alt="acon_text"
        class="acon_logo_white m-4 mt-8 lg:m-16"
      >
      <div class="title-container m-4 lg:m-16">
        <div
          class="landing_text_header text-5xl md:text-7xl"
        >
          {{ headerText }}
        </div>
        <div
          class="landing_text text-2xl md:text-5xl"
        >
          {{ descText }}
        </div>
      </div>

      <p class="bottom-8 left-4 lg:bottom-16 lg:left-16 ">
        {{ t("einloggen.tagline") }}
      </p>
    </div> -->
  </ion-page>
</template>

<script lang="ts">
import { IonPage } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'WelcomeAnimation',
  components: {
    IonPage
  },
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const headerText = ref(t("einloggen.360Inventory"));
    const descText = ref(t("einloggen.anlagentechnikTitel"));
    onMounted(async () => {
      // setTimeout(() => {
      //   headerText.value = t("einloggen.willkommenTitel");
      //   descText.value = '';
      // }, 3000);
      // setTimeout(() => {
      //   emit('done')
      // }, 5000);
    });
    return { headerText, descText, t };
  },
})
</script>

<style scoped>
.landing_page {
  font-family: 'CircularXXSub-BookSubset', sans-serif;
  color: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
}

.acon_logo_white {
  position: absolute;
  top: 10px;
  width: 210px;
  height: 70px;
}

.title-container {
  position: absolute;
  top: 200px;
}
.landing_text_header {
  letter-spacing: 1px;
  font-weight: 500;
}

.landing_text {
  padding-top: 16px;
  margin-bottom: 1rem;
  letter-spacing: 1px;
  font-weight: 500;
}

p {
  position: absolute;
  font-size: 25px;
  letter-spacing: 1px;
  margin: 0;
}

.welcome-video {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  background-color: #4532C9;
}

body {
  background-color: #4532C9;
}

</style>