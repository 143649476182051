<template>
  <!--  <ion-button-->
  <!--    fill="outline"-->
  <!--    :color="toolbarMode ? 'light' : 'dark'"-->
  <!--    class="ml-4 searchbar-button"-->
  <!--    :class="toolbarMode ? 'mr-4' : ''"-->
  <!--  >-->
  <!--    <ion-icon :icon="swapVerticalOutline" />-->
  <!--  </ion-button>-->

  <div 
    class="text-xl inline-block"
    @click="(e) => openPopover( e, 'sortby', { items: sortTerms, preselectedItem: sortTerm }, (res) => $emit('update:sortTerm', res))"
  >
    Sortierung: {{ sortTerm && sortTerm.label }}
  </div>
</template>

<script lang="ts">
import {swapVerticalOutline} from "ionicons/icons";
import {popoverHelper} from "../../utilities/popover-helper";
import {defineComponent, PropType} from "vue";
import {SortTerm} from "@/composables/Sort/SortTerms";

export default defineComponent({
  name: "SortBy",
  props: {
    sortTerms: {
      type: Object,
      required: true,
    },
    sortTerm: {
      type: Object as PropType<SortTerm>,
      default: undefined,
    },
    toolbarMode: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // const sortTermValues = props.sortTerms.map(el => el. Object.values(props.sortTerms[0]);

    return {
      swapVerticalOutline,
      // sortTermValues,
      openPopover: popoverHelper,
    }
  }
})
</script>

<style scoped>

</style>