<template>
  <ion-card
    :button="true"
    class="rounded-sm bg-white acon-card-shadow"
    data-cy="property-list-item"
    @click="cardClicked"
    @mouseover="hover"
  >
    <div class="p-0 m-0">
      <!--      <div -->
      <!--        class="p-0 m-0 md:h-40 flex flex-row"-->
      <!--        :style="isSlideMode ? 'h-40' : 'h-36'"-->
      <!--      >-->
      <div
        class="p-0 m-0 flex flex-row"
        :style="isSlideMode ? 'h-40' : 'h-36'"
      >
        <div class="py-4 pl-4">
          <a-image
            :image-class="`w-28 h-28 md:h-32 object-cover p-0 m-0`"
            :src="getPhotoUrl(property.vorschaubild, { small: true })"
            set-to-max
          />
        </div>
        <div class="content-wrapper">
          <ion-card-header class="flex-1 text-left">
            <ion-card-title class="text-lg leading-5 md:text-xl mb-3">
              {{ property.externeObjektNr }}
            </ion-card-title>
            <div class="text-md font-light">
              {{ property.strasse }}<br>
              {{ property.plz }} {{ property.stadt }}
            </div>
            <ion-card-subtitle class="text-md">
              <div class="flex flex-wrap">
                <div
                  v-for="label in baLabels"
                  :key="label"
                  class="
                    btn-secondary
                    inline-flex
                    items-center
                    mr-2.5
                    px-2
                    py-1
                    rounded-full
                    text-xs      
                    mt-2
                  "
                  style="word-break: break-word;"
                  :btnSecondarySm="true"
                >
                  <!--                {{ !!property.technikzentraleBericht ? t("immobilie.gebaeudeAufnahme") : t("immobilie.heizzentralenAufnahme") }}-->
                  <!--                {{ property.technikzentraleBericht }} &#45;&#45;-->
                  {{ label }}
              </div>

                <CloudOfflineIcon
                  v-if="property.isDownloaded"
                  small
                  class="mt-2"
                />
              </div>
            </ion-card-subtitle>
          </ion-card-header>
        </div>
      </div>
      <slot />
    </div>
  </ion-card>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, defineComponent, PropType } from "vue";
import Property from "../../models/immobilie.model";
import { useRouter } from "vue-router";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import { getPhotoUrl } from "@/utilities/get-media-url";
import AImage from "@/components/Base/AImage.vue";
import AButton from "@/components/Base/AButton.vue";
import getLocalization from "@/utilities/get-localization";
import CloudOfflineIcon from "@/components/Base/CloudOfflineIcon.vue";

export default defineComponent({
  name: "PropertyCard",
  components: {
    CloudOfflineIcon,
    AImage,
    AButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonGrid,
    IonRow,
    IonCol,
  },
  props: {
    property: {
      type: Object as PropType<Property>,
      required: true,
    },
    customClickFunction: {
      type: Function,
      default: undefined,
    },
    isSlideMode: {
      type: Boolean,
      default: false
    }
  },
  emits: ['hover'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const router = useRouter();

    const goToDetailPage = () => {
      router.push({
        path: `/property/${props.property.id}`
      });
    };

    const hover = () => {
      emit('hover');
    }

    const cardClicked = () => {
      if (props.customClickFunction) {
        props.customClickFunction()
        return;
      }
      goToDetailPage()
    }

    /**
     * Support only one label in slide mode but with a notice like "Heizungsbestandsaufnahme +2"
     * otherwise return full array of ba's that are abgeschlossen or freigegeben
     */
    const baLabels = computed(() => {

      const labels: any[] = [];
      let extraLabelsCounted = 0;
      props.property?.bestandsaufnahmes?.forEach((el) => {

        const name = el.name && getLocalization(el.name);
        if (!name) { return; }

        if (labels.indexOf(name) === -1 && (el.status === "ABGESCHLOSSEN" || el.status === "FREIGEGEBEN")) {
          if (props.isSlideMode && labels.length >= 1) {
            extraLabelsCounted += 1;
          } else {
            labels.push(name)
          }
        }
      })

      if (extraLabelsCounted > 0) {
        labels[0] += ` +${extraLabelsCounted}`;
      }

      if (labels.length > 1) {
        return ['Gebäudeaufnahme']
      }

      return labels;
    })

    return { router, goToDetailPage, getPhotoUrl, hover, cardClicked, t, baLabels };
  },
});
</script>

<style scoped>
.image-preview {
  width: 9rem !important;
  height: 100%;
  flex-shrink: 0;
  /*height: 7rem !important;*/
}

.row {
  display: flex;
  flex-direction: row;
}

.flex-1 {
  flex: 1 1 0%;
}

/deep/ ion-card-header {
  padding: 0;
  padding-inline: 0;
}

.content-wrapper {
  padding: 16px;
}
</style>


<style scoped lang="scss">
ion-card {
  box-shadow: $boxShadow;
}
</style>