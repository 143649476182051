import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-334b49c5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "pdf-title",
  "data-cy": "pdf-overviewpage-mainheader"
}
const _hoisted_2 = { "data-cy": "pdf-overviewpage-overviewheader" }
const _hoisted_3 = { "data-cy": "pdf-overviewpage-ergebnisheader" }
const _hoisted_4 = { class: "flex section-summary text-xs" }
const _hoisted_5 = { class: "flex-1" }
const _hoisted_6 = { class: "mt-8 mb-0" }
const _hoisted_7 = {
  class: "text-xs",
  "data-cy": "pdf-overviewpage-fazit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pdf_acon_logo = _resolveComponent("pdf-acon-logo")!
  const _component_pdf_general_data = _resolveComponent("pdf-general-data")!
  const _component_pdf_hzba_overview_general_info = _resolveComponent("pdf-hzba-overview-general-info")!
  const _component_PdfMSEDataTable = _resolveComponent("PdfMSEDataTable")!
  const _component_pdf_data_section_summary = _resolveComponent("pdf-data-section-summary")!
  const _component_pdf_color_codes = _resolveComponent("pdf-color-codes")!
  const _component_pdf_a4_page = _resolveComponent("pdf-a4-page")!

  return (_openBlock(), _createBlock(_component_pdf_a4_page, {
    "data-cy": "pdf-overview-page",
    "pdf-key": _ctx.lang
  }, {
    default: _withCtx(() => [
      _createVNode(_component_pdf_acon_logo),
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.t("hzba.pdf.kopfzeile", 1, { locale: _ctx.lang })) + "_" + _toDisplayString(_ctx.t("hzba.pdf.heizzentrale", 1, { locale: _ctx.lang })), 1),
      _createVNode(_component_pdf_general_data, {
        hzba: _ctx.ba,
        immobilie: _ctx.immobilie,
        lang: _ctx.lang
      }, null, 8, ["hzba", "immobilie", "lang"]),
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.t("hzba.pdf.uebersicht", 1, { locale: _ctx.lang })), 1),
      (_ctx.ba)
        ? (_openBlock(), _createBlock(_component_pdf_hzba_overview_general_info, {
            key: 0,
            data: _ctx.tableOfContentMSEData,
            hzba: _ctx.ba,
            immobilie: _ctx.immobilie,
            "value-title": _ctx.t('hzba.pdf.seite', 1, { locale: _ctx.lang }),
            name: _ctx.t('hzba.pdf.spaltenTitel', 1, { locale: _ctx.lang }),
            lang: _ctx.lang
          }, null, 8, ["data", "hzba", "immobilie", "value-title", "name", "lang"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.t("hzba.pdf.ergebnis", 1, { locale: _ctx.lang })) + " Zustand ", 1),
        _createVNode(_component_PdfMSEDataTable, {
          data: _ctx.tableOfContentMSEData,
          "has-description1-enabled": true,
          "value-title": _ctx.t('hzba.pdf.seite', 1, { locale: _ctx.lang }),
          name: _ctx.t('hzba.pdf.spaltenTitel', 1, { locale: _ctx.lang }),
          lang: _ctx.lang
        }, null, 8, ["data", "value-title", "name", "lang"])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.t("hzba.pdf.fazit", 1, { locale: _ctx.lang })), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.ba.fazit || "-"), 1)
          ])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_pdf_data_section_summary, {
            data: _ctx.baSummaryMSE,
            title: `${_ctx.ba?.getName()}`
          }, null, 8, ["data", "title"])
        ])
      ]),
      _createVNode(_component_pdf_color_codes, {
        class: "ml-1",
        lang: _ctx.lang
      }, null, 8, ["lang"])
    ]),
    _: 1
  }, 8, ["pdf-key"]))
}