<template>
  <table class="flex-1 w-full">
    <!-- <pre>{{dummyData}}</pre> -->
    <tr>
      <!-- <th class="text-left pl-2">
        {{ positionTranslated }}
      </th> -->

      <!-- <th
        v-if="valueTitle !== undefined"
        class="text-left value-col text-center"
      >
        {{ valueTitle }}
      </th> -->

      <th class="text-left pl-2">
        {{ t("immobilie.pdf.mangel", 1, { locale: lang}) }}
      </th>
      <th class="text-left pl-2">
        {{ t("immobilie.pdf.mangelKategorie", 1, { locale: lang}) }}
      </th>
      <th class="text-center pl-2">
        {{ t("immobilie.pdf.malus", 1, { locale: lang}) }}
      </th>
      <th class="text-center pl-2">
        {{ t("immobilie.pdf.status", 1, { locale: lang}) }}
      </th>
      <th class="text-center pl-2">
        {{ t("immobilie.pdf.gewerk", 1, { locale: lang}) }}
      </th>
      <th class="text-center pl-2">
        {{ t("immobilie.pdf.kostenprognose", 1, { locale: lang}) }}
      </th>
      <!-- <th class="numberCol">
       {{ t("immobilie.pdf.malus", 1, { locale: lang}) }}
      </th>
      <th class="numberCol">
       {{ t("immobilie.pdf.status", 1, { locale: lang}) }}
      </th>
      <th class="numberCol">
       {{ t("immobilie.pdf.gewerk", 1, { locale: lang}) }}
      </th>
      <th class="numberCol">
       {{ t("immobilie.pdf.kostenprognose", 1, { locale: lang}) }}
      </th> -->
    </tr>

    <tr
      v-for="(entry, key) in dummyData.maengel"
      :key="key"
      class="data-tr items-center"
    >
      <td
        style="min-width: 120px; max-width: 200px;"
      >
        {{ entry.titel }}
      </td>
      <td
        class="text-left"
      >
        {{ entry.kategorie }}
      </td>
      <td
        class="text-center"
      >
        <PdfChip
          :value="entry.malus"
          color="yellow"
          pdf-mode
        />
      </td>
      <td
        class="text-center"
      >
        {{ entry.status }}
      </td>
      <td
        class="text-center"
      >
        {{ entry.gewerk }}
      </td>
      <td
        class="text-center"
      >
        {{ entry.kostenprognose }} €
      </td>
    </tr>

    <!-- <tr
      v-for="(entry, key) in data"
      :key="key"
      class="data-tr items-center"
    >
      <td
        style="min-width: 120px; max-width: 200px;"
        class="pl-2"
      >
        {{ entry.getTitle() }}
      </td>

      <td
        class="text-right"
      >
      </td>
<td class="text-center">
        <PdfChip
          :value="entry.getFiredMaengel().length"
          color="yellow"
        />
      </td> -->
    <!-- <td
        class="text-right"
        :class="[
          entry.hint && entry.hint.color === 'red' ? 'red' : '',
          entry.hint && entry.hint.color === 'yellow' ? 'yellow' : '',
          entry.hint && entry.hint.color === 'green' ? 'green' : '',
        ]"
      >
        {{ entry.hint && entry.hint.value }}
      </td>
      <td class="text-center">
        <PdfChip
          v-if="entry.malus"
          :value="entry.malus.value"
          :color="entry.malus.color"
        />
      </td>
      <td class="text-center">
        <PdfChip
          v-if="entry.safety"
          :value="entry.safety.value"
          :color="entry.safety.color"
        />
      </td>
      <td class="text-center">
        <PdfChip
          v-if="entry.efficiency"
          :value="entry.efficiency.value"
          :color="entry.efficiency.color"
        />
      </td> -->
    <!-- </tr> -->
  </table>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import PdfChip from "@/components/Pdf/Components/PdfChip";
import {defineComponent} from "vue";
export default defineComponent({
  name: "GenericPdfMgtSummaryTable",
  components: {PdfChip},

  props: {
          data: {
            type: Array,
            required: true
          },
          valueTitle: {
            type: String,
            default: undefined
          },
          name: {
            type: String,
            default: 'Position'
          },
          lang: {
            type: String,
            default: 'de'
          },
          isTableOfContent: {
            type: Boolean,
          }
        },

  setup(props, { emit }) {

     const { t } = useI18n({ useScope: 'global' })
     const positionTranslated = computed(()=>{
     return props.name === 'Position'? t('hzba.pdf.position') : props.name
    })

    console.log('data:', props.data)

    const dummyData = {
      maengel:[
      {
        titel:"Abwasser: Fehlende Rohrschottung",
        kategorie:"Sicherheitsgefährdung",
        malus:"12",
        status:"Akut",
        gewerk:"Sanitär",
        kostenprognose:"3000",
      },
      {
        titel:"Trinkwasser: Fehlende Dämmung",
        kategorie:"Sicherheitsgefährdung",
        malus:"5",
        status:"Akut",
        gewerk:"Sanitär",
        kostenprognose:"500",
      },
      {
        titel:"Trinkwasser: Stagnation durch Stichleitungen",
        kategorie:"Sicherheitsgefährdung",
        malus:"20",
        status:"Akut",
        gewerk:"Sanitär",
        kostenprognose:"2500",
      },
      {
        titel:"Abwasser: Fehlende Rohrschottung",
        kategorie:"Sicherheitsgefährdung",
        malus:"12",
        status:"Akut",
        gewerk:"Sanitär",
        kostenprognose:"3000",
      },
      {
        titel:"Trinkwasser: Fehlende Dämmung",
        kategorie:"Sicherheitsgefährdung",
        malus:"5",
        status:"Akut",
        gewerk:"Sanitär",
        kostenprognose:"500",
      },
      {
        titel:"Trinkwasser: Stagnation durch Stichleitungen",
        kategorie:"Sicherheitsgefährdung",
        malus:"20",
        status:"Akut",
        gewerk:"Sanitär",
        kostenprognose:"2500",
      }
    ]
    }

    return {
        dummyData,
        t
      }
  },

})
</script>

<style scoped lang="scss">

table {
  font-size: 12px;
}
tr {
  border-bottom: 1px solid $grey-800 !important;
}

th {
  font-size: 12px;
  padding-bottom: 4px;
}

.data-tr {
  height: 22px;
}

.numberCol {
  width: 70px;
}

.red {
  color: $pdfRed1;
}

.yellow {
  color: $pdfYellow1;
}

.green {
  color: $pdfGreen1;
}

.value-col {
  padding: 8px;
}

</style>