<template>
  <div data-cy="bilder-ansicht">
    <h2 class="mt-4 mb-0 font-bold">
      Bilder
    </h2>
    <div class="flex flex-row justify-between">
      <div
        v-for="imgObj in imageObjects"
        :key="imgObj"
      >
        <img
          v-if="getPhotoUrl(imgObj)"
          :src="getPhotoUrl(imgObj)"
          data-cy="image"
        >
        <div
          v-if="imgObj?.imageNotAvailable"
          class="flex justify-center items-center h-40"
          style="background: rgb(237, 237, 237);"
        >
          <p class="text-xs text-center text-gray-400 mx-2 w-28">
            {{ imgObj?.imageNotAvailable }} nicht verfügbar
          </p>
        </div>
      </div>
      <!-- <div
        v-for="imgObj in imageObjects"
        :key="imgObj"
        class="flex-shrink-0"
        :style="`width: ${(800 - 72 - 32 - (maxImages-1)*2) / maxImages}px`"
      >
        <PdfImageCropped
            v-if="getPhotoUrl(imgObj)"
          :src="getPhotoUrl(imgObj)"
          :width="(800 - 72 - 32 - (maxImages-1)*2) / maxImages"
          :height="160"
        />

      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { getPhotoUrl } from "@/utilities/get-media-url";
// import PdfImageCropped from "@/components/Pdf/Components/PdfImageCropped.vue";

export interface ImageObjects {
  image: any;
  title: string;
  width: "double" | undefined;
}

export default defineComponent({
  name: "PdfImageRow",
  // components: { PdfImageCropped },
  props: {
    imageObjects: {
      type: Array,
      required: true,
    },
    hideTitles: {
      type: Boolean,
      default: false,
    },
    maxImages: {
      type: Number,
      default: 5,
    },
    stretch: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      getPhotoUrl,
    };
  },
});
</script>

<style scoped>
.image-object ~ .image-object {
  margin-left: 8px;
}
h2 {
  font-size: 14px;
  font-weight: 900;
}

.title-container {
  background: rgba(255, 255, 255, 0.75);
}

img {
  /* height: 100%; */
  /* clip-path: inset(0px 0px 0px 0px); */
  height: 180px;
  width: 120px;
  max-height: 160px;
}

.nachspeisung {
  margin-right: 20px;
}
</style>