<template>
  <ion-page>
    <toolbar :title="baSubpage === 'summary' ? 'Vorschau' :`MAST - ${immobilie ? immobilie.externeObjektNr : '...'}`">
      <template #leading>
        <ion-buttons slot="start">
          <!--          <ion-back-button-->
          <!--            v-if="baSubpage !== 'summary'"-->
          <!--            default-href="/bas"-->
          <!--          />-->
          <!--          <ion-button-->
          <!--            v-if="baSubpage !== 'summary'"-->
          <!--            fill="clear"-->
          <!--            color="light"-->
          <!--            class="px-0 -ml-4"-->
          <!--            size="small"-->
          <!--            @click="() => closeHzba()"-->
          <!--          >-->
          <!--            <ion-icon-->
          <!--              :icon="close"-->
          <!--              size="large"-->
          <!--            />-->
          <!--          </ion-button>-->
          
          <ion-button
            fill="clear"
            class="px-0 -ml-4"
            size="small"
            @click="() => goBackFromSummary()"
          >
            <ion-icon
              :icon="arrowBack"
              size="large"
            />
          </ion-button>
          <!-- icon "no network connection" -->
          <ion-button
            v-if="networkStatus === ('none' || 'undefined') && baSubpage !== 'summary'"
            :key="networkStatus"
            data-cy="network-status"
          >
            <img src="/assets/img/svg/Cloud_Error.svg">
          </ion-button>
        </ion-buttons>
      </template>
      <template #trailing>
        <ion-buttons
          slot="primary"
        >
          <!-- icon "no network connection" -->
          <ion-button
            v-if="networkStatus === ('none' || 'undefined') && baSubpage === 'summary'"
            :key="networkStatus"
            class="inverse-text-color"
            data-cy="network-status"
          >
            <img src="/assets/img/svg/Cloud_Error.svg">
          </ion-button>

          <ion-button
            v-if="baSubpage !== 'summary'"
            class="inverse-text-color"
            data-cy="button-preview"
            @click="showSummary"
          >
            {{ $t("hzba.buttons.vorschau") }}
          </ion-button>
        </ion-buttons>
      </template>
    </toolbar>

    <ion-content>
      <div v-if="ba">
        <div class="md:container lg:flex md:mx-auto mt-6">
          <div
            class="overview-item row lg:block items-center lg:w-1/3 lg-boxed-container lg:p-4 self-start z-50 top-0 lg:top-4"
          >
            <div class="mb-8 hidden lg:block">
              <h3 class="mt-6">
                <strong>{{ $t("hzba.uebersicht.objektTitel") }} {{ immobilie && immobilie.externeObjektNr }}</strong><br>{{ getLocalization(ba.name) }}
              </h3>
              <p v-if="ba.begehungsdatum">
                {{ $t("hzba.uebersicht.datumTitel") }}: {{ new Date(ba.begehungsdatum).toLocaleString("de-De") }}
              </p>
              <p v-if="ba.updatedAt">
                {{ $t("hzba.uebersicht.updateAmTitel") }} {{ new Date(ba.updatedAt).toLocaleString("de-De") }}
              </p>
              <p v-if="ba.updatedBy?.username">
                {{ $t("hzba.uebersicht.updateVonTitel") }} {{ ba.updatedBy?.username }}
              </p>
              <p v-if="HzbaStatus[ba.status]">
                {{ $t("hzba.uebersicht.statusTitel") }}: {{ translatedHzbaStatus(ba.status, t) }} {{ ba.bearbeitenderNutzer?.username ? `(${ba.bearbeitenderNutzer.username})` : '' }}
              </p>
              <p v-if="ba.isLocal">
                {{ $t("hzba.uebersicht.asynchron") }}
              </p>
            </div>

            <tabs
              :selected-view="baSubpage"
              @showSummary="showSummary"
              @changeTab="changeTab"
            />
          </div>

          <div
            class="lg:w-2/3"
          >
            <div class="pb-8 -mt-8 pt-8 container">
              <HzbaMainView
                v-if="ba && baSubpage !== 'summary'"
                :ba="ba"
                :immobilie="immobilie"
                :route-name="baSubpage"
              />
              <HzbaSummary v-else-if="baSubpage === 'summary'" />
            </div>
          </div>
        </div>
      </div>
      <Skeleton v-else />
    </ion-content>
  </ion-page>
</template>


<script lang="ts">

import AButton from "@/components/Base/AButton";
import Toolbar from "@/components/Navigation/Toolbar";
import ProgressIndicator from "@/components/ProgressIndicator";
import Skeleton from "@/components/Skeleton.vue";
import Tabs from "@/components/hzba/BaTabs.vue";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import HzbaSummary from "@/components/hzba/Views/BaSummary.vue";
import HzbaMainView from "@/components/hzba/Views/HzbaMainView";
import useBestandsaufnahmeSubpage from "@/composables/Bestandsaufnahme/useBestandsaufnahmeSubpage";
import { useStore } from "@/composables/useTypedStore";
import { HzbaStatus, translatedHzbaStatus } from "@/models/ba/interfaces/IBestandsaufnahme";
import PdfPreview from "@/pages/hzba/_id/PdfPreview";
import getLocalization from "@/utilities/get-localization";
import { Network } from "@capacitor/network";
import {
IonBackButton,
IonButton,
IonButtons,
IonContent,
IonIcon,
IonLabel,
IonPage,
IonSegment,
IonSegmentButton,
onIonViewWillEnter,
onIonViewWillLeave
} from "@ionic/vue";
import { arrowBack, close } from "ionicons/icons";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ViewHZBA",
  components: {
    Skeleton,
    PdfPreview,
    HzbaMainView,
    HzbaSummary,
    Tabs,
    IonIcon,
    AButton,
    HzbaGroup,
    ProgressIndicator,
    Toolbar,
    IonBackButton,
    IonButtons,
    IonButton,
    IonPage,
    IonLabel,
    IonContent,
    IonSegment,
    IonSegmentButton
  },
  setup(_, params) {

    const { t } = useI18n({ useScope: 'global' })

    const store = useStore();
    const router = useRouter();
    const lastRoute = ref()

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    const { ba, immobilie, baSubpageName: baSubpage, localSubpage } = useBestandsaufnahmeSubpage({onIonViewWillEnter, onIonViewWillLeave });

    const showSummary = () => {
      lastRoute.value = baSubpage.value;
      // router.replace(`/ba/${ba.value!.id}/summary`)
      history.replaceState({}, 'title', `/ba/${ba.value!.id}/summary`)
      localSubpage.value = 'summary';
    }

    const goBackFromSummary = () => {

      const rec = store.state.app.recentRoute;

      // console.log("goBackFromSummary", rec, baSubpage);
      if (baSubpage.value === "summary") {
        if (lastRoute.value) {
          changeTab(lastRoute.value)
          // router.replace(`/ba/${ba.value!.id}/${lastRoute.value}`)
        } else {
          changeTab('')
          // router.replace(`/ba/${ba.value!.id}`)
        }
      } else if (rec.name === 'bas') {
        router.go(-1);
      } else {
        router.replace("/bas");
      }
      // router.replace(`/ba/${ba.value!.id}/${lastRoute.value}`)
    }

    const changeTab = (val?: string) => {
      if (val) {
        history.replaceState({}, 'title', `/ba/${ba.value!.id}/${val}`)
      } else {
        history.replaceState({}, 'title', `/ba/${ba.value!.id}`)
      }

      localSubpage.value = val;
    }

    const networkStatus = ref();

    Network.addListener("networkStatusChange", (status) => {
      console.log('network status: ', status, 'connection type: ', status.connectionType);
      networkStatus.value = status.connectionType;
    });

    const closeHzba = () => {
      router.replace(`/bas`)
    }

    return {
      showSummary,
      baSubpage,
      immobilie,
      objectCountHeight: 62,
      isReadonly,
      ba,
      HzbaStatus,
      closeHzba,
      arrowBack,
      close,
      goBackFromSummary,
      translatedHzbaStatus,
      t,
      getLocalization,
      changeTab,
      networkStatus
    };
  },
});
</script>


<style scoped lang="scss">
.overview-item {
  position: sticky;
  align-self: flex-start !important;
  position: -webkit-sticky; /* for Safari */
}

ion-icon {
  color: $color3;
}

</style>