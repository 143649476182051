<template>
  <ion-item lines="none" data-cy="text-input-item">
    <ion-grid class="p-0 mb-8">
      <ion-row class="ion-wrap d-flex items-center">
        <input-label>{{ label }}</input-label>
        <ion-col class="ion-float-right" />
        <unit-chip
          show-unit
          :unit="unit"
        />
      </ion-row>
      <ion-row class="mb-4">
        <geo-json-input
          :identifier="identifier"
          :input-json="modelValue"
          :map-config="mapConfig"
          :min-max="minMax"
          :readonly="readonly"
          @update:input-json="onChanged"
        />
      </ion-row>

      <input-error :errors="errors" />
    </ion-grid>
  </ion-item>
</template>

<script lang="ts">

import GeoJsonInput from "@/components/hzba/Base/GeoJsonInput.vue";
import InputError from "@/components/hzba/Base/Input/InputElements/InputError";
import InputLabel from "@/components/hzba/Base/Input/InputElements/InputLabel";
import UnitChip from "@/components/hzba/Base/Input/InputElements/UnitChip";
import { GeoJsonConfig } from "@/models/ba/interfaces/IFrage";
import { IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow, IonText, IonTextarea } from "@ionic/vue";
import { geometry, unitsFactors } from "@turf/turf";
import { PropType, defineComponent } from "vue";
export default defineComponent({
  name: "HzbaGeoJsonInput",
  components: {
    GeoJsonInput,
    InputError,
    UnitChip,
    InputLabel,
    IonItem,
    IonCol,
    IonRow,
    IonGrid,
    IonText,
    IonInput,
    IonLabel,
    IonTextarea
  },
  props: {
    modelValue: {
      type: Object,
      default: undefined,
    },
    identifier: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    mapConfig: {
      type: Object as PropType<GeoJsonConfig>,
      required: true
    },
    minMax: {
      type: Object as PropType<any>,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => [],
    },
    unit: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    function onChanged(e: any) {
      if (e.features.length >= 1) {
        // props.mapConfig = null
      }
      emit("update:modelValue", e);
    }

    return {
      onChanged
    }
  }
})
</script>

<style scoped lang="scss">
.unit {
  background: #efefef;
  padding: 4px 8px;
  border-radius: 4px;
}


ion-label {
  max-width: unset !important;
}

ion-textarea {
  background: $grey-100;
  border: 1px solid $grey-300;
  border-radius: 5px;
  min-height: 150px;
  text-align: left;
  padding: 16px;
  //margin-right: 16px;
  margin-bottom: 16px;
}

.error-text {
  color: $aconRed;
}

</style>

<style>
textarea {
  min-height: 150px;
}
</style>