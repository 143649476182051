<template>
  <ion-app>
    <!--    <ion-page style="background: pink;">-->
    <!-- applinkbanner disabled for ARIO -->
    <!-- <AppLinkBanner v-if="showAppLink" /> -->
    <ion-tabs
      :class="showAppLink ? 'app-link-shown' : ''"
    >
      <!--        <div :class="showBottomNavigation ? '' : 'bottomNavigationHidden' ">-->
      <!--          <ion-router-outlet :class="showBottomNavigation ? '' : 'bottomNavigationHidden'" />-->
      <!--        </div>-->
      <ion-router-outlet :animated="true" />
      <!-- NOTE: ion-tab-bar does not work in seperated component -->
      <ion-tab-bar
        v-show="showBottomNavigation"
        slot="bottom"
        class="ml-background inverse-text-color"
        :class=" isDesktopToolbarForbidden ? 'bottomNavigation' : ''"
      >
        <ion-tab-button
          tab="tab_properties"
          href="/properties"
          data-cy="toolbar-immobilien"
          class="ml-background"
        >
          <ImmobilienIcon :is-active="routeName === 'propertiesList'" />
          <ion-label 
            class="inverse-text-color" 
            :class="{ 'ml-forest-text': routeName === 'propertiesList' }"
          >
            {{ t("toolbar.immobilien") }}
          </ion-label>
        </ion-tab-button>

        <ion-tab-button
          v-if="!user.isImmobilienverwalter()"
          tab="tab_main"
          href="/bas"
          class="ml-background"
        >
          <ToolbarListIcon :is-active="routeName === 'bas'" />
          <ion-label 
            class="inverse-text-color" 
            :class="{ 'ml-forest-text': routeName === 'bas' }"
          >
            {{ t("toolbar.bestandsaufnahmen") }}
          </ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="tab_setting"
          href="/settings"
          data-cy="toolbar-einstellungen"
          class="ml-background"
        >
          <SettingsIcon :is-active="routeName === 'settings'" />
          <ion-label 
            class="inverse-text-color" 
            :class="{ 'ml-forest-text': routeName === 'settings' }"
          >
            {{ t("toolbar.einstellungen") }}
          </ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
    <!--    </ion-page>-->
    <dev-box v-if="isDev" />

    <update-screen v-if="isUpdating" />
  </ion-app>
</template>

<script lang="ts">
import AppLinkBanner from "@/components/AppLinkBanner.vue";
import DevBox from "@/components/DevBox";
import Navigation from "@/components/Navigation/Toolbar";
import UpdateScreen from "@/components/Other/UpdateScreen.vue";
import { isDevEnvironment } from "@/composables/useEnvDetector";
import useFetchData from "@/composables/useFetchData";
import useNetworkState from "@/composables/useNetworkState";
import { useOTA } from "@/composables/useOTA";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import AppLogger from "@/utilities/AppLogger";
import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
import {
IonApp,
IonHeader,
IonIcon,
IonLabel,
IonPage,
IonRouterOutlet,
IonSlide,
IonSlides,
IonTabBar,
IonTabButton,
IonTabs,
IonTitle,
IonToolbar,
isPlatform,
} from "@ionic/vue";
import { home, reader, settings } from "ionicons/icons";
import { computed, defineComponent, onMounted, onUnmounted, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from "vue-router";
import ImmobilienIcon from "../public/assets/mlIcons/ImmobilienIcon.vue";
import SettingsIcon from "../public/assets/mlIcons/SettingsIcon.vue";
import ToolbarListIcon from "../public/assets/mlIcons/ToolbarListIcon.vue";
import useUser from './composables/useUser';

export default defineComponent({
  name: "App",
  components: {
    UpdateScreen,
    AppLinkBanner,
    Navigation,
    ImmobilienIcon,
    ToolbarListIcon,
    SettingsIcon,
    IonApp,
    IonTabBar,
    IonTabButton,
    IonSlides,
    IonSlide,
    IonTabs,
    IonLabel,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonHeader,
    IonToolbar,
    IonTitle,
    DevBox,
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore();
    const useData = useFetchData();
    const screenSize = useScreenSize();
    const networkState = useNetworkState();
    const user = useUser();
    const ota = useOTA();
    const route = useRoute();
    let justStarted = true;
    const router = useRouter();
    const routeName = computed(() => { return router.currentRoute.value.name; });

    const showBottomNavigation = computed(() => {
      return store.state.app.showBottomNavigation;
    });

    const showAppLink = computed(() => {
      return store.state.app.showAppLinkBanner;
    });

    const isDesktopToolbarForbidden = computed(() => {
      return !(isPlatform("ios") || isPlatform("ipad"));
    });

    const isDev = isDevEnvironment();


    const enabledDevModeLogging = computed(() => store.state.app.enabledDevModeLogging );
    if (!enabledDevModeLogging.value) {
      // useBindConsole();
    }

    console.log("App.vue setup")
    AppLogger.setup(store);


    onMounted(async () => {
      const { uuid } = await Device.getId();
      await networkState.mountMe();
      store.commit("app/setDeviceId", uuid);

      // applinkbanner disabled for ARIO
      // if (isPlatform("mobileweb")) {
      //   store.commit('app/setShowAppLinkBanner', true)
      // }

      useData.fetch();
      screenSize.mountMe();

      const getLocaleFromStorage = localStorage.getItem('locale') || 'de';
      store && store.commit('app/setLocale', getLocaleFromStorage);
    });


    App.addListener('appRestoredResult', data => {
      console.log('Restored state:', data);
    });

    const checkForUpdates = async () => {
        if (justStarted) {
          justStarted = false;

          if (store.state.app.disabledDevOTA) {
            console.error('!!!Warning!!!')
            console.error('!!!Warning!!!')
            console.error('!!!Warning!!!')
            console.error('!!!Warning!!!! Do not disable ota for production, uat or dev stage as it will loose OTA functionality when deployed!!!! Use it only in local env!!')
          }

          const available = await ota.updateAvailable();
          if (available) {
            !store.state.app.disabledDevOTA && await ota.updateNow();
          }
        }
    }

    watch(() => route.name, (n, o) => checkForUpdates(), { immediate: true })
    watch(() => store.state.app.welcomeAnimationDone, (n, o) => checkForUpdates(), { immediate: true })

    onUnmounted(async() => {
      screenSize.unmountMe();
      await networkState.unmountMe();
    });



    return {
      isDev,
      home,
      settings,
      reader,
      showBottomNavigation,
      showAppLink,
      isDesktopToolbarForbidden,
      t,
      user,
      isUpdating: ota.isUpdating,
      routeName
    };
  },
});
</script>


<style scoped lang="scss">
@media (min-width: 1024px) {
  .bottomNavigation {
    display: none;
  }
}

@media (max-width: 1024px) {
  .bottomNavigation {
    display: flex;
  }
}


.app-link-shown {
  top: 69px !important;
  height: calc(100% - 69px) !important;
}
</style>