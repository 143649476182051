<template>
  <table
    v-if="!isTableOfContent && freieMaengel.length > 0"
    class="flex-1 w-full"
  >
    <!-- <h2>zusätzliche Mängel</h2> -->
    <h2 />
    <tr>
      <th class="text-left pl-2">
        {{ t("hzba.pdf.zuaetzlicheMaengel", 1, { locale: lang }) }}
      </th>

      <th class="text-left pl-2">
        {{ t("hzba.mangel.kategorie", 1, { locale: lang }) }}
      </th>

      <th class="text-left pl-2">
        {{ t("hzba.pdf.mangelBezeichnung", 1, { locale: lang }) }}
      </th>
      <td class="numberCol" />
      <!-- <td class="numberCol" /> -->
    </tr>

    <tr class="data-tr items-center">
      <td
        style="min-width: 120px; max-width: 200px"
        class="text-left pl-2"
      >
        <p>{{ name }}</p>
      </td>

      <td
        class="text-left pl-2 numberCol"
        style="min-width: 120px; max-width: 200px"
      >
        <span
          v-for="(mangel, key) in customMaengel"
          :key="key"
        >
          {{ mangel.custom.titel }}
        </span>
      </td>
      <td
        class="text-left pl-2 numberCol"
        style="min-width: 120px; max-width: 200px"
      >
        <p
          v-for="(mangel, key) in customMaengel"
          :key="key"
        >
          {{ mangel.custom.kategorie }}
        </p>
      </td>

      <!-- <td class="text-center">
        <PdfChip :value="entry.malus.value" :color="entry.malus.color" />
      </td> -->
      <td class="numberCol" />
      <!-- <td class="numberCol" /> -->
    </tr>
  </table>
</template>

<script lang="ts">
import { useI18n } from "vue-i18n";
import { computed, PropType } from "vue";
import PdfChip from "@/components/Pdf/Components/PdfChip";
import { defineComponent } from "vue";
import { ExtendedMSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
import { Fragenblock } from "@/models/ba/Fragenblock";
export default defineComponent({
  name: "PdfFMDataTable",
  components: { PdfChip },

  props: {
    freieMaengel: {
      type: Array as PropType<ExtendedMSERow[]>,
      required: true,
    },
    valueTitle: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: "Position",
    },
    lang: {
      type: String,
      default: "de",
    },
    isTableOfContent: {
      type: Boolean,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });
    const positionTranslated = computed(() => {
      return props.name === "Position" ? t("hzba.pdf.position") : props.name;
    });

    const customMaengel = computed(() => {
      console.log(
        "props.freieMaengel",
        props.freieMaengel.length,
        props.freieMaengel
      );
      return props.freieMaengel;
    });

    return {
      positionTranslated,
      customMaengel,
      t,
    };
  },
});
</script>

<style scoped lang="scss">
table {
  font-size: 12px;
  margin-top: 0px;
}
tr {
  border-bottom: 1px solid $grey-800 !important;
  padding-top: 4px;
}

th {
  font-size: 12px;
  padding-bottom: 4px;
  padding-top: 4px;
}

.data-tr {
  height: 22px;
}

.numberCol {
  width: 70px;
}

.red {
  color: $pdfRed1;
}

.yellow {
  color: $pdfYellow1;
}

.green {
  color: $pdfGreen1;
}

.value-col {
  padding: 8px;
}
</style>