<template>
  <table class="flex-1 w-full">
    <tr>
      <th class="text-left pl-2">
        {{ positionTranslated }}
      </th>
      <th
        v-if="valueTitle !== undefined"
        class="text-left value-col"
      >
        {{ valueTitle }}
      </th>

      <th class="numberCol">
        {{ t("hzba.pdf.maluswert", 1, { locale: lang}) }}
      </th>
      <th class="numberCol">
        {{ t("hzba.pdf.safety", 1, { locale: lang}) }}
      </th>
      <th class="numberCol">
        {{ t("hzba.pdf.effizienz", 1, { locale: lang}) }}
      </th>
    </tr>
    <tr
      v-for="(entry, key) in data"
      :key="key"
      class="data-tr items-center"
    >
      <td
        style="min-width: 120px;"
        class="pl-2"
      >
        {{ entry.position }}
      </td>

      <td
        v-if="entry.value"
        class="value-col text-left"
        :class="entry.value.color === 'red' ? 'red' : ''"
      >
        {{ entry.value.value }}
      </td>

      <td
        class="text-right"
        :class="[
          entry.hint && entry.hint.color === 'red' ? 'red' : '',
          entry.hint && entry.hint.color === 'yellow' ? 'yellow' : '',
          entry.hint && entry.hint.color === 'green' ? 'green' : '',
        ]"
      >
        {{ entry.hint && entry.hint.value }}
      </td>
      <td class="text-center">
        <PdfChip
          v-if="entry.malus"
          :value="entry.malus.value"
          :color="entry.malus.color"
          pdf-mode
        />
      </td>
      <td class="text-center">
        <PdfChip
          v-if="entry.safety"
          :value="entry.safety.value"
          :color="entry.safety.color"
          pdf-mode
        />
      </td>
      <td class="text-center">
        <PdfChip
          v-if="entry.efficiency"
          :value="entry.efficiency.value"
          :color="entry.efficiency.color"
          pdf-mode
        />
      </td>
    </tr>
  </table>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import PdfChip from "@/components/Pdf/Components/PdfChip";
import {defineComponent} from "vue";
export default defineComponent({
  name: "PdfDataTable",
  components: {PdfChip},

  props: {
          data: {
            type: Array,
            required: true
          },
          valueTitle: {
            type: String,
            default: undefined
          },
          name: {
            type: String,
            default: 'Position'
          },
          lang: {
            type: String,
            default: 'de'
          }
        },
        
  setup(props, { emit }) {
 
     const { t } = useI18n({ useScope: 'global' })
     const positionTranslated = computed(()=>{
     return props.name === 'Position'? t('hzba.pdf.position') : props.name
    })
      
    return {
        positionTranslated,
        t
      }
  },
  
})
</script>

<style scoped lang="scss">

table {
  font-size: 12px;
}
tr {
  border-bottom: 1px solid $grey-800 !important;
}

th {
  font-size: 12px;
  padding-bottom: 4px;
}

.data-tr {
  height: 22px;
}

.numberCol {
  width: 70px;
}

.red {
  color: $pdfRed1;
}

.yellow {
  color: $pdfYellow1;
}

.green {
  color: $pdfGreen1;
}

.value-col {
  padding: 8px;
}

</style>