<template>
  <transition name="fade">
    <div
      class="mangel-item-wrapper"
      data-cy="mangel-item-wrapper"
      :class="collapsed ? 'collapsed' : ''"
    >
      <div
        class="m-1 md:mx-6 mangel-item"
        :data-cy="`${mangel.uid()}`"
      >
        <div class="mx-6 flex items-center">
          <div class="flex-1">
            <h3
              v-if="!isCustomMangelAddMode"
              class="warning mb-0 text-base"
            >
              {{
                !isCustomMangel
                  ? t("hzba.mangel.mangelErkannt")
                  : t("hzba.mangel.mangelHinzugefuegt")
              }}
              ({{ mangel.maluspunkte() }} {{ t("hzba.mangel.mp") }})
            </h3>
            <h3
              v-else
              class="mb-0 text-xl"
            >
              Mangel hinzufügen
            </h3>

            <h2 class="mt-0 text-xl">
              {{ mangel.titel() }}
            </h2>
          </div>
          <div v-if="!collapsed && showBodyEnabled">
            <!--            :disabled="mangel.eingabeRelevant === undefined"-->
            <ion-button
              fill="clear"
              color="dark"
              @click="$emit('update:collapsed', !collapsed)"
            >
              <ion-icon
                :icon="chevronUpOutline"
                size="large"
              />
            </ion-button>
          </div>
          <div v-if="collapsed && showBodyEnabled">
            <ion-button
              data-cy="button-expand-mangel"
              fill="clear"
              color="dark"
              @click="$emit('update:collapsed', !collapsed)"
            >
              <ion-icon
                :icon="chevronDownOutline"
                size="large"
              />
            </ion-button>
          </div>
        </div>

        <div v-if="!collapsed && showBodyEnabled">
          <hzba-base-input
            v-if="isCustomMangel"
            v-model="mangel.custom.titel"
            data-cy="input-text-bezeichnung"
            :label="t('hzba.mangel.bezeichnung')"
            :readonly="isReadonly"
            hide-unit-space
            :placeholder="t('hzba.mangel.bezeichnungPlaceholder')"
          />

          <input-error
            class="px-4"
            :errors="customTitelErrors"
          />

          <ion-item
            v-if="!mangelConfig?.hideBilder"
            lines="none"
          >
            <ion-grid data-cy="input-photo-bilder">
              <ion-row class="d-flex items-center">
                <ion-col> {{ mangel.getBilderLabel(t) }} </ion-col>
                <ion-col>
                  <hzba-photo-input v-model="mangel.eingabeMedien" />
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>

          <!--          <hzba-base-input-->
          <!--            v-if="isCustomMangel"-->
          <!--            v-model="mangel.customMaluspunkte"-->
          <!--            :label="t('hzba.mangel.maluspunkte')"-->
          <!--            :readonly="isReadonly"-->
          <!--            hide-unit-space-->
          <!--            :placeholder="t('hzba.wertPlatzhalter')"-->
          <!--          />-->

          <input-error
            class="px-4"
            :errors="imageErrors"
          />

          <hzba-choose-input
            v-if="!mangelConfig?.hideIstRelevant"
            v-model="mangel.eingabeRelevant"
            :label="mangel.getIstRelevantLabel(t)"
            data-cy="input-choose-relevanz"
            :readonly="isReadonly"
            :show-unit="false"
          />
          <input-error
            class="px-4"
            :errors="relevantErrors"
          />

          <hzba-textarea-input
            v-if="!mangelConfig?.hideFreitext"
            v-model="mangel.eingabeFreitext"
            data-cy="input-textarea-freitext"
            :label="mangel.getFreitextLabel(t)"
            :readonly="isReadonly"
            :limit="freitextLimit"
          />

          <input-error
            class="px-4"
            :errors="textErrors"
          />

          <hzba-select-input
            v-if="isCustomMangel"
            v-model="mangel.custom.gewerk"
            data-cy="input-select-gewerk"
            :multiple="false"
            :label="t('hzba.mangel.gewerk')"
            :options="mangelGewerkKeys"
            :labels="mangelGewerkValues"
            :readonly="isReadonly"
          />

          <input-error
            class="px-4"
            :errors="gewerkErrors"
          />

          <hzba-base-input
            v-if="isCustomMangel"
            v-model="mangel.custom.handlungsbedarfTaetigkeit"
            data-cy="input-text-handlungsbedarf"
            :label="t('hzba.mangel.handlungsbedarfTaetigkeit')"
            :readonly="isReadonly"
            hide-unit-space
          />

          <input-error
            class="px-4"
            :errors="handlungsbedarfTaetigkeitsErrors"
          />

          <hzba-select-input
            v-if="isCustomMangel"
            v-model="mangel.custom.handlungsbedarfZeitpunkt"
            data-cy="input-select-handlungsbedarf-zeitpunkt"
            :multiple="false"
            :label="t('hzba.mangel.handlungsbedarfZeitpunkt')"
            :options="mangelZeitpunktKeys"
            :labels="mangelZeitpunktValues"
            :readonly="isReadonly"
          />

          <input-error
            class="px-4"
            :errors="handlungsbedarfZeitpunktErrors"
          />

          <hzba-select-input
            v-if="isCustomMangel"
            v-model="mangel.custom.kategorie"
            data-cy="input-select-kategorie"
            :multiple="false"
            :label="t('hzba.mangel.kategorie')"
            :options="mangelKategorieKeys"
            :labels="mangelKategorieValues"
            :readonly="isReadonly"
          />
          <input-error
            class="px-4"
            :errors="kategorieErrors"
          />

          <hzba-select-input
            v-if="isCustomMangel"
            v-model="mangel.custom.produktTyp"
            data-cy="input-select-produktTyp"
            :multiple="false"
            :label="t('hzba.mangel.produktTyp')"
            :options="mangelProduktTypKeys"
            :labels="mangelProduktTypValues"
            :readonly="isReadonly"
          />
          <input-error
            class="px-4"
            :errors="produktTypErrors"
          />

          <hzba-select-input
            v-if="isCustomMangel"
            v-model="mangel.custom.quelle"
            data-cy="input-select-quelle"
            :multiple="false"
            :label="t('hzba.mangel.quelle')"
            :options="mangelQuelleKeys"
            :labels="mangelQuelleValues"
            :readonly="isReadonly"
          />
          <input-error
            class="px-4"
            :errors="quelleErrors"
          />

          <hzba-base-input
            v-if="isCustomMangel"
            v-model="mangel.custom.maluspunkte"
            :type="'number'"
            data-cy="input-text-maluspunkte"
            :label="t('hzba.mangel.maluspunkte')"
            :readonly="isReadonly"
            hide-unit-space
          />
          <input-error
            class="px-4"
            :errors="maluspunkteErrors"
          />
        </div>

        <div
          v-if="collapsed && errors && errors.length > 0"
          class="p-4"
        >
          <input-error
            :errors="errors"
            show-all
          />
        </div>
        <div
          v-if="!showBodyEnabled"
          class="p-4"
        >
          {{ t("hzba.mangelInfoText") }}
        </div>

        <div
          v-if="!collapsed"
          class="mt-4"
        >
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import {
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  onIonViewWillEnter,
} from "@ionic/vue";

import { chevronUpOutline, chevronDownOutline } from "ionicons/icons";
import InputError from "@/components/hzba/Base/Input/InputElements/InputError";
import HzbaPhotoInput from "@/components/hzba/Base/Input/HzbaPhotoInput";
import HzbaTextareaInput from "@/components/hzba/Base/Input/HzbaTextareaInput";
import HzbaChooseInput from "@/components/hzba/Base/Input/HzbaChooseInput";
import { useI18n } from "vue-i18n";
import { computed, PropType } from "vue";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput.vue";
import HzbaSelectInput from "@/components/hzba/Base/Input/HzbaSelectInput.vue";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import {
  MangelGewerk,
  MangelKategorie,
  MangelProduktTyp, MangelQuelle,
  MangelZeitpunkt,
} from "@/models/ba/Mangelvorlage";
import { TEXTBLOCK_ALLOWED_CHAR_LIMIT } from "@/models/ba/Frage";
import { MangelConfig } from "@/models/ba/interfaces/IMangelzuordnung";

export default {
  components: {
    HzbaSelectInput,
    HzbaBaseInput,
    InputError,
    HzbaPhotoInput,
    IonItem,
    HzbaTextareaInput,
    HzbaChooseInput,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonButton,
  },
  props: {
    mangel: {
      type: Object as PropType<Mangelzuordnung>,
      default: undefined,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: null,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    mangelConfig: {
      type: Object as PropType<MangelConfig>,
      default: undefined,
    },
    mangelvorlage: {
      type: Object,
      default: undefined,
    },
    isCustomMangel: {
      type: Boolean,
      default: false,
    },
    isCustomMangelAddMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:collapsed"],
  setup(props: any) {
    const { t } = useI18n({ useScope: "global" });

    const mangelGewerkKeys = Object.keys(MangelGewerk);
    const mangelGewerkValues = Object.values(MangelGewerk);

    const mangelKategorieKeys = Object.keys(MangelKategorie);
    const mangelKategorieValues = Object.values(MangelKategorie);

    const mangelProduktTypKeys = Object.keys(MangelProduktTyp);
    const mangelProduktTypValues = Object.keys(MangelProduktTyp);

    const mangelQuelleKeys = Object.keys(MangelQuelle);
    const mangelQuelleValues = Object.keys(MangelQuelle);

    const mangelZeitpunktKeys = Object.keys(MangelZeitpunkt);
    const mangelZeitpunktValues = Object.values(MangelZeitpunkt);

    const handlungsbedarfTaetigkeits: any = [];
    const handlungsbedarfZeitpunkt: any = [];

    function filterErrors(id: string) {
      return props.errors?.filter((el: any) => el.$id === id);
    }

    const textErrors = computed(() => filterErrors("freitext"));
    const imageErrors = computed(() => filterErrors("bilder"));
    const relevantErrors = computed(() => filterErrors("relevant"));
    const gewerkErrors = computed(() => filterErrors("customGewerk"));
    const customTitelErrors = computed(() => filterErrors("customTitel"));
    const handlungsbedarfTaetigkeitsErrors = computed(() => filterErrors("customHandlungsbedarfTaetigkeit"));
    const kategorieErrors = computed(() => filterErrors("customKategorie"));
    const produktTypErrors = computed(() => filterErrors("produktTyp"));
    const quelleErrors = computed(() => filterErrors("quelle"));
    const handlungsbedarfZeitpunktErrors = computed(() => filterErrors("customHandlungsbedarfZeitpunkt"));
    const maluspunkteErrors = computed(() => filterErrors("maluspunkte"));

    const showBodyEnabled = computed(() => {
      return !props.mangelConfig || props.mangelConfig.hideFields !== true;
    });

    return {
      t,
      chevronUpOutline,
      chevronDownOutline,
      showBodyEnabled,
      textErrors,
      imageErrors,
      relevantErrors,
      customTitelErrors,
      gewerkErrors,
      quelleErrors,
      handlungsbedarfTaetigkeits,
      handlungsbedarfZeitpunkt,
      handlungsbedarfTaetigkeitsErrors,
      handlungsbedarfZeitpunktErrors,
      mangelKategorieKeys,
      produktTypErrors,
      mangelKategorieValues,
      mangelProduktTypKeys,
      mangelQuelleKeys,
      mangelQuelleValues,
      mangelProduktTypValues,
      maluspunkteErrors,
      mangelZeitpunktKeys,
      mangelZeitpunktValues,
      kategorieErrors,
      mangelGewerkKeys,
      mangelGewerkValues,
      freitextLimit: props.mangelConfig?.maxCharacters || 300,
    };
  },
};
</script>


<style lang="scss">
.mangel-item-wrapper {
  background: white;
  padding-bottom: 48px;
  padding-top: 8px;

  &.collapsed {
    padding-bottom: 16px;
  }
}
.mangel-item {
  //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: $boxShadow;
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>