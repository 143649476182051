<template>
  <div class="h-8">
    <div class="flex justify-between ">
      <div class="text-xs">Maststandort Checkliste</div>
      <div class="text-xs">
        {{
          useIdentifierFrageFinder(
            ba,
            'rippistein-bauflaechen.allgemein-tab.allgemein-group.vorhaben'
          )['eingabeText']
        }}
      </div>
      <div class="text-xs">
        Mast:
        {{
          useIdentifierFrageFinder(
            ba,
            'rippistein-bauflaechen.allgemein-tab.allgemein-group.anlage'
          )['eingabeText']
        }}
        /
        {{
          useIdentifierFrageFinder(
            ba,
            'rippistein-bauflaechen.allgemein-tab.allgemein-group.mastnummer'
          )['eingabeText']
        }}
      </div>
      <div>
        <img
          src="../../../../public/assets/img/pdf/transnetBW/tng-logo.png"
          width="100"
        />
      </div>
    </div>
    <div class="grid gap-0 grid-cols-3 p-0 m-0">
      <div><hr class="trenner" /></div>
      <div class="mt-0.5"><hr class="trenner" /></div>
      <div><hr class="trenner" /></div>
    </div>

  </div>
</template>


<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';

export default defineComponent({
  name: 'FirstPage',
  components: {},
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();
    return {
      user: uUser.user,
      t,
      moment,
      useIdentifierFrageFinder,
    };
  },
});
</script>
<style scoped>
* {
  color: rgb(23,68,81);
  font-family: 'Avenir-Next-LT-Pro-Regular';
}

.trenner {
  height: 3px;
  border-width: 1;
  background-color: rgb(23,68,81);
}
</style>